import styled from "@emotion/styled";
import NoTitlePageLayout from "../../components/layouts/NoTitlePageLayout";
import StandardTag from "../../components/tags/StandardTag";
import { Button } from "../../components/buttons/Button";
import { useNavigate } from "react-router-dom";
import { useTitle } from "../../hooks/useTitle";

export default function RegisterCompletePage() {
  useTitle("회원가입 완료")
  const navigate = useNavigate();
  return(
    <NoTitlePageLayout title={`회원가입 완료`} isDark={false}>
      <RegisterCompletePageLayout>
        <StandardTag scaleKey={`TITLE3`}>
          회원가입이 완료되었습니다.
        </StandardTag>
        <StandardTag
          scaleKey={`TITLE5`}
        >
          좋아하는 작품 속 로케이션을 방문해 보세요!
        </StandardTag>
        <BottomButton>
          <Button
            colorType={`PRIMARY`}
            height={`52px`}
            onClick={() => navigate(`/login`)}
          >
            로그인 하러가기
          </Button>
        </BottomButton>
      </RegisterCompletePageLayout>
    </NoTitlePageLayout>
  )
}

const RegisterCompletePageLayout = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--gap_16);
  padding-bottom: 120px;
`

const BottomButton = styled.div`
  width: 90%;
  position: absolute;
  bottom: 68px;
`