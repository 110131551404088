import React, { useState } from "react";
import "./styles/App.css";
import { Route, Routes } from "react-router";
import MainCustomerPage from "./pages/main/MainCustomerPage";
import LoginPage from "./pages/init/LoginPage";
import RegisterPage from "./pages/init/RegisterPage";
import MainMoreCustomerPage from "./pages/main/MainMoreCustomerPage";
import MainDetailCustomerPage from "./pages/main/MainDetailCustomerPage";
import ContentManagementAdminPage from "./pages/management/ContentManagementAdminPage";
import WriteAdminPage from "./pages/write/WriteAdminPage";
import { useQueryClient } from "@tanstack/react-query";
import AlertModal from "./components/modals/AlertModal";
import RegisterCompletePage from "./pages/init/RegisterCompletePage";
import TermDetailPage from "./pages/init/TermDetailPage";
import TermsPage from "./pages/init/TermPage";
import PasswordPage from "./pages/init/PasswordPage";
import MyPage from "./pages/mypage/MyPage";
import MyInfoPage from "./pages/mypage/MyInfoPage";
import Oauth2Page from "./pages/init/Oauth2Page";

function App() {
  const [alertModalVisible, setAlertModalVisible] = useState(false);
  const [alertTitle, setAlertTitle] = useState<string>("");
  const [alertSubtitle, setAlertSubtitle] = useState<string>("");
  const [alertThirdTitle, setAlertThirdTitle] = useState<string>("");
  const [alertOkText, setAlertOkText] = useState<string>("돌아가기");

  const queryClient = useQueryClient();
  queryClient.setDefaultOptions({
    queries: {
      onError: (error: any) => {
        handleError(error);
      },
    },
    mutations: {
      onError: (error: any) => {
        handleError(error);
      },
    },
  });

  function handleError(error: any) {
    let title = error.response.data.title;
    let subTitle = error.response.data.detail;
    let thirdTitle = error.response.data.thirdTitle;

    // sub, third는 이전 내용 Caching 되어있을 수 있어서 삭제
    setAlertSubtitle(``);
    setAlertThirdTitle(``);

    if (error.response.status === 504) {
      setAlertTitle(`서버 점검 중`);
      setAlertSubtitle(`잠시만 기다렸다 다시 진행해 주세요`);
      setAlertThirdTitle(`지속될 경우 jupiter@theoneder.co.kr로 문의주세요`);
      setAlertModalVisible(true);
      return;
    }
    if (title === `` || title === undefined) {
      setAlertTitle(`에러가 발생했습니다.`);
      setAlertSubtitle(``);
    } else {
      if (title !== subTitle) {
        setAlertTitle(title);
        setAlertSubtitle(subTitle);
        if (thirdTitle) {
          setAlertThirdTitle(thirdTitle);
        }
      } else {
        setAlertTitle(title);
        setAlertSubtitle(``);
      }
    }
    setAlertModalVisible(true);
  }

  return (
    <>
      <Routes>
        <Route path="/" element={<MainCustomerPage />} />
        <Route path="/more" element={<MainMoreCustomerPage />} />
        <Route path="/detail" element={<MainDetailCustomerPage />} />
        <Route
          path="/admin/management"
          element={<ContentManagementAdminPage />}
        />
        <Route path="/admin/write" element={<WriteAdminPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/terms">
          <Route index={true} element={<TermsPage />} />
          <Route index={false} path=":id" element={<TermDetailPage />} />
        </Route>
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/password" element={<PasswordPage />} />
        <Route path="/my_page" element={<MyPage />} />
        <Route path="/my_info_page" element={<MyInfoPage />} />
        <Route path="/complete_register" element={<RegisterCompletePage />} />
        <Route path="/login/oauth2/code/kakao" element={<Oauth2Page />} />
      </Routes>
      {alertModalVisible && (
        <AlertModal
          visible={alertModalVisible}
          resetFn={() => setAlertModalVisible(false)}
          title={alertTitle}
          subTitle={alertSubtitle}
          okText={alertOkText}
          okFn={() => setAlertModalVisible(false)}
        />
      )}
    </>
  );
}

export default App;
