import { CustomIcon } from "../../../../components/icons/CustomIcon";
import SelectLayout, {
  BasicSelectItem,
} from "../../../../components/selects/SelectLayout";

interface ListProps {
  setShowOptionList: (b: boolean) => void;
  deleteFunction: () => void;
  updateFunction: () => void;
}

export default function ReviewOptionList(props: ListProps) {
  return (
    <SelectLayout
      setOpen={props.setShowOptionList}
      style={{ top: "calc(100% - 4px)", right: "2px" }}
    >
      <BasicSelectItem
        onClick={() => {
          props.deleteFunction();
          props.setShowOptionList(false);
        }}
      >
        삭제하기
        <CustomIcon iconName={"trash"} size={24} className="cl_gy3" />
      </BasicSelectItem>
      <BasicSelectItem
        onClick={() => {
          props.updateFunction();
          props.setShowOptionList(false);
        }}
      >
        수정하기
        <CustomIcon iconName={"edit-pencil"} size={24} className="cl_gy3" />
      </BasicSelectItem>
    </SelectLayout>
  );
}
