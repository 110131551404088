import Modal from "antd/lib/modal/Modal";
import React, { CSSProperties, useEffect, useState } from "react";
import ModalLayout from "../layouts/ModalLayout";
import { BasicModalProps } from "../../types/modals";
import { FlexColLayout, FlexRowLayout } from "../layouts/styles";
import { Button } from "../buttons/Button";
import PaddingBox from "../boxes/PaddingBox";

const modalBodyLayout: CSSProperties = {
  height: "221px",
  display: "flex",
  flexDirection: "column",
};

interface ModalProps extends BasicModalProps {
  title: string | undefined;
  subTitle: string | undefined;
  okText: string | undefined;
  cancelText: string | undefined;
  okFn: () => void;
  cancelFn: (() => void) | undefined;
  height?: number;
  closable?: boolean;
  isDeleteMode?: boolean;
}

export default function ConfirmModal(props: ModalProps) {
  const hideModal = () => {
    if (props.cancelFn) props.cancelFn();
    props.resetFn();
  };
  const [isCloseable, setCloseable] = useState<boolean>(false);
  useEffect(() => {
    if (props.closable) setCloseable(props.closable);
  }, [props.closable]);

  const getOkText = () => {
    if (props.okText) {
      return props.okText;
    } else {
      if (props.isDeleteMode) {
        return "삭제하기";
      }
      return "네";
    }
  };
  return (
    <Modal
      open={props.visible}
      centered
      width={292}
      closable={false}
      maskClosable={isCloseable}
      bodyStyle={modalBodyLayout}
      footer={false}
      onCancel={props.resetFn}
      afterClose={props.resetFn}
      transitionName=""
      maskTransitionName=""
    >
      <ModalLayout
        existsBackBtn={false}
        existsCloseBtn={false}
        backFn={undefined}
        closeFn={hideModal}
      >
        <div className="dp_fx_col fx_jc_btw fx_grow">
          <FlexColLayout className="fx_jc_ct fx_grow">
            <PaddingBox only={{ top: 30, right: 20, left: 20 }}>
              {props.title && (
                <div className="ta_ct ell fx_auto fs_m fw_b">{props.title}</div>
              )}
              {props.subTitle && (
                <div className="ta_ct ws_pw ell fs_m" style={{ flexShrink: 1 }}>
                  {props.subTitle}
                </div>
              )}
            </PaddingBox>
          </FlexColLayout>
          <PaddingBox all={6}>
            <FlexRowLayout gap={10}>
              <Button
                colorType={"GRAY1_FONT_BLACK"}
                isWithIcon={false}
                onClick={hideModal}
                height="44px"
                fontSize="XS"
                borderRadius="M"
              >
                {props.cancelText ? props.cancelText : "닫기"}
              </Button>
              <Button
                colorType={props.isDeleteMode ? "DANGER" : "BLACK"}
                isWithIcon={false}
                onClick={props.okFn}
                height="44px"
                fontSize="XS"
                borderRadius="M"
              >
                {getOkText()}
              </Button>
            </FlexRowLayout>
          </PaddingBox>
        </div>
      </ModalLayout>
    </Modal>
  );
}
