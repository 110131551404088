import React, { useEffect, useState } from "react";
import PaddingBox from "../../../../components/boxes/PaddingBox";
import {
  FlexColLayout,
  FlexRowLayout,
} from "../../../../components/layouts/styles";
import styled from "@emotion/styled";
import CustomRate from "../../../../components/rates/CustomRate";
import StandardTag from "../../../../components/tags/StandardTag";
import IconImage from "../../../../components/icons/IconImage";
import { ReviewApiResponse } from "../../../../types/review";
import { format } from "date-fns";
import ReviewOptionList from "./ReviewOptionList";
import ConfirmModal from "../../../../components/modals/ConfirmModal";
import { useDeleteReview } from "../../../../apis/review";
import { useAuth } from "../../../../hooks/useAuth";
import { checkAdminOrHigher } from "../../../../utils/auth";
import { useQueryClient } from "@tanstack/react-query";
import { isValidString } from "../../../../utils/checkType";

interface Props {
  data: ReviewApiResponse;
  updateFn: () => void;
}

// 프로필 사진, 자신이 작성한 것만 옵션 버튼 보이게 하기
export default function Review(props: Props) {
  const [showOptionList, setShowOptionList] = useState(false);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);

  const auth = useAuth();
  const queryClient = useQueryClient();
  const deleteMutation = useDeleteReview(false);

  return (
    <PaddingBox symmetric={{ vertical: 8 }}>
      <FlexColLayout gap={12}>
        <FlexColLayout gap={10}>
          <FlexRowLayout className="fx_jc_btw">
            <FlexRowLayout gap={13} className="fx_ai_ct">
              <ProfileImg>
                {isValidString(props.data.profile_url) ? (
                  <ProfileImgInside
                    src={props.data.profile_url}
                    alt="프로필 사진"
                  />
                ) : (
                <IconImage iconName="mypage" />
                )}
              </ProfileImg>
              <FlexColLayout gap={5}>
                <CustomRate size={18} value={props.data.rate} />
                <NameBox>
                  <StandardTag scaleKey="TAG2">
                    {props.data.member_name}
                  </StandardTag>
                  <div>|</div>
                  <div style={{ fontSize: "10px" }} className="fw_r">
                    {format(new Date(props.data.created_at), "yyyy.MM.dd")}
                  </div>
                </NameBox>
              </FlexColLayout>
            </FlexRowLayout>
            {checkAdminOrHigher(auth) ? (
              <DeleteButton
                onClick={() => {
                  setConfirmModalVisible(true);
                }}
              >
                삭제하기
              </DeleteButton>
            ) : (
              <>
                {props.data.member_id === auth?.loggedInMember?.id && (
                  <MoreButton
                    onClick={() => {
                      setShowOptionList(true);
                    }}
                    className={showOptionList ? "selected" : undefined}
                  >
                    <IconImage iconName="more" style={{ color: "#828282" }} />
                    {showOptionList && (
                      <ReviewOptionList
                        setShowOptionList={setShowOptionList}
                        updateFunction={() => {
                          props.updateFn();
                        }}
                        deleteFunction={() => {
                          setConfirmModalVisible(true);
                        }}
                      />
                    )}
                  </MoreButton>
                )}
              </>
            )}
          </FlexRowLayout>
          <Content>{props.data.detail}</Content>
        </FlexColLayout>
        <Divider />
      </FlexColLayout>
      <ConfirmModal
        visible={confirmModalVisible}
        resetFn={() => {
          setConfirmModalVisible(false);
        }}
        title={"삭제된 리뷰는 복구가 불가능합니다."}
        subTitle={"리뷰를 삭제하시겠습니까?"}
        okText={undefined}
        cancelText={undefined}
        okFn={() => {
          setConfirmModalVisible(false);
          deleteMutation.mutate(props.data.id, {
            onSuccess(data, variables, context) {
              queryClient.invalidateQueries(["content", props.data.content_id]);
            },
          });
        }}
        cancelFn={undefined}
      />
    </PaddingBox>
  );
}

const ProfileImg = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--color_gray8);
`;

const ProfileImgInside = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
`

const NameBox = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  color: #8d8a8a;
`;

const MoreButton = styled.div`
  width: 24px;
  height: 24px;
  position: relative;
  cursor: pointer;
  &:hover,
  &.selected {
    border-radius: 50%;
    background-color: var(--color_gray8);
  }
`;

const Content = styled.div`
  width: 100%;
  white-space: pre-wrap;
  word-break: keep-all;
  padding-left: 50px;
  color: #8d8a8a;
  font-size: var(--fs_xxs);
  font-weight: var(--fw_r);
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #535353;
`;

const DeleteButton = styled.div`
  color: var(--color_primary1);
  font-size: 14px;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.28px;
`;
