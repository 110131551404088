import React, { PropsWithChildren } from "react";
import { ContentLayout, MainLayout, PageLayout } from "./styles";

interface Props extends PropsWithChildren {
  title: string;
  isDark: boolean;
}

export default function TitlePageLayout(props: Props) {
  return (
    <PageLayout isDark={props.isDark}>
      <ContentLayout>
        <MainLayout>{props.children}</MainLayout>
      </ContentLayout>
    </PageLayout>
  );
}
