import React, { PropsWithChildren } from "react";
import styled from "@emotion/styled";
import { CustomIcon } from "../icons/CustomIcon";

interface Props extends PropsWithChildren {
  existsBackBtn: boolean;
  existsCloseBtn: boolean;
  backFn?: () => void;
  closeFn?: () => void;
  title?: string;
}

export const ModalTitle = styled.div`
  width: fit-content;
  height: auto;
  font-size: var(--fs_l);
  font-weight: var(--fw_b);
`;

export default function ModalLayout(props: Props) {
  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          style={{
            width: "fit-content",
            display: "flex",
            gap: "16px",
            lineHeight: "1",
          }}
        >
          {props.existsBackBtn && (
            <div className="fl_l cs_pt">
              <CustomIcon
                iconName="nav-arrow-left"
                className="cl_bk cs_pt"
                onClick={props.backFn}
              />
            </div>
          )}
          {props.title && <ModalTitle>{props.title}</ModalTitle>}
        </div>
        <div className="dp_fx_row fx_ai_ct fl_r cs_pt h_100">
          {props.existsCloseBtn && (
            <CustomIcon
              iconName="cancel"
              className="cl_bk cs_pt"
              onClick={props.closeFn}
            />
          )}
        </div>
      </div>
      {props.children}
    </>
  );
}
