import React, { useEffect, useRef, useState } from "react";
import TitlePageLayout from "../../components/layouts/TitlePageLayout";
import styled from "@emotion/styled";
import { CustomIcon } from "../../components/icons/CustomIcon";
import { Input, InputProps, InputWithMsg, getInputTag } from "../../components/inputs/Input";
import { InputLayout, InputLabel, InputText, InputMsg } from "../../components/inputs/style";
import { FlexColLayout, FlexRowLayout } from "../../components/layouts/styles";
import { COLOR_TYPE, ColorKey } from "../../types/unions/styles/colors/basicColor";
import { INPUT_COLOR_TYPE } from "../../types/unions/styles/colors/inputColor";
import { Button } from "../../components/buttons/Button";
import { InputStatus } from "../../types/unions/status";
import { useLocation, useNavigate } from "react-router-dom";
import { TermsObjectType } from "../../types/terms";
import { ACTION_CONFIRM, ALERT_ABNORMAL_ACCESS, ALERT_ABNORMAL_ACCESS_SUBTITLE, ALERT_AUTHENTICATED, ALERT_CHECK_AUTH_STATUS, ALERT_CHECK_EMAIL_STATUS, ALERT_CHECK_PASSWORD, ALERT_EMAIL_SENT, ALERT_ERROR_OCCURRED, ALERT_NOT_ALLOWED_EMPTY_PHONE, ALERT_PASSWORD_NOT_EQUALS, ALERT_REGISTERED, ALERT_SERVE_BUILDING_CARE_BY_PHONE, ALERT_WRITE_NAME, ALERT_WRONG_PHONE_FORMAT, LABEL_AUTH_CODE, LABEL_CHECK_PASSWORD, LABEL_EMAIL, LABEL_NAME, LABEL_PASSWORD, MESSAGE_RE_ERROR_AUTH_CODE, MESSAGE_RE_ERROR_EMAIL, PLACEHOLDER_REGISTER_AUTHENTICATION, PLACEHOLDER_REGISTER_COMPANY_CODE, PLACEHOLDER_REGISTER_COMPANY_POSITION, PLACEHOLDER_REGISTER_EMAIL, PLACEHOLDER_REGISTER_NAME, PLACEHOLDER_REGISTER_PASSWORD, PLACEHOLDER_REGISTER_PASSWORDCHECK, PLACEHOLDER_REGISTER_PHONE, TEXT_BUTTON_DO_AUTHENTICATION, TEXT_BUTTON_REGISTER, TEXT_BUTTON_SEND_AUTHENTICATION, TEXT_REQUEST_SEND_AGAIN } from "../../strings/string";
import { ButtonColorKey } from "../../types/unions/styles/colors/buttonColor";
import { AxiosResponse } from "axios";
import { EmailCheckProps, EmailProps, UseCheckAuthMail, UseSendAuthMail } from "../../apis/auth";
import { MemberApiResponse, MemberInputCheckDto, RegisterApiRequest } from "../../types/member";
import { UserCreateMember } from "../../apis/member";
import AlertModal from "../../components/modals/AlertModal";
import { checkAuthCode, checkEmail, checkPassword, checkPasswordCheck, checkStatus } from "../../utils/member";
import { useTitle } from "../../hooks/useTitle";

const iconCheckCircle = "check-circle";
const iconWarningCircle = "warning-circle";
const iconEyeAlt = "eye-alt";

//버튼 비활성화를 위한 noActionFunction
const noActionFunction = () => {};

type RegisterAlertModalType =
  | "SENT"
  | "AUTHORIZED"
  | "DESCRIPTION"
  | "MEMBER_CREATED"
  | "ABNORMAL_ACCESS"
  | "CHECK_NAME"
  | "CHECK_EMAIL"
  | "CHECK_AUTH"
  | "CHECK_PASSWORD"
  | "PASSWORD_NOT_MATCHING"
  | "EMPTY_MOBILE_NUMBER"
  | "WRONG_MOBILE_NUMBER";

export default function RegisterPage() {
  useTitle("회원가입")
  const navigate = useNavigate();
  const location = useLocation();
  const stateType = location.state as TermsObjectType;

  //ref
  const nameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const authCodeRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const passwordCheckRef = useRef<HTMLInputElement>(null);

  // state
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [authCode, setAuthCode] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [passwordCheck, setPasswordCheck] = useState<string>("");
  const [alertModalType, setAlertModalType] = useState<RegisterAlertModalType>();

  const [iconPasswordName, setIconPasswordName] = useState<string>("eye-close");
  const [iconPasswordCheckName, setIconPasswordCheckName] = useState<string>("eye-close");
  const [iconEmailConfirmName, setIconEmailConfirmName] = useState<string>("");
  const [iconAuthCodeConfirmName, setIconAuthCodeConfirmName] = useState<string>("");
  const [iconPasswordConfirmName, setIconPasswordConfirmName] = useState<string>("");
  const [iconPasswordCheckConfirmName, setIconPasswordCheckConfirmName] = useState<string>("");
  const [iconStyleEmailConfirmName, setIconStyleEmailConfirmName] = useState<React.CSSProperties>({});
  const [iconStyleAuthCodeConfirmName, setIconStyleAuthCodeConfirmName] = useState<React.CSSProperties>({});
  const [iconStylePasswordConfirmName, setIconStylePasswordConfirmName] = useState<React.CSSProperties>({});
  const [iconStylePasswordCheckConfirmName, setIconStylePasswordCheckConfirmName] = useState<React.CSSProperties>({});

  const [emailLabel, setEmailLabel] = useState<string>(LABEL_EMAIL);
  const [emailButtonLabel, setEmailButtonLabel] = useState<string>(TEXT_BUTTON_SEND_AUTHENTICATION);
  const [authCodeLabel, setAuthCodeLabel] = useState<string>(LABEL_AUTH_CODE);
  const [authCodeButtonLabel, setAuthCodeButtonLabel] = useState<string>(TEXT_BUTTON_DO_AUTHENTICATION);
  const [passwordLabel, setPasswordLabel] = useState<string>(LABEL_PASSWORD);
  const [passwordCheckLabel, setPasswordCheckLabel] = useState<string>(LABEL_CHECK_PASSWORD);

  const [emailButtonColor, setEmailButtonColor] = useState<ButtonColorKey>(`GRAY5`);
  const [authCodeButtonColor, setAuthCodeButtonColor] = useState<ButtonColorKey>(`GRAY5`);
  const [registerButtonColor, setRegisterButtonColor] = useState<ButtonColorKey>(`GRAY5`);

  // status
  const [emailStatus, setEmailStatus] = useState<InputStatus | undefined>(`NORMAL`);
  const [authCodeStatus, setAuthCodeStatus] = useState<InputStatus | undefined>(`NORMAL`);
  const [passwordStatus, setPasswordStatus] = useState<InputStatus | undefined>(`NORMAL`);
  const [passwordCheckStatus, setPasswordCheckStatus] = useState<InputStatus | undefined>("NORMAL");
  const [isRegisterable, setRegisterable] = useState<boolean>(false);

  // 인증 체크
  const [isMailSent, setMailSent] = useState<boolean>(false);
  const [isAuthCodeCheck, setAuthCodeCheck] = useState<boolean>(false);

  // 하위 alert 모달
  const [isAlertModalVisible, setAlertModalVisible] = useState<boolean>(false);
  const [alertTitle, setAlertTitle] = useState<string>("");
  const [alertSubtitle, setAlertSubtitle] = useState<string>("");
  const [alertOkText, setAlertOkText] = useState<string>(ACTION_CONFIRM);

  // style
  const [authInputStyleWithButton, setAuthInputStyleWithButton] =
    useState<React.CSSProperties>(getStyleProperties(`small_normal`));
  const [passwordInputStyle, setPasswordInputStyle] =
    useState<React.CSSProperties>(getStyleProperties(`big_normal`));
  const [passwordCheckInputStyle, setPasswordCheckInputStyle] =
    useState<React.CSSProperties>(getStyleProperties(`big_normal`));

  const [emailButtonClick, setEmailButtonClick] = useState(() => noActionFunction);
  const [authCodeButtonClick, setAuthCodeButtonClick] = useState(() => noActionFunction);
  const [registerButtonClick, setRegisterButtonClick] = useState(() => noActionFunction);

  // show password, passwordCheck
  const [isPasswordShown, setIsPasswordShown] = useState<boolean>(false);
  const [isPasswordCheckShown, setIsPasswordCheckShown] = useState<boolean>(false);

  const [passwordInputType, setPasswordInputType] = useState<string>("password");
  const [passwordCheckInputType, setPasswordCheckInputType] = useState<string>("password");

  // onChange setState
  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.currentTarget.value);
  };
  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.currentTarget.value);
  };
  const handleChangeAuthCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAuthCode(event.currentTarget.value);
  };
  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.currentTarget.value);
  };
  const handleChangePasswordCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordCheck(event.currentTarget.value);
  };

  const registerMutation = UserCreateMember();
  const sendAuthMailMutation = UseSendAuthMail();
  const checkAuthMailMutation = UseCheckAuthMail();

  // terms로 부터 받은 data -> service, privacy, marketing
  useEffect(() => {
    if (!stateType) {
      setAlertTitle("비정상적인 접근입니다.");
      setAlertSubtitle("로그인 페이지로 이동합니다.");
      setAlertModalVisible(true);
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    if (alertModalType === "MEMBER_CREATED") {
      if (isAlertModalVisible === false) {
        navigate("/register_success");
      }
    }
  }, [alertModalType, isAlertModalVisible]);

  // 이메일 확인 후 값들 세팅
  useEffect(() => {
    const dto : MemberInputCheckDto = checkEmail(email);
    setAuthCodeCheck(dto.authCodeCheck!);
    setEmailLabel(dto.inputLabel);
    setEmailButtonLabel(dto.inputButtonLabel!);
    setIconEmailConfirmName(dto.inputIcon);
    setIconStyleEmailConfirmName(dto.inputIconStyle);
    setEmailButtonColor(dto.inputButtonColor!);
    setEmailStatus(dto.inputStatus);
  }, [email]);

  // 이메일 상태가 OK 일 때만 버튼 동작
  useEffect(() => {
    if(emailStatus === `OK`) {
      setEmailButtonClick(() => handleSendAuthMail);
    } else{
      setEmailButtonClick(() => noActionFunction);
    }
  }, [emailStatus, email]);

  // 인증 코드 확인
  useEffect(() => {
    const dto: MemberInputCheckDto = checkAuthCode(isMailSent, isAuthCodeCheck, authCode);
    // setAuthCodeCheck(dto.authCodeCheck); // 필요 없음
    setAuthCodeLabel(dto.inputLabel);
    setAuthCodeButtonLabel(dto.inputButtonLabel!);
    setIconAuthCodeConfirmName(dto.inputIcon);
    setIconStyleAuthCodeConfirmName(dto.inputIconStyle);
    setAuthCodeButtonColor(dto.inputButtonColor!);
    setAuthCodeStatus(dto.inputStatus);
  }, [authCode, isAuthCodeCheck, isMailSent])

  // 인증 코드 상태가 OK 일 때만 버튼 동작
  useEffect(() => {
    if(authCodeStatus === `OK`) {
      setAuthCodeButtonClick(() => handleCheckAuthMail);
    } else { 
      setAuthCodeButtonClick(() => noActionFunction);
    }
  }, [authCodeStatus, authCode])

  // 비밀번호 체크
  useEffect(() => {
    const dto: MemberInputCheckDto = checkPassword(password);
    setPasswordLabel(dto.inputLabel);
    setIconPasswordConfirmName(dto.inputIcon);
    setIconStylePasswordConfirmName(dto.inputIconStyle);
    setPasswordStatus(dto.inputStatus);
  }, [password])

  // 비밀번호 확인 체크
  useEffect(() => {
    const dto: MemberInputCheckDto = checkPasswordCheck(passwordStatus, passwordCheck, password);
    setPasswordCheckLabel(dto.inputLabel);
    setIconPasswordCheckConfirmName(dto.inputIcon);
    setIconStylePasswordCheckConfirmName(dto.inputIconStyle);
    setPasswordCheckStatus(dto.inputStatus);
  }, [password, passwordCheck, passwordStatus])

  // 전체 상태 체크
  useEffect(() => {
    if(checkStatus(name, emailStatus, authCodeStatus, passwordStatus, passwordCheckStatus)){
      setRegisterable(true);
      let request: RegisterApiRequest = {
        email: email,
        name: name,
        password: password,
        is_privacy: true,
        is_service: true,
        login_type: `EMAIL`,
        // is_privacy: stateType.privacy,
        // is_service: stateType.service,
      };
      setRegisterButtonColor(`BLACK`);

      const handleRegisterButtonClick = () => {
        createRegisterFunction(request);
      };

      setRegisterButtonClick(() => handleRegisterButtonClick);
    } else {
      setRegisterable(false);
      setRegisterButtonClick(() => noActionFunction);
      setRegisterButtonColor(`GRAY5`);
    }
  }, [authCodeStatus, emailStatus, name, passwordCheckStatus, passwordStatus])

  useEffect(() => {
    if(isPasswordShown) {
      setIconPasswordName(iconEyeAlt);
    }else {
      setIconPasswordName(`eye-close`)
    }
  }, [isPasswordShown])
  useEffect(() => {
    if(isPasswordCheckShown) {
      setIconPasswordCheckName(iconEyeAlt);
    }else {
      setIconPasswordCheckName(`eye-close`)
    }
  }, [isPasswordCheckShown])

  // API related logics
  const sendAuthMailFunction = (
    request: EmailProps,
  ) => {
    sendAuthMailMutation.mutate(request, {
      onSuccess(data: AxiosResponse<EmailProps>) {
        if (data.status !== 200) {
          setAlertTitle(`${data.statusText}`);
          setAlertModalByCase("DESCRIPTION");
        } else {
          setAlertModalByCase("SENT");
          setMailSent(true);

          setEmailButtonLabel(TEXT_REQUEST_SEND_AGAIN);
        }
      },
      onError() {
        setAlertModalType("ABNORMAL_ACCESS");
        setMailSent(false);
      },
    });
  };

  const checkAuthMailFunction = (
    request: EmailCheckProps,
    setAuthCodeCheck: (type: boolean) => void
  ) => {
    checkAuthMailMutation.mutate(request, {
      onSuccess(data: AxiosResponse<EmailCheckProps>) {
        if (data.status === 200) {
          setAlertModalByCase("AUTHORIZED");
          setAuthCodeCheck(true);
          setIconEmailConfirmName(iconCheckCircle);
          setIconStyleEmailConfirmName({ color: "var(--color_primary3" });
          setIconAuthCodeConfirmName(iconCheckCircle);
          setIconStyleAuthCodeConfirmName({ color: "var(--color_primary3" });
          setAuthInputStyleWithButton({
            width: "266px",
            height: "44px",
            color: "var(--color_gray5)",
            fontSize: "var(--fs_xs)",
            fontWeight: "var(--fw_m)",
            borderRadius: "4px",
          });
          setAuthCodeButtonColor(`OK`);
          setAuthCodeButtonClick(() => noActionFunction);
          setEmailButtonColor(`GRAY5`);
          setEmailButtonClick(() => noActionFunction);
          setEmailButtonLabel(TEXT_REQUEST_SEND_AGAIN);
        }
      },
    });
  };

  const handleSendAuthMail = () => {
    // 이메일 인증 로직

    let request: EmailProps = {
      email: email,
      for_register: true,
    };
    sendAuthMailFunction(request);
  };

  const handleCheckAuthMail = () => {
    if (authCodeStatus === `OK`) {
      let request: EmailCheckProps = {
        email: email,
        code: authCode,
        for_register: true,
      };
      checkAuthMailFunction(request, setAuthCodeCheck);
    }
  };

  const showPassword = () => {
    if (isPasswordShown) {
      setIsPasswordShown(false);
      setPasswordInputType("password");
    } else {
      setIsPasswordShown(true);
      setPasswordInputType("text");
    }
  };
  const showPasswordCheck = () => {
    if (isPasswordCheckShown) {
      setIsPasswordCheckShown(false);
      setPasswordCheckInputType("password");
    } else {
      setIsPasswordCheckShown(true);
      setPasswordCheckInputType("text");
    }
  };

  //register function
  const createRegisterFunction = (request: RegisterApiRequest) => {
    registerMutation.mutate(request, {
      onSuccess(data: AxiosResponse<MemberApiResponse>) {
        if (data.status === 200) {
          navigate("/complete_register");
        }
      },
      onError(error) {
        setAlertTitle("회원가입에 실패하였습니다");
        setAlertModalByCase("DESCRIPTION");
      },
    });
  };

  const onKeyPressEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      //       register();
    }
  };

  type styleType = `small_normal` | `small_deactivated` | `big_normal` | `big_deactivated`;
  // CSSProperties
  function getStyleProperties(type: styleType): React.CSSProperties {
    let width: number = 350;
    let  color: ColorKey = `GRAYSCALE9`;
    switch(type) {
      case `small_normal`: {
        width = 266;
        break;
      }
      case `small_deactivated`: {
        width = 266;
        color = `GRAYSCALE5`
        break;
      }
      case `big_normal`: {
        break;
      }
      case `big_deactivated`: {
        color = `GRAYSCALE5`
        break;
      }
    }
    const colorString = COLOR_TYPE[color];

    return {
      width: `${width}px`,
      height: `44px`,
      color: `var(--${colorString})`,
      fontSize: `var(--fs_xs)`,
      fontWeight: `var(--fw_m)`,
      borderRadius: `4px`,
    }
  }

  // 모달 세팅
  const setAlertModalByCase = (modalType: RegisterAlertModalType) => {
    setAlertModalType(modalType);
    setAlertSubtitle(``);
    switch (modalType) {
      case "SENT": {
        setAlertTitle(ALERT_EMAIL_SENT);
        break;
      }
      case "AUTHORIZED": {
        setAlertTitle(ALERT_AUTHENTICATED);
        break;
      }
      case "DESCRIPTION": {
        break;
      }
      case "MEMBER_CREATED": {
        setAlertTitle(ALERT_REGISTERED);
        break;
      }
      case "ABNORMAL_ACCESS": {
        setAlertTitle(ALERT_ABNORMAL_ACCESS);
        setAlertSubtitle(ALERT_ABNORMAL_ACCESS_SUBTITLE);
        break;
      }
      case "CHECK_NAME": {
        setAlertTitle(ALERT_WRITE_NAME);
        break;
      }
      case "CHECK_EMAIL": {
        setAlertTitle(ALERT_CHECK_EMAIL_STATUS);
        break;
      }
      case "CHECK_AUTH": {
        setAlertTitle(ALERT_CHECK_AUTH_STATUS);
        break;
      }
      case "CHECK_PASSWORD": {
        setAlertTitle(ALERT_CHECK_PASSWORD);
        break;
      }
      case "PASSWORD_NOT_MATCHING": {
        setAlertTitle(ALERT_PASSWORD_NOT_EQUALS);
        break;
      }
      case "EMPTY_MOBILE_NUMBER": {
        setAlertTitle(ALERT_NOT_ALLOWED_EMPTY_PHONE);
        setAlertSubtitle(ALERT_SERVE_BUILDING_CARE_BY_PHONE);
        break;
      }
      case "WRONG_MOBILE_NUMBER": {
        setAlertTitle(ALERT_WRONG_PHONE_FORMAT);
        setAlertSubtitle(ALERT_SERVE_BUILDING_CARE_BY_PHONE);
        break;
      }
    }
    setAlertModalVisible(true);
  };

  return (
    <TitlePageLayout title={"회원가입"} isDark={false}>
      <InputPartStyle>
        <SectionLayout className="fx_ai_ct fx_jc_ct w_100">
          <FlexColLayout gap={20}>
            <Input
              label={LABEL_NAME}
              value={name}
              onChange={handleChangeName}
              ref={nameRef}
              placeholder={PLACEHOLDER_REGISTER_NAME}
              customStyle={getStyleProperties(`big_normal`)}
              isMobile={true}
            />
            <div className={"dp_fx_row fx_jc_ct w_100 ps_rl"}>
              <FlexRowLayout gap={8}>
                <InputWithMsg
                  label={emailLabel}
                  value={email}
                  status={emailStatus}
                  onChange={handleChangeEmail}
                  ref={emailRef}
                  placeholder={PLACEHOLDER_REGISTER_EMAIL}
                  customStyle={getStyleProperties(`small_normal`)}
                  isMobile={true}
                >
                  <CustomIcon
                    iconName={iconEmailConfirmName}
                    size={24}
                    customStyle={iconStyleEmailConfirmName}
                  />
                </InputWithMsg>
                <Button
                  colorType={emailButtonColor}
                  fontWeight={"MEDIUM"}
                  fontSize={"XS"}
                  isReserve={false}
                  // onClick={()=>sendAuthMailFunction}
                  onClick={emailButtonClick}
                  height={"36px"}
                  width={"76px"}
                  customStyle={{ top: "50%", transform: "translateY(-19%)" }}
                  disabled={emailStatus !== `OK`}
                >
                  {emailButtonLabel}
                </Button>
              </FlexRowLayout>
            </div>
            <div className={"dp_fx_row fx_jc_ct w_100 ps_rl"}>
              <FlexRowLayout gap={8}>
                {isAuthCodeCheck ? (
                  <InputWithMsg
                    label={authCodeLabel}
                    type="text"
                    value={authCode}
                    status={authCodeStatus}
                    onChange={handleChangeAuthCode}
                    ref={authCodeRef}
                    placeholder={PLACEHOLDER_REGISTER_AUTHENTICATION}
                    customStyle={authInputStyleWithButton}
                    disabled
                  >
                    <CustomIcon
                      iconName={iconAuthCodeConfirmName}
                      size={24}
                      customStyle={iconStyleAuthCodeConfirmName}
                    />
                  </InputWithMsg>
                ) : (
                  <InputWithMsg
                    label={authCodeLabel}
                    type="text"
                    value={authCode}
                    status={authCodeStatus}
                    onChange={handleChangeAuthCode}
                    ref={authCodeRef}
                    placeholder={PLACEHOLDER_REGISTER_AUTHENTICATION}
                    customStyle={authInputStyleWithButton}
                    isMobile={true}
                  >
                    <CustomIcon
                      iconName={iconAuthCodeConfirmName}
                      size={24}
                      customStyle={iconStyleAuthCodeConfirmName}
                    />
                  </InputWithMsg>
                )}

                <Button
                  colorType={authCodeButtonColor}
                  fontWeight={"MEDIUM"}
                  fontSize={"XS"}
                  isReserve={false}
                  onClick={authCodeButtonClick}
                  height={"36px"}
                  width={"76px"}
                  customStyle={{ top: "50%", transform: "translateY(-19%)" }}
                >
                  {authCodeButtonLabel}
                </Button>
              </FlexRowLayout>
            </div>
            <InputWithMsg
              label={passwordLabel}
              type={passwordInputType}
              value={password}
              status={passwordStatus}
              onChange={handleChangePassword}
              ref={passwordRef}
              placeholder={PLACEHOLDER_REGISTER_PASSWORD}
              customStyle={passwordInputStyle}
              isMobile={true}
            >
              <FlexRowLayout gap={12}>
                <CustomIcon
                  iconName={iconPasswordName}
                  size={24}
                  customStyle={{ color: "var(--color_gray6)" }}
                  onClick={showPassword}
                  className={"cs_pt"}
                />
                <CustomIcon
                  iconName={iconPasswordConfirmName}
                  size={24}
                  customStyle={iconStylePasswordConfirmName}
                />
              </FlexRowLayout>
            </InputWithMsg>
            <InputWithMsg
              label={passwordCheckLabel}
              type={passwordCheckInputType}
              value={passwordCheck}
              status={passwordCheckStatus}
              onChange={handleChangePasswordCheck}
              ref={passwordCheckRef}
              press={onKeyPressEnter}
              placeholder={PLACEHOLDER_REGISTER_PASSWORDCHECK}
              customStyle={passwordCheckInputStyle}
              isMobile={true}
            >
              <FlexRowLayout gap={12}>
                <CustomIcon
                  iconName={iconPasswordCheckName}
                  size={24}
                  onClick={showPasswordCheck}
                  customStyle={{ color: "var(--color_gray6)" }}
                  className={"cs_pt"}
                />
                <CustomIcon
                  iconName={iconPasswordCheckConfirmName}
                  size={24}
                  customStyle={iconStylePasswordCheckConfirmName}
                />
              </FlexRowLayout>
            </InputWithMsg>
            <div
              style={{
                height: "1px",
                width: "100%",
                backgroundColor: "var(--color_gray4)",
              }}
            ></div>
          </FlexColLayout>
          <Button
            colorType={registerButtonColor}
            isReserve={false}
            onClick={registerButtonClick}
            height={"52px"}
            width={"350px"}
            disabled={!isRegisterable}
          >
            {TEXT_BUTTON_REGISTER}
          </Button>
        </SectionLayout>
      </InputPartStyle>

      {isAlertModalVisible && (
        <AlertModal
          visible={isAlertModalVisible}
          resetFn={() => setAlertModalVisible(false)}
          okText={alertOkText}
          okFn={() => {
            // if(!stateType) {
            //   setError(true);
            // } else {
            //   setAlertModalVisible(false);
            //   console.log(alertModalType)
            //   if(alertModalType === "MEMBER_CREATED") {
            //       navigate("/login");

            //   }
            // }
            setAlertModalVisible(false);
          }}
          title={alertTitle}
          subTitle={alertSubtitle}
        />
      )}
    </TitlePageLayout>
  );
}

const SectionLayout = styled.div`
  display: flex;
  flex-direction: column;

  gap: 68px;
`;
const InputPartStyle = styled.div`
  width: 390px;
  font-size: var(--fs_s);
  padding: 0px;
  // height: 650px;
  // overflow: scroll;
  padding-bottom: 68px;
`;
