import styled from "@emotion/styled";
import TextareaAutosize from "react-textarea-autosize";

export const InputWithoutBorder = styled.input`
  width: 100%;
  height: 33px;
  display: flex;
  align-items: center;
  padding: 8px;
  gap: 8px;
  color: white;
  font-size: var(--fs_s);
  font-weight: var(--fw_r);
  line-height: 17px;
  letter-spacing: -0.5px;
  background-color: transparent;
  border: none;

  &::placeholder {
    color: #b2b2b2;
    font-size: var(--fs_s);
    font-weight: var(--fw_r);
    line-height: 17px;
    letter-spacing: -0.5px;
  }
`;

export const CustomTextarea = styled(TextareaAutosize)`
  width: 100%;
  padding: 12px 9px 12px 14px;
  color: white;
  font-size: 14px;
  font-weight: 400;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-color: transparent;

  &::placeholder {
    color: rgba(255, 255, 255, 0.2);
    font-size: 14px;
    font-weight: 400;
  }
`;

export const InputGray = styled.input`
  width: 100%;
  height: 38px;
  display: flex;
  align-items: center;
  padding: 12px 9px 12px 14px;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: -0.64px;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.1);
  color: white;

  &::placeholder {
    color: rgba(255, 255, 255, 0.38);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.64px;
  }
`;
