import { message } from "antd";
import { NoticeType } from "antd/es/message/interface";

type messageCallbackFn = (
  type: NoticeType,
  content: string,
  key?: string | number,
  duration?: number
) => Promise<boolean>;

export function useMessage(): [
  messageCallbackFn,
  React.ReactElement<any, string | React.JSXElementConstructor<any>>
] {
  const [messageApi, contextHolder] = message.useMessage();

  const success = (content: string) => {
    messageApi.open({
      type: "success",
      content: content,
    });
  };
  const error = (content: string) => {
    messageApi.open({
      type: "error",
      content: content,
    });
  };

  const messageCallback: messageCallbackFn = async (
    type: NoticeType,
    content: string,
    key?: string | number,
    duration?: number
  ) => {
    messageApi.open({
      type: type,
      content: content,
      key: key,
      duration: duration,
    });
    return true;
  };

  return [messageCallback, contextHolder];
}
