import styled from "@emotion/styled";
import { CustomIcon } from "../icons/CustomIcon";
import IconImage from "../icons/IconImage";

interface ComponentProps {
  searchWord: string;
  setSearchWord: (keyword: string) => void;
  placeholder?: string;
  // width?: string;
  // disabled?: boolean;
  // small?: boolean;
}

export default function SearchBar(props: ComponentProps) {
  const handleChangeSearchWord = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    props.setSearchWord(event.currentTarget.value);
  };

  return (
    <SearchBarLayout>
      <CustomInput
        placeholder={
          props.placeholder
            ? props.placeholder
            : "좋아하는 작품을 검색해보세요!"
        }
        value={props.searchWord}
        onChange={handleChangeSearchWord}
        // disabled={props.disabled === true ? true : false}
      />
      <IconImage
        iconName="search"
        style={{
          marginTop: "12px",
          marginBottom: "12px",
          color: "var(--color_gray6)",
        }}
      />
    </SearchBarLayout>
  );
}

const SearchBarLayout = styled.div`
  width: 100%;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 12px 12px 15px;
  gap: 20px;
  border-radius: 4px;
  background-color: var(--color_bg3);
  border: 1px solid var(--color_gray7);
`;

const CustomInput = styled.input`
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  font-size: var(--fs_xxs);
  font-weight: var(--fw_r);
  color: var(--color_gray2);

  &::placeholder {
    font-size: var(--fs_xxs);
    font-weight: var(--fw_r);
    color: var(--color_gray2);
  }
`;
