import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FlexColLayout } from "../../components/layouts/styles";
import AlertModal from "../../components/modals/AlertModal";
import { CHECK } from "../../strings/string";
import { TermsObjectType } from "../../types/terms";
import { ButtonColorKey } from "../../types/unions/styles/colors/buttonColor";
import MobileCheckBox from "./components/MobileCheckBox";
import MobileTermBox from "./components/MobileTermBox";
import TitlePageLayout from "../../components/layouts/TitlePageLayout";
import styled from "@emotion/styled";
import { Button } from "../../components/buttons/Button";
import { useTitle } from "../../hooks/useTitle";

export default function TermsPage() {
  useTitle("약관")
  const [isAlertModalVisible, setAlertModalVisible] = useState<boolean>(false);
  const [alertTitle, setAlertTitle] = useState<string>("");
  const [alertSubtitle, setAlertSubtitle] = useState<string>("");
  const [buttonType, setButtonType] = useState<ButtonColorKey>(`GRAY5`);
  const [isError, setError] = useState<boolean>(false);
  useEffect(() => {
    if (isError) {
      navigate("/login");
    }
  }, [isError]);

  const location = useLocation();
  const navigate = useNavigate();
  const stateType = location.state as TermsObjectType;
  useEffect(() => {
    if (!stateType) {
      setAlertTitle("비정상적인 접근입니다.");
      setAlertSubtitle("로그인 페이지로 이동합니다.");
      setAlertModalVisible(true);
    } else {
      setServiceTerms(stateType.service);
      setPrivacyTerms(stateType.privacy);
      //       setInvitationDto(stateType.invitationDto);
    }
  }, []);

  const [totalNecessaryTerms, setTotalNecessaryTerms] =
    useState<boolean>(false);
  const [serviceTerms, setServiceTerms] = useState<boolean>(false);
  const [privacyTerms, setPrivacyTerms] = useState<boolean>(false);
  //   const [invitationDto, setInvitationDto] = useState<InvitationDto | undefined>(undefined);

  // const [totalOptionalTerm, setTotalOptionalTerm] = useState<boolean>(false);
  // const [marketing, setMarketing] = useState<boolean>(false);

  useEffect(() => {
    if (serviceTerms === privacyTerms) {
      setTotalNecessaryTerms(serviceTerms);
      if (serviceTerms) {
        setButtonType("PRIMARY");
      } else {
        setButtonType(`GRAY5`);
      }
    } else {
      setTotalNecessaryTerms(false);
      setButtonType(`GRAY5`);
    }
  }, [serviceTerms, privacyTerms]);

  const onClickForTotalNecessary = () => {
    setTotalNecessaryTerms(!totalNecessaryTerms);
    setServiceTerms(!totalNecessaryTerms);
    setPrivacyTerms(!totalNecessaryTerms);
  };

  const moveToRegisterPage = () => {
    if (!totalNecessaryTerms) {
      setAlertTitle("필수 정보에 대한 동의가 필요합니다.");
      setAlertSubtitle("");
      setAlertModalVisible(true);
    } else {
      navigate("/register", {
        state: {
          service: serviceTerms,
          privacy: privacyTerms,
          //   invitationDto: invitationDto,
        },
      });
    }
  };

  return (
    <TitlePageLayout title={"회원가입"} isDark={false}>
      <InputPartStyle>
        <FlexColLayout gap={16} style={{ padding: "0 20px 0 20px" }}>
          <MobileCheckBox
            text="전체 필수 항목 동의하기"
            type={totalNecessaryTerms}
            justifyContentEnd={false}
            onClick={onClickForTotalNecessary}
          />
          <div
            style={{
              height: "1px",
              width: "100%",
              backgroundColor: "var(--color_gray4)",
            }}
          ></div>

          <FlexColLayout gap={4}>
            <div style={{ marginBottom: "28px" }}>
              <MobileTermBox
                name="서비스 이용 약관 동의"
                type={serviceTerms}
                locationTo={"/terms/service"}
                service={serviceTerms}
                private={privacyTerms}
                isNecessary={true}
                onClick={() => setServiceTerms((prev) => !prev)}
              />
              <MobileTermBox
                name="개인정보 처리 방침 동의"
                type={privacyTerms}
                locationTo={`/terms/privacy`}
                service={serviceTerms}
                private={privacyTerms}
                isNecessary={true}
                onClick={() => setPrivacyTerms((prev) => !prev)}
              />
            </div>
          </FlexColLayout>
        </FlexColLayout>

        <Button
          colorType={buttonType}
          isReserve={false}
          height={"48px"}
          width={"100%"}
          onClick={moveToRegisterPage}
          customStyle={{
            position: "fixed",
            bottom: "0",
            left: "0",
            borderRadius: 0,
          }}
        >
          확인
        </Button>
      </InputPartStyle>
      {isAlertModalVisible && (
        <AlertModal
          visible={isAlertModalVisible}
          resetFn={() => setAlertModalVisible(false)}
          okText={CHECK}
          okFn={
            !stateType
              ? () => setError(true)
              : () => setAlertModalVisible(false)
          }
          height={120}
          title={alertTitle}
          subTitle={alertSubtitle}
        />
      )}
    </TitlePageLayout>
  );
}

const InputPartStyle = styled.div`
  width: 390px;
  font-size: var(--fs_s);
`;

