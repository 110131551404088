/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { InputStatus } from "../../types/unions/status";
import { INPUT_COLOR_TYPE } from "../../types/unions/styles/colors/inputColor";

export const InputLayout = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 8px;
`;

export const InputLabel = styled.div<{ status: InputStatus }>`
  width: 100%;
  height: auto;
  color: ${(props) => `var(--${INPUT_COLOR_TYPE[props.status].color})`};
  font-size: var(--fs_xs);
  font-weight: var(--fw_m);
`;

const BasicInput = styled.input`
  width: 100%;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  padding: 13px;
  position: relative;
  border: 1px solid var(--color_gray4);
  font-size: var(--fs_xs);
  line-height: 1;

  &:disabled {
    background: var(--color_white);
    cursor: not-allowed;
  }
`;

export const InputTag = styled(BasicInput)<{ status: InputStatus }>`
  border-radius: var(--border_radius_m);
  border-color: ${(props) => props.status === "ERROR" && "var(--color_red)"};

  &::placeholder {
    color: #c0c0c0;
  }

  &:focus {
    outline: none !important;
    border: ${(props) =>
      props.status === "NORMAL" && "1px solid var(--color_primary1)"};
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-transition: background-color 9999s ease-out;
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
    -webkit-text-fill-color: var(--color_black) !important;
  }
`;

export const InputMsg = styled.div<{ status: InputStatus }>`
  width: 100%;
  height: auto;
  font-size: var(--fs_xs);
  color: ${(props) => `var(--${INPUT_COLOR_TYPE[props.status].color})`};
  text-align: right;
`;

export const InputText = styled.div<{ status?: InputStatus }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  display: flex;
  font-size: var(--fs_s);
  color: ${(props) =>
    props.status && `var(--${INPUT_COLOR_TYPE[props.status].color})`};
`;

export const SearchInputTag = styled(BasicInput)`
  height: 34px;
  border-radius: var(--border_radius_m);
  padding-right: 35px;
  box-shadow: var(--item_box_shadow);
  font-size: var(--fs_xs);
  &:focus {
    outline: none !important;
    border: 1px solid var(--color_middle_gray);
  }
`;

export const SearchInputTagVer2 = styled(BasicInput)`
  height: 48px;
  border-radius: var(--border_radius_m);
  font-size: 16px;
  gap: 16px;
  background-color: var(--color_gray2);
  padding: 0 52px;
  border: none;

  &:focus {
    outline: none !important;
  }
`;
