import styled from "@emotion/styled";
import React from "react";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  width?: number;
  height: number;
}

export default function SizedBox(props: Props) {
  return <BoxLayout styleProps={props} {...props} />;
}

const BoxLayout = styled.div<{ styleProps: Props }>`
  width: ${(props) =>
    props.styleProps.width ? `${props.styleProps.width}px` : "100%"};
  height: ${(props) => props.styleProps.height}px;
`;
