import React, { PropsWithChildren } from "react";
import { ContentLayout, MainLayout, PageLayout } from "./styles";
import PageTitle from "./items/PageTitle";

interface Props extends PropsWithChildren {
  title: string;
  isDark: boolean;
}

export default function TitlePageLayout(props: Props) {
  return (
    <PageLayout isDark={props.isDark}>
      <ContentLayout>
        <MainLayout>
          <PageTitle title={props.title} isDark={props.isDark} />
          {props.children}
        </MainLayout>
      </ContentLayout>
    </PageLayout>
  );
}
