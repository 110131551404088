import React, { PropsWithChildren } from "react";
import { ContentLayout, MainLayout, PageLayout } from "./styles";
import TopLogoBar from "./items/TopLogoBar";

interface Props extends PropsWithChildren {
  isDark: boolean;
}

export default function TopLogoBarPageLayout(props: Props) {
  return (
    <PageLayout isDark={props.isDark}>
      <ContentLayout>
        <MainLayout>
          <TopLogoBar isDark={props.isDark} />
          {props.children}
        </MainLayout>
      </ContentLayout>
    </PageLayout>
  );
}
