import styled from "@emotion/styled";
import React from "react";
import StandardTag from "../../../../components/tags/StandardTag";
import { FlexColLayout } from "../../../../components/layouts/styles";
import { ContentApiResponse } from "../../../../types/content";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

interface Props {
  data: ContentApiResponse;
  isInMyPage?: boolean;
}

export default function MainContent(props: Props) {
  const navigate = useNavigate();
  return (
    <ItemLayout
      onClick={() => {
        navigate(`/detail?id=${props.data.id}`);
      }}
      isInMyPage={props.isInMyPage}
    >
      <ThumbnailImg
        src={props.data.image_url || "imgs/logos/loketrip_logo.svg"}
        style={{ objectFit: props.data.image_url ? "cover" : "contain" }}
      />
      <FlexColLayout gap={2} style={{ paddingLeft: "4px" }}>
        <StandardTag
          scaleKey="TITLE5"
          color="WHITE"
          className="ell"
          customStyle={{ fontWeight: "var(--fw_b)" }}
        >
          {props.data.title}
        </StandardTag>
        <StandardTag scaleKey="SUBTITLE2" color="WHITE" className="ell">
          {props.data.subtitle}
        </StandardTag>
        <UploadTime>
          {format(new Date(props.data.updated_at), "yyyy.MM.dd")}
        </UploadTime>
      </FlexColLayout>
    </ItemLayout>
  );
}

const ItemLayout = styled.div<{ isInMyPage?: boolean }>`
  width: 186px;
  height: 266px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding: 6px;
  gap: 8px;
  border-radius: 4px;
  cursor: pointer;

  & div {
    flex-shrink: 0;
  }

  &:hover {
    border: 1px solid #616161;
    background: var(--color_bg3);
  }

  ${(props) =>
    props.isInMyPage &&
    `
    & div {
    color: #000 !important;
    }
  `}
`;

const ThumbnailImg = styled.img`
  width: 100%;
  height: 176px;
`;

export const UploadTime = styled.div`
  font-size: var(--fs_xxs);
  color: rgba(255, 255, 255, 0.5);
  line-height: var(--line_height_xxs);
`;
