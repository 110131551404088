import styled from "@emotion/styled";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import qs from "qs";

import InitCenterPageLayout from "../../components/layouts/InitCenterPageLayout";
import { axiosCustomerNoTimeout } from "../../apis/axiosInstances";
import { CustomIcon } from "../../components/icons/CustomIcon";
import { Input, InputWithMsg } from "../../components/inputs/Input";
import { FlexColLayout, FlexRowLayout } from "../../components/layouts/styles";
import AlertModal from "../../components/modals/AlertModal";
import { useAuth } from "../../hooks/useAuth";
import { ACTION_BACK, MESSAGE_WRITE_EMAIL, MESSAGE_RE_ERROR_EMAIL, MESSAGE_WRITE_PASSWORD, ALERT_CHECK_PASSWORD, ALERT_CHECK_EMAIL, ALERT_ERROR_OCCURRED, LABEL_EMAIL_ADDRESS, LABEL_PASSWORD, TEXT_REGISTER, MOBILE_TEXT_FORGOT_PASSWORD, TEXT_EMAIL_LOGIN, TEXT_KAKAO_LOGIN } from "../../strings/string";
import { Button } from "../../components/buttons/Button";
import { LOGIN_PAGE_EXCEPTION_PARAM, LoginPageExceptionParamKey } from "../../types/unions/pageParam";
import { useTitle } from "../../hooks/useTitle";

const rest_api_key = `${process.env.REACT_APP_KAKAO_REST_API_KEY}`;
const client_secret_key = `${process.env.REACT_APP_KAKAO_CLIENT_SECRET_KEY}`;
const redirect_uri = `${process.env.REACT_APP_KAKAO_REDIRECT_URI}`;
type LoginAlertModalType = "PASSWORD" | "EMAIL" | "DESCRIPTION";
interface PageProps {}

export default function LoginPage(props: PageProps) {
  useTitle(`로그인`);
  // return (

  // );
  // useTitle(PAGE_LOGIN);
  const navigate = useNavigate();

  const auth = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailMsg, setEmailMsg] = useState("");
  const [passwordMsg, setPasswordMsg] = useState("");
  const [isValidId, setIsValidId] = useState(false);
  const [isValidPw, setIsValidPw] = useState(false);
  const [isPasswordShown, setIsPasswordShown] = useState<boolean>(false);
  const [passwordInputType, setPasswordInputType] =
    useState<string>("password");
  // const [invitationDto, setInvitationDto] = useState<InvitationDto | undefined>(undefined);

  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  // 하위 alert 모달
  const [isAlertModalVisible, setAlertModalVisible] = useState<boolean>(false);
  const [alertTitle, setAlertTitle] = useState<string>("");
  const [alertSubtitle, setAlertSubtitle] = useState<string>("");
  const [alertOkText, setAlertOkText] = useState<string>(ACTION_BACK);
  const [iconName, setIconName] = useState<string>(`eye-close`);
  const [searchParams, setSearchParams] = useSearchParams();

  const location = useLocation();
  const stateType = location.state;

  useEffect(() => {
    if (searchParams.get("error")) {
      const exceptionParams =
        LOGIN_PAGE_EXCEPTION_PARAM[
          searchParams.get("exception") as LoginPageExceptionParamKey
        ];
      const title = exceptionParams.title;
      const subTitle = exceptionParams.subTitle;
      setAlertModalByCase("DESCRIPTION", title, subTitle);
    }
  }, [searchParams]);

  const onKeyPressEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  const showPassword = () => {
    if (isPasswordShown) {
      setIsPasswordShown(false);
      setPasswordInputType("password");
      setIconName(`eye-close`);
    } else {
      setIsPasswordShown(true);
      setPasswordInputType("text");
      setIconName(`eye-alt`);
    }
  };

  useEffect(() => {
    if (emailRef.current) {
      emailRef.current.focus();
    }
    // TODO LIST :  forwardRef() 를 통한 input 태그 내, ref 연결 필요
  }, []);

  useEffect(() => {
    const emailRule =
      /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/;
    if (email === "") {
      setEmailMsg(MESSAGE_WRITE_EMAIL);
      setIsValidId(false);
    } else if (!emailRule.test(email)) {
      setEmailMsg(MESSAGE_RE_ERROR_EMAIL);
      setIsValidId(false);
    } else {
      setEmailMsg("");
      setIsValidId(true);
    }
  }, [email]);

  useEffect(() => {
    if (password === "") {
      setPasswordMsg(MESSAGE_WRITE_PASSWORD);
      setIsValidPw(false);
    } else {
      setPasswordMsg("");
      setIsValidPw(true);
    }
  }, [password]);

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.currentTarget.value);
  };
  const handleChangePW = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.currentTarget.value);
  };

  const setAlertModalByCase = (
    modalType: LoginAlertModalType,
    title?: string,
    subTitle?: string
  ) => {
    // 기본적으로 "돌아가기"
    setAlertOkText(ACTION_BACK);
    switch (modalType) {
      case "PASSWORD": {
        setAlertTitle(ALERT_CHECK_PASSWORD);
        break;
      }
      case "EMAIL": {
        setAlertTitle(ALERT_CHECK_EMAIL);
        break;
      }
      case "DESCRIPTION": {
        setAlertTitle(ALERT_ERROR_OCCURRED);
        if (title !== undefined) {
          setAlertTitle(title);
        }
        break;
      }
    }
    setAlertSubtitle(``);
    if (subTitle !== undefined) {
      setAlertSubtitle(subTitle);
    }
    setAlertModalVisible(true);
  };

  const handleSubmit = () => {
    if (!isValidPw) {
      setAlertModalByCase("PASSWORD");
      if (passwordRef.current) passwordRef.current.focus();
      return;
    }

    if (!isValidId) {
      setAlertModalByCase("EMAIL");
      if (emailRef.current) emailRef.current.focus();
      return;
    }

    axiosCustomerNoTimeout
      .post(
        "/members/login",
        qs.stringify({
          email: email,
          password: password,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.headers.authorization) {
          // if(invitationDto === undefined){
          auth?.onLogin(res.headers.authorization);
          // } else{
          //   auth?.onTeamJoin(res.headers.authorization, invitationDto);
          // }
        }
        navigate(`/`);
      })
      .catch((error) => {
        if (error.response.headers.errormsg) {
          const param =
            LOGIN_PAGE_EXCEPTION_PARAM[
              error.response.headers.errormsg as LoginPageExceptionParamKey
            ];
          const title = param.title;
          const subTitle = param.subTitle;
          setAlertModalByCase("DESCRIPTION", title, subTitle);
        }
      });
  };

    // oauth 요청 URL
    const kakaoURL = `https://kauth.kakao.com/oauth/authorize?client_id=${rest_api_key}&redirect_uri=${redirect_uri}&client_secret=${client_secret_key}&response_type=code`

  return (
    <InitCenterPageLayout>
      <LoginPageLayout>
        <LogoPartStyle
            src={`imgs/logos/loketrip_logo_black.svg`}
            alt={`로고`}
        />
        <InputPartStyle>
          <Input
            label={LABEL_EMAIL_ADDRESS}
            value={email}
            type="text"
            onChange={handleChangeEmail}
            placeholder="abc123@buildingcare.co.kr"
            ref={emailRef}
            customStyle={{
              fontSize: "var(--fs_s)",
              fontWeight: "var(--fw_r)",
              borderColor: `var(--color_gray2)`,
              borderRadius: 4,
            }}
            status={`OK`}
            customLayoutStyle={{ fontWeight: "var(--fw_r)" }}
            isMobile={true}
          />
          <InputWithMsg
            label={LABEL_PASSWORD}
            value={password}
            type={passwordInputType}
            onChange={handleChangePW}
            press={onKeyPressEnter}
            placeholder="••••••••••••"
            ref={passwordRef}
            customStyle={{
              fontSize: "var(--fs_s)",
              fontWeight: "var(--fw_r)",
              borderColor: `var(--color_gray2)`,
              borderRadius: 4,
            }}
            status={`OK`}
            isMobile={true}
          />
        </InputPartStyle>
        <ButtonPartStyle>
          <Button
            colorType={"PRIMARY"}
            isReserve={false}
            fontSize={`M`}
            fontWeight={"MEDIUM"}
            height={"48px"}
            onClick={handleSubmit}
            borderRadius={`L`}
          >
            {TEXT_EMAIL_LOGIN}
          </Button>
          <Button
            colorType={`CUSTOM`}
            backgroundColor={`KAKAO_YELLOW`}
            fontColor={`KAKAO_BLACK`}
            isReserve={false}
            fontSize={`M`}
            fontWeight={"MEDIUM"}
            height={"48px"}
            borderColor={`KAKAO_YELLOW`}
            borderRadius={`L`}
          >
            <KakaoPartStyle
              href={kakaoURL}
            >
              <KakaoLogoStyle>
                <img
                  src={`imgs/logos/kakao_logo.svg`}
                  alt={`kakao`}
                />
              </KakaoLogoStyle>
              {TEXT_KAKAO_LOGIN}
            </KakaoPartStyle>
          </Button>
          <PageButtonRowLayout>
            <Button
              colorType="CUSTOM"
              fontColor="GRAYSCALE7"
              fontSize={`S`}
              fontWeight={`MEDIUM`}
              isReserve={false}
              height={"40px"}
              width={"155px"}
              customStyle={{ border: "0" }}
              onClick={() =>
                navigate("/terms", {
                  state: { service: false, privacy: false },
                })
              }
            >
              <FlexRowLayout gap={4} className="fx_ai_ct">
                {TEXT_REGISTER}
                <CustomIcon
                  size={16}
                  iconName={"nav-arrow-right"}
                  className={`cl_bk`}
                  customStyle={{ color: "var(--color_gray6)" }}
                />
              </FlexRowLayout>
            </Button>
            <Link to={`/password`}>
              <Button
                colorType="CUSTOM"
                fontColor="GRAYSCALE7"
                fontSize="XS"
                fontWeight="REGULAR"
                height={"40px"}
                width={"155px"}
                isReserve={false}
                customStyle={{ border: "0" }}
              >
                <FlexRowLayout gap={4} className="fx_ai_ct">
                  {MOBILE_TEXT_FORGOT_PASSWORD}
                  <CustomIcon
                    size={16}
                    iconName={"nav-arrow-right"}
                    className={`cl_bk`}
                    customStyle={{ color: "var(--color_gray6)" }}
                  />
                </FlexRowLayout>
              </Button>
            </Link>
          </PageButtonRowLayout>
        </ButtonPartStyle>
      </LoginPageLayout>
      {isAlertModalVisible && (
        <AlertModal
          visible={isAlertModalVisible}
          resetFn={() => setAlertModalVisible(false)}
          okFn={() => setAlertModalVisible(false)}
          okText={alertOkText}
          title={alertTitle}
          subTitle={alertSubtitle}
        />
      )}
    </InitCenterPageLayout>
  );
}

const LoginPageLayout = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0 var(--gap_16);
  gap: 36px;
`;

const LogoPartStyle = styled.img`
  width: 114px;
  height: 36px;
`;

const InputPartStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: var(--gap_20);
`;

const ButtonPartStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--gap_20);
`

const KakaoPartStyle = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const KakaoLogoStyle = styled.div`
  width: 40px;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;
`

const PageButtonRowLayout = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 20px;
`;
