import styled from "@emotion/styled";
import React, { useState } from "react";
import StandardTag from "../../../../components/tags/StandardTag";
import {
  FlexColLayout,
  FlexRowLayout,
} from "../../../../components/layouts/styles";
import { ContentApiResponse } from "../../../../types/content";
import IconImage from "../../../../components/icons/IconImage";
import { useChangeLike } from "../../../../apis/likes";
import { LikesApiRequest } from "../../../../types/likes";
import { useAuth } from "../../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";

interface Props {
  isInDetailPage?: boolean;
  data: ContentApiResponse;
  isLike?: boolean;
}

export default function MainBanner(props: Props) {
  const [isLike, setIsLike] = useState(props.isLike);
  const auth = useAuth();
  const navigate = useNavigate();
  const changeLikeMutation = useChangeLike();

  const clickLike = () => {
    const request: LikesApiRequest = {
      id: null,
      member_id: auth?.loggedInMember?.id || null,
      content_id: props.data.id,
      is_deleted: false,
    };
    changeLikeMutation.mutate(request, {
      onSuccess(data, variables, context) {
        setIsLike((prev) => !prev);
      },
    });
  };
  return (
    <ItemLayout
      isInDetailPage={props.isInDetailPage}
      imageUrl={props.data.image_url}
      onClick={() => {
        navigate(`/detail?id=${props.data.id}`);
      }}
    >
      <FlexColLayout style={{ padding: "0 20px 26px 19px" }}>
        <Title scaleKey="SUBTITLE1">{props.data.subtitle}</Title>
        <Title
          scaleKey="TITLE1"
          customStyle={{
            // wordWrap: "unset",
            marginBottom: props.isInDetailPage ? "24px" : "12px",
          }}
        >
          <FlexRowLayout gap={10} className="fx_jc_btw fx_ai_end">
            <div className="ell">{props.data.title}</div>
            {auth?.loggedInMember && (
              <>
                {props.isInDetailPage && (
                  <div
                    className="dp_fx_col fx_jc_ct"
                    onClick={() => {
                      clickLike();
                    }}
                  >
                    {isLike ? (
                      <IconImage iconName="like" />
                    ) : (
                      <IconImage iconName="like_empty" />
                    )}
                  </div>
                )}
              </>
            )}
          </FlexRowLayout>
        </Title>
        <Title scaleKey="BODY1" customStyle={{ marginBottom: "10px" }}>
          {props.data.detail}
        </Title>
        <Title scaleKey="TAG1" customStyle={{ fontWeight: "var(--fw_m)" }}>
          {props.data.tag}
        </Title>
      </FlexColLayout>
    </ItemLayout>
  );
}

const ItemLayout = styled.div<{
  isInDetailPage?: boolean;
  imageUrl: string | null;
}>`
  width: ${(props) => (props.isInDetailPage ? "100%" : "342px")};
  height: ${(props) => (props.isInDetailPage ? "440px" : "520px")};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-shrink: 0;
  border-radius: ${(props) => (props.isInDetailPage ? "0px" : "8px")};
  background-color: var(--color_gray9);
  ${(props) =>
    props.imageUrl &&
    `background-image: url(${props.imageUrl});
    background-size: cover;
    background-repeat: no-repeat;`};

  cursor: pointer;

  ${(props) =>
    props.isInDetailPage &&
    `
    & div {
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  `}
`;

const Title = styled(StandardTag)`
  color: var(--color_white);
  word-wrap: break-word;
  word-break: keep-all;
`;
