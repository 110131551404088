import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TermsObjectType } from "../../types/terms";
import TitlePageLayout from "../../components/layouts/TitlePageLayout";
import { Button } from "../../components/buttons/Button";
import AlertModal from "../../components/modals/AlertModal";
import { CHECK } from "../../strings/string";
import { serviceTermsContent, privacyTermsContent } from "./components/TermsContent";
import { useTitle } from "../../hooks/useTitle";

const InputPartStyle = styled.div`
  width: 390px;
  font-size: var(--fs_s);
`;

const DetailPartLayout = styled.div`
  font-size: 8px;
  height: 640px;
  // padding-top: 28px;
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 8px;
  // max-height: 100%;
  // background-color: var(--color_gray2);
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  overflow: scroll;
`;
export default function TermDetailPage() {
  useTitle("약관 상세")
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const stateType = location.state as TermsObjectType;

  const [serviceTerms, setServiceTerms] = useState<boolean>(false);
  const [privateTerms, setPrivateTerms] = useState<boolean>(false);

  const [isAlertModalVisible, setAlertModalVisible] = useState<boolean>(false);
  const [alertTitle, setAlertTitle] = useState<string>("");
  const [alertSubtitle, setAlertSubtitle] = useState<string>("");
  useEffect(() => {
    if (!stateType) {
      setAlertTitle("비정상적인 접근입니다.");
      setAlertSubtitle("로그인 페이지로 이동합니다.");
      setAlertModalVisible(true);
    } else {
      setServiceTerms(stateType.service);
      setPrivateTerms(stateType.privacy);
    }
  }, []);

  const [isError, setError] = useState<boolean>(false);
  useEffect(() => {
    if (isError) {
      navigate("/login");
    }
  }, [isError]);

  const [pathParam, setPathParam] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  useEffect(() => {
    if (params.id) {
      switch (params.id) {
        case "service":
          setPathParam("service");
          setTitle("서비스 이용 약관");
          setContent(serviceTermsContent);
          break;
        case "privacy":
          setPathParam("privacy");
          setTitle("개인정보처리방침");
          setContent(privacyTermsContent);
          break;
      }
    }
  }, []);

  const onClick = () => {
    if (pathParam === "service") {
      navigate(`/terms`, {
        state: {
          service: true,
          privacy: privateTerms,
        },
      });
    } else if (pathParam === "privacy") {
      navigate(`/terms`, {
        state: {
          service: serviceTerms,
          privacy: true,
        },
      });
    } else {
    }
  };
  return (
    <TitlePageLayout title={title} isDark={false}>
      <InputPartStyle>
        <DetailPartLayout>{content}</DetailPartLayout>
      </InputPartStyle>
      <Button
        colorType={"PRIMARY"}
        isReserve={false}
        onClick={onClick}
        height={"48px"}
        fontSize={"M"}
        width={"100%"}
        fontWeight={"MEDIUM"}
        customStyle={{
          position: "fixed",
          bottom: "0",
          left: "0",
        }}
      >
        동의하기
      </Button>

      {isAlertModalVisible && (
        <AlertModal
          visible={isAlertModalVisible}
          resetFn={() => setAlertModalVisible(false)}
          okText={CHECK}
          okFn={
            !stateType
              ? () => setError(true)
              : () => setAlertModalVisible(false)
          }
          height={120}
          title={alertTitle}
          subTitle={alertSubtitle}
        />
      )}
    </TitlePageLayout>

    // <InitDetailLayout title={"빌딩케어 스페이스 " + title}>
    //   <SectionLayout>
    //     <InputPartStyle>
    //       <DetailPartLayout>{content}</DetailPartLayout>
    //     </InputPartStyle>
    //     <Button
    //       colorType={"PRIMARY"}
    //       isMixedUse={false}
    //       isReserve={false}
    //       onClick={onClick}
    //       height={"48px"}
    //       fontSize={"M"}
    //       width={"420px"}
    //       fontWeight={"MEDIUM"}
    //     >
    //       동의하기
    //     </Button>
    //   </SectionLayout>
    //   {isAlertModalVisible && (
    //     <AlertModal
    //       visible={isAlertModalVisible}
    //       resetFunction={() => setAlertModalVisible(false)}
    //       okText={CHECK}
    //       okFunction={
    //         !stateType
    //           ? () => setError(true)
    //           : () => setAlertModalVisible(false)
    //       }
    //       height={120}
    //       size={"M"}
    //       title={alertTitle}
    //       subtitle={alertSubtitle}
    //     />
    //   )}
    // </InitDetailLayout>
  );
}
