import React, { PropsWithChildren } from "react";
import {
  InputTag,
  InputLabel,
  InputLayout,
  InputMsg,
  InputText,
} from "./style";
import { InputStatus } from "../../types/unions/status";

export interface InputProps extends PropsWithChildren {
  value?: string | number | readonly string[];
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  type?: React.HTMLInputTypeAttribute;
  label?: string;
  label2?: string;
  msg?: string;
  status?: InputStatus;
  isEmpty?: boolean;
  disabled?: boolean;
  name?: string;
  ref?: React.RefObject<HTMLInputElement>;
  press?: (e: any) => void;
  min?: string | number;
  max?: string | number;
  customStyle?: React.CSSProperties;
  customLayoutStyle?: React.CSSProperties;
  placeholder?: string;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  isMobile?: boolean;
  onBlur?: () => void;
}

export const getInputTag = (props: InputProps, isWithMsg: boolean) => {
  return (
    <InputTag
      type={props.type || "text"}
      value={props.value || ""}
      status={props.status || "NORMAL"}
      onChange={props.onChange}
      disabled={props.disabled}
      name={props.name}
      ref={props.ref}
      min={props.min}
      max={props.max}
      style={props.customStyle}
      placeholder={props.placeholder}
      onClick={props.onClick}
    />
  );
};
export function Input(props: InputProps) {
  if (props.isEmpty) return <InputLayout />;
  return (
    <InputLayout style={props.customLayoutStyle}>
      {props.label && (
        <InputLabel status={props.status || "NORMAL"}>{props.label}</InputLabel>
      )}
      {getInputTag(props, false)}
    </InputLayout>
  );
}

export function InputWithMsg(props: InputProps) {
  if (props.isEmpty) return <InputLayout />;
  return (
    <InputLayout style={props.customLayoutStyle} onBlur={props.onBlur}>
      {props.label && (
        <InputLabel status={props.status || "NORMAL"}>{props.label}</InputLabel>
      )}
      <div className="ps_rl w_100">
        {getInputTag(props, true)}
        <InputText status={props.status || "NORMAL"}>
          {props.children}
        </InputText>
      </div>
      {props.msg && (
        <InputMsg status={props.status || "NORMAL"}>{props.msg}</InputMsg>
      )}
    </InputLayout>
  );
}
