import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import SizedBox from "../../components/boxes/SizedBox";
import PaddingBox from "../../components/boxes/PaddingBox";
import StandardTag from "../../components/tags/StandardTag";
import NoTitlePageLayout from "../../components/layouts/NoTitlePageLayout";
import MainBanner from "./components/items/MainBanner";
import CustomTab from "../../components/tabs/CustomTab";
import DetailContent from "./components/items/DetailContent";
import ReviewSection from "./components/sections/ReviewSection";
import MapSection from "./components/sections/MapSection";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useGetContentDetail } from "../../apis/content";
import { ContentDetailResponse } from "../../types/content";
import { isNumber } from "../../utils/utils";
import { CustomIcon } from "../../components/icons/CustomIcon";
import { useAuth } from "../../hooks/useAuth";
import { checkAdminOrHigher } from "../../utils/auth";
import { useTitle } from "../../hooks/useTitle";

type TabKey = "CONTENT" | "MAP";

export default function MainDetailCustomerPage() {
  useTitle("콘텐츠 상세");
  const [contentId, setContentId] = useState<number>();
  const [selectedTab, setSelectedTab] = useState<TabKey>("CONTENT");
  const [contentDetail, setContentDetail] = useState<ContentDetailResponse>();

  // const { id } = useParams() as { id: string };
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const navigate = useNavigate();
  const auth = useAuth();

  useEffect(() => {
    if (!id) {
    } else {
      if (isNumber(id)) {
        setContentId(Number(id));
      }
    }
  }, [id]);

  const res = useGetContentDetail(false, contentId);

  useEffect(() => {
    if (res.data?.data) {
      setContentDetail(res.data.data);
    }
  }, [res]);

  const handleClickBackButton = () => {
    // eslint-disable-next-line no-restricted-globals
    history.go(-1);
  };

  return (
    <NoTitlePageLayout title="" isDark={true}>
      <BackButton
        onClick={() => {
          handleClickBackButton();
          // if (auth?.isAdminPage) {
          //   handleClickBackButton();
          // } else {
          //   navigate("/more");
          // }
        }}
      >
        <CustomIcon iconName={`nav-arrow-left`} size={24} />
      </BackButton>
      {checkAdminOrHigher(auth) && contentDetail && (
        <EditButton
          onClick={() => {
            navigate(
              `/admin/write?id=${contentDetail.content_api_response.id}`
            );
          }}
        >
          수정하기
        </EditButton>
      )}
      {contentDetail && (
        <>
          <MainBanner
            isInDetailPage
            data={contentDetail.content_api_response}
            isLike={contentDetail.like}
          />
          <PaddingBox only={{ top: 8, left: 32, bottom: 34, right: 33 }}>
            <TabBoxLayout>
              <CustomTab
                label={"소개"}
                isSelected={selectedTab === "CONTENT"}
                size={"S"}
                isDark
                onClick={() => {
                  setSelectedTab("CONTENT");
                }}
              />
              <CustomTab
                label={"위치"}
                isSelected={selectedTab === "MAP"}
                size={"S"}
                isDark
                onClick={() => {
                  setSelectedTab("MAP");
                }}
              />
            </TabBoxLayout>
          </PaddingBox>
          {selectedTab === "CONTENT" && (
            <Description>
              <StandardTag scaleKey="BODY1" color="GRAYSCALE1">
                {contentDetail.content_api_response.description}
              </StandardTag>
            </Description>
          )}
          {selectedTab === "CONTENT" && (
            <>
              <ContentListLayout>
                {contentDetail.location_list.map((location) => (
                  <DetailContent key={location.id} data={location} />
                ))}
              </ContentListLayout>
              <ReviewSection
                reviewList={contentDetail.review_list}
                contentId={contentDetail.content_api_response.id}
              />
            </>
          )}
          {selectedTab === "MAP" && <MapSection data={contentDetail} />}
        </>
      )}
      <SizedBox height={40} />
    </NoTitlePageLayout>
  );
}

const BackButton = styled.div`
  width: 24px;
  height: 24px;
  position: absolute;
  margin-left: 16px;
  cursor: pointer;
  top: 10px;
  left: 0;
  color: rgba(255, 255, 255, 0.34);
`;

const EditButton = styled.div`
  position: absolute;
  margin-right: 16px;
  cursor: pointer;
  top: 10px;
  right: 0;
  color: var(--color_primary1);
  font-size: 14px;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.28px;
`;

const TabBoxLayout = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Description = styled.div`
  width: 100%;
  white-space: pre-wrap;
  word-break: keep-all;
  padding: 0 16px;
  margin-bottom: 40px;

  & div {
    font-weight: var(--fw_r);
  }
`;

const ContentListLayout = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  gap: 40px;
  margin-bottom: 40px;
`;
