import { FONT_SIZE_TYPE, FONT_WEIGHT_TYPE, LINE_HEIGHT_TYPE } from "./font";

export type TagStyleKey =
  | "TITLE1"
  | "TITLE2"
  | "TITLE3"
  | "TITLE4"
  | "TITLE5"
  | "SUBTITLE1"
  | "SUBTITLE2"
  | "BODY1"
  | "BODY2"
  | "TAG1"
  | "TAG2"
  | "TAG3"
  | "LABEL1"
  | "LABEL2"
  | "BUTTON1";

export type TagStyleType = {
  [k in TagStyleKey]: {
    size: string;
    weight: string;
    lineHeight: string;
  };
};

export const TAG_STYLE_TYPE: TagStyleType = {
  TITLE1: {
    size: FONT_SIZE_TYPE.XXXL,
    weight: FONT_WEIGHT_TYPE.BOLD,
    lineHeight: LINE_HEIGHT_TYPE.XXXL,
  },
  TITLE2: {
    size: FONT_SIZE_TYPE.XXL,
    weight: FONT_WEIGHT_TYPE.BOLD,
    lineHeight: LINE_HEIGHT_TYPE.XXL,
  },
  TITLE3: {
    size: FONT_SIZE_TYPE.XL,
    weight: FONT_WEIGHT_TYPE.BOLD,
    lineHeight: LINE_HEIGHT_TYPE.XL,
  },
  TITLE4: {
    size: FONT_SIZE_TYPE.L,
    weight: FONT_WEIGHT_TYPE.BOLD,
    lineHeight: LINE_HEIGHT_TYPE.L,
  },
  TITLE5: {
    size: FONT_SIZE_TYPE.M,
    weight: FONT_WEIGHT_TYPE.MEDIUM,
    lineHeight: LINE_HEIGHT_TYPE.M,
  },
  SUBTITLE1: {
    size: FONT_SIZE_TYPE.S,
    weight: FONT_WEIGHT_TYPE.BOLD,
    lineHeight: LINE_HEIGHT_TYPE.M,
  },
  SUBTITLE2: {
    size: FONT_SIZE_TYPE.XS,
    weight: FONT_WEIGHT_TYPE.REGULAR,
    lineHeight: LINE_HEIGHT_TYPE.S,
  },
  BODY1: {
    size: FONT_SIZE_TYPE.M,
    weight: FONT_WEIGHT_TYPE.BOLD,
    lineHeight: LINE_HEIGHT_TYPE.M,
  },
  BODY2: {
    size: FONT_SIZE_TYPE.XS,
    weight: FONT_WEIGHT_TYPE.REGULAR,
    lineHeight: LINE_HEIGHT_TYPE.S,
  },
  TAG1: {
    size: FONT_SIZE_TYPE.XS,
    weight: FONT_WEIGHT_TYPE.BOLD,
    lineHeight: LINE_HEIGHT_TYPE.XS,
  },
  TAG2: {
    size: FONT_SIZE_TYPE.XXS,
    weight: FONT_WEIGHT_TYPE.REGULAR,
    lineHeight: LINE_HEIGHT_TYPE.XXXS,
  },
  TAG3: {
    size: FONT_SIZE_TYPE.XXS,
    weight: FONT_WEIGHT_TYPE.BOLD,
    lineHeight: LINE_HEIGHT_TYPE.XXXS,
  },
  LABEL1: {
    size: FONT_SIZE_TYPE.XXS,
    weight: FONT_WEIGHT_TYPE.MEDIUM,
    lineHeight: LINE_HEIGHT_TYPE.XXXS,
  },
  LABEL2: {
    size: FONT_SIZE_TYPE.XXS,
    weight: FONT_WEIGHT_TYPE.REGULAR,
    lineHeight: LINE_HEIGHT_TYPE.XXS,
  },
  BUTTON1: {
    size: FONT_SIZE_TYPE.XS,
    weight: FONT_WEIGHT_TYPE.MEDIUM,
    lineHeight: LINE_HEIGHT_TYPE.XXS,
  },
};

export const title1Style = TAG_STYLE_TYPE[`TITLE1`];
export const title2Style = TAG_STYLE_TYPE[`TITLE2`];
export const title3Style = TAG_STYLE_TYPE[`TITLE3`];
export const title4Style = TAG_STYLE_TYPE[`TITLE4`];
export const title5Style = TAG_STYLE_TYPE[`TITLE5`];
export const subTitle1Style = TAG_STYLE_TYPE[`SUBTITLE1`];
export const subTitle2Style = TAG_STYLE_TYPE[`SUBTITLE2`];
export const body1Style = TAG_STYLE_TYPE[`BODY1`];
export const body2Style = TAG_STYLE_TYPE[`BODY2`];
export const tag1Style = TAG_STYLE_TYPE[`TAG1`];
export const tag2Style = TAG_STYLE_TYPE[`TAG2`];
export const tag3Style = TAG_STYLE_TYPE[`TAG3`];
export const label1Style = TAG_STYLE_TYPE[`LABEL1`];
export const label2Style = TAG_STYLE_TYPE[`LABEL2`];
export const button1Style = TAG_STYLE_TYPE[`BUTTON1`];
