import styled from "@emotion/styled";
import { CustomIcon } from "../../icons/CustomIcon";
import StandardTag from "../../tags/StandardTag";

interface PartProps {
  title: string;
  isDark: boolean;
}

export default function PageTitle(props: PartProps) {
  const handleClickBackButton = () => {
    // eslint-disable-next-line no-restricted-globals
    history.go(-1);
  };

  return (
    <MobilePageTitlePartLayout isDark={props.isDark}>
      <BackButton onClick={handleClickBackButton}>
        <CustomIcon iconName={`nav-arrow-left`} size={24} color="GRAYSCALE3" />
      </BackButton>
      <StandardTag scaleKey={"TITLE4"} color={props.isDark ? "WHITE" : "BLACK"}>
        {props.title}
      </StandardTag>
      <div></div>
    </MobilePageTitlePartLayout>
  );
}

const MobilePageTitlePartLayout = styled.div<{ isDark: boolean }>`
  width: 100%;
  height: 52px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0px 16px 0;
  background-color: transparent;
  border-bottom: 1px solid
    ${(props) =>
      props.isDark ? `rgba(239, 239, 239, 0.12)` : `rgba(0, 0, 0, 0.12)`};
`;

const BackButton = styled.div`
  width: 24px;
  height: 24px;
  position: absolute;
  margin-left: 16px;
  cursor: pointer;
  left: 0;
`;
