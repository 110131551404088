import styled from "@emotion/styled";
import TitlePageLayout from "../../components/layouts/TitlePageLayout";
import { useEffect, useState } from "react";
import IconImage from "../../components/icons/IconImage";
import { FlexColLayout, FlexRowLayout } from "../../components/layouts/styles";
import StandardTag from "../../components/tags/StandardTag";
import { useAuth } from "../../hooks/useAuth";
import ProtectedRoute from "../../containers/contexts/ProtectedRoute";
import { InputLabel, InputLayout } from "../../components/inputs/style";
import { Input, InputWithMsg } from "../../components/inputs/Input";
import { PLACEHOLDER_REGISTER_NAME } from "../../strings/string";
import { Button } from "../../components/buttons/Button";
import { useDeleteMember, useUpdateMember } from "../../apis/member";
import { MemberApiRequest, MemberDeleteRequest } from "../../types/member";
import { useMessage } from "../../hooks/useMessage";
import { useQueryClient } from "@tanstack/react-query";
import { Drawer } from "antd";
import AlertModal from "../../components/modals/AlertModal";
import { useNavigate } from "react-router-dom";
import { useTitle } from "../../hooks/useTitle";
import { isValidString } from "../../utils/checkType";

interface PageProps {}
export default function MyInfoPage(props: PageProps) {
  useTitle("내정보 관리")
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [name, setName] = useState<string>("");
  const [profileUrl, setProfileUrl] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [alertModalVisible, setAlertModalVisible] = useState(false);

  const auth = useAuth();
  const navigate = useNavigate();
  const [messageFn, messageContextHolder] = useMessage();
  const queryClient = useQueryClient();

  const updateMutation = useUpdateMember();
  const deleteMutation = useDeleteMember();

  useEffect(() => {
    if (auth?.loggedInMember) {
      setName(auth.loggedInMember.name);
      setProfileUrl(auth.loggedInMember.profile_url);
    }
  }, [auth]);

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const updateInfo = () => {
    if (auth?.loggedInMember) {
      if (name.length === 0) {
        messageFn("error", "이름을 입력해 주세요.");
        return;
      }
      const request: MemberApiRequest = {
        id: auth.loggedInMember.id,
        email: auth.loggedInMember.email,
        profile_url: auth.loggedInMember.profile_url,
        name: name,
      };
      updateMutation.mutate(request, {
        onSuccess(data, variables, context) {
          queryClient.invalidateQueries(["loggedInUser"]);
          messageFn("success", "내정보 수정이 완료되었습니다.").then(() => {
            setIsUpdateMode(false);
          });
        },
      });
    }
  };

  const deleteMember = () => {
    if (auth?.loggedInMember) {
      const request: MemberDeleteRequest = {
        member_id: auth.loggedInMember.id,
        password: password,
      };
      deleteMutation.mutate(request, {
        onSuccess(data, variables, context) {
          setAlertModalVisible(true);
          hideDrawer();
        },
      });
    }
  };

  const showDrawer = () => {
    setDrawerOpen(true);
  };

  const hideDrawer = () => {
    setDrawerOpen(false);
    setPassword("");
  };

  return (
    <ProtectedRoute isAdminPage={false}>
      <TitlePageLayout title={`내정보 관리`} isDark={false}>
        {auth?.loggedInMember && (
          <ContentLayout>
            <MyInfoLayout>
              <FlexColLayout gap={16}>
                <StandardTag
                  scaleKey="BODY1"
                  customStyle={{ fontWeight: "var(--fw_r)" }}
                >
                  프로필 정보
                </StandardTag>
                <FlexRowLayout className="fx_jc_ct">
                  <ProfileImg>
                    {isValidString(profileUrl) ? (
                      <ProfileImgInside
                        src={profileUrl}
                      />
                    ) : (
                      <IconImage iconName="mypage" size={58} />
                    )}
                  </ProfileImg>
                </FlexRowLayout>
              </FlexColLayout>
              <FlexColLayout gap={18}>
                <InputLayout>
                  <InputLabel status={"NORMAL"} className="dp_fx_row">
                    이름{" "}
                    <div className="cl_pr1" style={{ marginLeft: "2px" }}>
                      *
                    </div>
                  </InputLabel>
                  {isUpdateMode ? (
                    <Input
                      value={name}
                      onChange={handleChangeName}
                      placeholder={PLACEHOLDER_REGISTER_NAME}
                      isMobile={true}
                    />
                  ) : (
                    <NameLayout>
                      {auth.loggedInMember.name}
                      <IconImage
                        iconName="edit"
                        size={24}
                        onClick={() => {
                          setIsUpdateMode(true);
                        }}
                      />
                    </NameLayout>
                  )}
                </InputLayout>
                <InputWithMsg
                  label={"이메일 주소"}
                  value={auth?.loggedInMember.email || ""}
                  status={"NORMAL"}
                  isMobile={true}
                  disabled
                  customStyle={{
                    color: "#aeaeae",
                    // border: "1px solid rgba(0, 0, 0, 0.12)",
                  }}
                ></InputWithMsg>
              </FlexColLayout>
            </MyInfoLayout>
            <ButtonBoxLayout>
              {isUpdateMode ? (
                <Button
                  colorType={"GRAY2_FONT_BLACK2"}
                  isWithIcon={false}
                  onClick={() => {
                    updateInfo();
                  }}
                  height="44px"
                  fontSize="XS"
                  customStyle={{ color: "black", marginTop: "21px" }}
                >
                  완료
                </Button>
              ) : (
                <>
                  <ActionButton
                    onClick={() => {
                      navigate("/password");
                    }}
                  >비밀번호 변경</ActionButton>
                  <ActionButton
                    className="cl_pr1"
                    onClick={() => {
                      showDrawer();
                    }}
                  >
                    계정삭제
                  </ActionButton>
                  <ActionButton
                    onClick={() => {
                      auth.onLogout(true);
                    }}
                  >
                    로그아웃
                  </ActionButton>
                </>
              )}
            </ButtonBoxLayout>
          </ContentLayout>
        )}
        {messageContextHolder}
        <Drawer
          // title={u}
          placement="bottom"
          closable={false}
          onClose={hideDrawer}
          open={drawerOpen}
          getContainer={false}
          height={336}
          maskClosable={false}
          bodyStyle={{
            padding: "50px 10px 24px 10px",
            backgroundColor: "#f8f8f8",
          }}
        >
          <FlexColLayout gap={24}>
            <FlexColLayout gap={20}>
              <StandardTag scaleKey="SUBTITLE2" className="ta_ct">
                계정을 삭제하실 경우 앱에서 사용했던 회원님의
                <br /> 이용 내역의 복구가 불가합니다. <br />
                그래도 계정을 삭제하시겠습니까?
              </StandardTag>
              <Input
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                placeholder={"비밀번호를 입력해 주세요."}
                isMobile={true}
                type="password"
              />
            </FlexColLayout>
            <FlexColLayout gap={10}>
              <Button
                colorType={"GRAY05_FONT_BLACK_OUTLINE"}
                isWithIcon={false}
                onClick={() => {
                  hideDrawer();
                }}
                height="44px"
                fontSize="XS"
                borderRadius="M"
              >
                취소
              </Button>
              <Button
                colorType={"BLACK"}
                isWithIcon={false}
                onClick={() => {
                  deleteMember();
                }}
                height="44px"
                fontSize="XS"
                borderRadius="M"
              >
                삭제하기
              </Button>
            </FlexColLayout>
          </FlexColLayout>
        </Drawer>
      </TitlePageLayout>
      {alertModalVisible && (
        <AlertModal
          visible={alertModalVisible}
          resetFn={() => {
            setAlertModalVisible(false);
            navigate("/login");
          }}
          title={undefined}
          subTitle={"계정 삭제가 완료되었습니다."}
          okText={undefined}
          okFn={() => {
            setAlertModalVisible(false);
            navigate("/login");
          }}
          hasPrimaryButton
        />
      )}
    </ProtectedRoute>
  );
}

const ContentLayout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

const MyInfoLayout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

const ProfileImg = styled.div`
  width: 88px;
  height: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--color_gray8);
`;

const ProfileImgInside = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
`

const NameLayout = styled.div`
  height: 44px;
  display: flex;
  gap: 18px;
  padding: 13px;
`;

const ButtonBoxLayout = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 19px;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
`;

const ActionButton = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 8px;
  color: #171717;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: -0.32px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
`;
