import { Icon } from "@iconify/react";
import React from "react";
import {
  COLOR_TYPE,
  ColorKey,
} from "../../types/unions/styles/colors/basicColor";

interface Props {
  size?: 16 | 20 | 24 | 28 | 32;
  iconName: string;
  className?: string;
  onClick?: () => void;
  customStyle?: React.CSSProperties;
  color?: ColorKey;
}

export function CustomIcon(props: Props) {
  let color = ``;
  if (props.color) {
    color = `var(--${COLOR_TYPE[props.color]})`;
  }
  return (
    <Icon
      width={props.size ? props.size : "24"}
      height={props.size ? props.size : "24"}
      icon={`iconoir:${props.iconName}`}
      className={props.className + " fx_auto"}
      onClick={props.onClick}
      style={props.customStyle}
      color={color}
    />
  );
}
