import styled from "@emotion/styled";
import React from "react";
import StandardTag from "../tags/StandardTag";

interface Props {
  label: string;
  isSelected: boolean;
  isDark?: boolean;
  size: "M" | "S";
  onClick: () => void;
}

export default function CustomTab(props: Props) {
  return (
    <TabLayout size={props.size} onClick={props.onClick}>
      <ContentLayout isSelected={props.isSelected} isDark={props.isDark}>
        <StandardTag scaleKey="TITLE5">{props.label}</StandardTag>
      </ContentLayout>
      <TabBar isSelected={props.isSelected} isDark={props.isDark} />
    </TabLayout>
  );
}

const TabLayout = styled.div<{ size: "M" | "S" }>`
  width: ${(props) => (props.size === "M" ? "155px" : "130px")};
  height: ${(props) => (props.size === "M" ? "50px" : "45px")};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ContentLayout = styled.div<{ isSelected: boolean; isDark?: boolean }>`
  width: 100%;
  height: 47px;
  display: flex;
  justify-content: center;
  align-items: center;

  & div {
    color: ${(props) =>
      props.isSelected
        ? !props.isDark
          ? "var(--color_black)"
          : "var(--color_white)"
        : !props.isDark
        ? "rgba(0,0,0,0.38)"
        : "rgba(255,255,255,0.3) "};
  }
`;

const TabBar = styled.div<{ isSelected: boolean; isDark?: boolean }>`
  width: 100%;
  height: 2px;
  background-color: ${(props) =>
    props.isSelected
      ? !props.isDark
        ? "var(--color_black)"
        : "var(--color_white)"
      : !props.isDark
      ? "var(--color_white)"
      : "transparent"};
`;
