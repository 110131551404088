import { CustomIcon } from "../../../../components/icons/CustomIcon";
import SelectLayout, {
  BasicSelectItem,
} from "../../../../components/selects/SelectLayout";
import { ContentStatusKey } from "../../../../types/unions/enums/ContentStatus";

interface ListProps {
  setShowOptionList: (b: boolean) => void;
  selectedStatus?: ContentStatusKey;
  setSelectedStatus: (status: ContentStatusKey) => void;
}

export default function ContentStatusDropdown(props: ListProps) {
  return (
    <SelectLayout
      setOpen={props.setShowOptionList}
      style={{
        top: "0",
        left: "calc(100% + 10px)",
        boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.25)",
      }}
    >
      <BasicSelectItem
        onClick={() => {
          props.setSelectedStatus("PENDING");
          props.setShowOptionList(false);
        }}
        style={{
          backgroundColor:
            props.selectedStatus === "PENDING" ? "#f0f0f0" : "white",
        }}
      >
        미발행
      </BasicSelectItem>
      <BasicSelectItem
        onClick={() => {
          props.setSelectedStatus("PUBLISHED");
          props.setShowOptionList(false);
        }}
        style={{
          backgroundColor:
            props.selectedStatus === "PUBLISHED" ? "#f0f0f0" : "white",
        }}
      >
        발행
      </BasicSelectItem>
    </SelectLayout>
  );
}
