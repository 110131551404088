export const MEMBER_ROLE = {
  ERROR: { id: 0, name: "ERROR", kor_name: `에러` },
  ROLE_REJECTED: { id: 1, name: "ROLE_REJECTED", kor_name: `반려됨` },
  ROLE_UNREGISTERED: { id: 2, name: "ROLE_UNREGISTERED", kor_name: `미승인` },
  ROLE_REGISTERED: { id: 3, name: "ROLE_REGISTERED", kor_name: `승인` },
  ROLE_ADMIN: { id: 100, name: "ROLE_ADMIN", kor_name: `관리자` },
  ROLE_SUPER_ADMIN: {
    id: 999,
    name: "ROLE_SUPER_ADMIN",
    kor_name: `최고 관리자`,
  },
} as const;
export type MemberRoleKey = keyof typeof MEMBER_ROLE;
export type MemberRole = (typeof MEMBER_ROLE)[keyof typeof MEMBER_ROLE];
