import styled from "@emotion/styled";
import React from "react";
import { ReviewApiResponse } from "../../../../types/review";
import CustomRate from "../../../../components/rates/CustomRate";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

interface Props {
  data: ReviewApiResponse;
}

export default function Review(props: Props) {
  const navigate = useNavigate();
  return (
    <ItemLayout
      onClick={() => {
        navigate(`/detail?id=${props.data.content_id}`);
      }}
    >
      <Title className="ell">{props.data.content_title}</Title>
      <ReviewInfoLayout>
        <Info>평가</Info>
        <Info>
          <CustomRate value={props.data.rate} />
        </Info>
      </ReviewInfoLayout>
      <ReviewInfoLayout>
        <Info>작성일</Info>
        <Info>{format(new Date(props.data.created_at), "yyyy.MM.dd")}</Info>
      </ReviewInfoLayout>
      <Detail>{props.data.detail}</Detail>
    </ItemLayout>
  );
}

const ItemLayout = styled.div`
  width: 100%;
  min-height: 172px;
  display: flex;
  flex-direction: column;
  gap: 13px;
  padding: 20px 18px 24px 19px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background: #f8f8f8;

  &:hover {
    background: var(--color_gray1);
  }
`;

const Title = styled.div`
  color: #000;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px; /* 150% */
  letter-spacing: -0.64px;
`;

const ReviewInfoLayout = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;

  & > div:first-of-type {
    width: 34px;
    flex-shrink: 0;
  }
`;

const Info = styled.div`
  color: rgba(0, 0, 0, 0.38);
  font-size: 12px;
  font-weight: 400;
`;

const Detail = styled.div`
  /* white-space: pre; */
  color: rgba(0, 0, 0, 0.38);
  font-size: 14px;
  font-weight: 400;
`;
