import { useState, useEffect } from "react";
import PaddingBox from "../../../../components/boxes/PaddingBox";
import { FlexColLayout } from "../../../../components/layouts/styles";
import { useGetReviewList } from "../../../../apis/review";
import { ReviewApiResponse } from "../../../../types/review";
import Review from "../items/Review";
import CenterBox from "../../../../components/boxes/CenterBox";
import StandardTag from "../../../../components/tags/StandardTag";

export default function ReviewSection() {
  const [reviewList, setReviewList] = useState<ReviewApiResponse[]>([]);

  const res = useGetReviewList();
  useEffect(() => {
    if (res.data?.data) {
      setReviewList(res.data.data);
    }
  }, [res]);

  return (
    <PaddingBox all={16} className="dp_fx_col fx_grow">
      {reviewList.length > 0 ? (
        <FlexColLayout gap={10}>
          {reviewList.map((review) => (
            <Review key={review.id} data={review} />
          ))}
        </FlexColLayout>
      ) : (
        <CenterBox>
          <StandardTag scaleKey="BODY2" color="GRAYSCALE2">
            작성한 리뷰가 없습니다.
          </StandardTag>
        </CenterBox>
      )}
    </PaddingBox>
  );
}
