import styled from "@emotion/styled";
import { COLOR_TYPE } from "../../types/unions/styles/colors/basicColor";

export const PageLayout = styled.div<{ isDark?: boolean }>`
  width: 100%;
  padding: 0;
  overflow: auto;
  min-height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background-color: ${(props) =>
    props.isDark
      ? `var(--${COLOR_TYPE["BACKGROUND2"]})`
      : // : `var(--color_gray05)`};
        `var(--color_white)`};
  overflow-x: hidden;
`;

export const ContentLayout = styled.div`
  width: 390px;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  /* padding-bottom: 50px; */
`;

export const MainLayout = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* flex-grow: 1; */
`;

export const FlexRowLayout = styled.div<{ gap?: number }>`
  display: flex;
  gap: ${(props) => `${props.gap || 0}px`};
`;

export const FlexColLayout = styled.div<{ gap?: number }>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => `${props.gap || 0}px`};
`;
