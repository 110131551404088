import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import StandardTag from "../../../../components/tags/StandardTag";
import { UploadTime } from "../../../main/components/items/MainContent";
import IconImage from "../../../../components/icons/IconImage";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { ContentApiResponse } from "../../../../types/content";
import { format } from "date-fns";
import { useUpdateRecommended } from "../../../../apis/content";
import CustomCheckbox from "../../../../components/checkboxes/CustomCheckbox";
import { useQueryClient } from "@tanstack/react-query";
import { ContentStatusKey } from "../../../../types/unions/enums/ContentStatus";
import { useNavigate } from "react-router-dom";

interface Props {
  canSort?: boolean;
  data: ContentApiResponse;
  canRecommend?: boolean;
  checkAll?: boolean;
  onClickToCheck?: (content: ContentApiResponse, isCancel: boolean) => void;
}

export default function ContentItem(props: Props) {
  const [isChecked, setIsChecked] = useState(false);
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({ id: props.data.id });

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const updateRecommendedMutation = useUpdateRecommended();

  useEffect(() => {
    setIsChecked(props.checkAll || false);
  }, [props.checkAll]);

  useEffect(() => {
    if (props.data.status === "PENDING") {
      if (props.onClickToCheck) {
        props.onClickToCheck(props.data, !isChecked);
      }
    }
  }, [isChecked, props.data]);

  const updateRecommended = () => {
    updateRecommendedMutation.mutate(props.data.id, {
      onSuccess(data, variables, context) {
        queryClient.invalidateQueries([
          "content",
          "list",
          { filter: "PUBLISHED" as ContentStatusKey },
        ]);
      },
    });
  };

  return (
    <ItemLayout
      ref={props.canSort ? setNodeRef : null}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
      }}
      onClick={() => {
        navigate(`/detail?id=${props.data.id}`);
      }}
    >
      <ImgBoxLayout imageUrl={props.data.image_url}>
        {props.data.status === "PUBLISHED" && (
          <StarImgLayout
            onClick={(e) => {
              e.stopPropagation();
              if (
                props.data.recommended ||
                (!props.data.recommended && props.canRecommend)
              ) {
                updateRecommended();
              }
            }}
          >
            {props.data.recommended ? (
              <IconImage iconName="star_yellow" />
            ) : (
              <IconImage iconName="star_gray" />
            )}
          </StarImgLayout>
        )}
      </ImgBoxLayout>
      <TitleBoxLayout>
        <StandardTag
          scaleKey="TITLE5"
          color="WHITE"
          className="ell"
          customStyle={{ fontWeight: "var(--fw_b)" }}
        >
          {props.data.title}
        </StandardTag>
        <StandardTag
          scaleKey="SUBTITLE2"
          color="WHITE"
          className="ell"
          customStyle={{ lineHeight: "var(--line_height_m)" }}
        >
          {props.data.subtitle}
        </StandardTag>
        <Caption className="ell">{props.data.detail}</Caption>
        <UploadTime>
          {format(new Date(props.data.updated_at), "yyyy.MM.dd")}
        </UploadTime>
      </TitleBoxLayout>
      <div
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {props.data.status === "PUBLISHED" ? (
          <>
            {props.canSort && (
              <div
                ref={setActivatorNodeRef}
                style={{
                  cursor: props.canSort ? "move" : "",
                }}
                {...attributes}
                {...listeners}
              >
                <IconImage iconName="sort" />
              </div>
            )}
          </>
        ) : (
          <CustomCheckbox
            checked={isChecked}
            onClick={() => {
              setIsChecked((prev) => !prev);
            }}
          />
        )}
      </div>
    </ItemLayout>
  );
}

const ItemLayout = styled.div`
  width: 100%;
  height: 124px;
  display: flex;
  align-items: center;
  gap: 11px;
  padding: 2px 0;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const ImgBoxLayout = styled.div<{ imageUrl: string | null }>`
  width: 120px;
  height: 120px;
  position: relative;
  background-color: var(--color_gray9);
  ${(props) =>
    props.imageUrl &&
    `background-image: url(${props.imageUrl});
    background-size: cover;
    background-repeat: no-repeat;`};
`;

const StarImgLayout = styled.div`
  position: absolute;
  top: 13px;
  left: 9px;
`;

const TitleBoxLayout = styled.div`
  width: 213px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Caption = styled.div`
  font-size: var(--fs_xxs);
  color: rgba(255, 255, 255, 0.5);
  line-height: var(--line_height_xxs);
`;
