import { useMutation } from "@tanstack/react-query";
import { axiosCustomerNoTimeout } from "./axiosInstances";

export interface EmailProps {
  email: string;
  for_register: boolean;
}

export interface EmailCheckProps extends EmailProps {
  code: string;
}


const target = "authorizes";

export const UseSendAuthMail = () => {
  return useMutation((authRequest: EmailProps) =>
  axiosCustomerNoTimeout.post(`${target}/set`, authRequest)
  );
};

export const UseCheckAuthMail = () => {
  return useMutation((authRequest: EmailCheckProps) =>
  axiosCustomerNoTimeout.put(`${target}/check`, authRequest)
  );
};
