import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { CustomIcon } from "../../../components/icons/CustomIcon";

interface ComponentProps {
  name: string;
  type: boolean;
  locationTo: string;
  service: boolean;
  private: boolean;
  isNecessary?: boolean;
  onClick?: () => void;
}

const TermBoxStyle = styled.div`
  width: 100%;
  font-size: var(--fs_s);
  display: flex;
  justify-content: space-between;
  height: 42px;
  border-radius: 2px;
  align-items: center;
  text-align: left;
`;
const SubText = styled.span`
  margin-top: 1px;
  color: var(--color_black);
  font-size: var(--fs_s);
  font-weight: var(--fw_r);
  margin-left: 16px;
`;
const TermButtonStyle = styled.div`
  color: var(--color_gray6);
`;
const NecessaryText = styled.span`
  margin-top: 1px;
  color: var(--color_primary1);
  font-size: var(--fs_xs);
  font-weight: var(--fw_r);
  margin-left: 4px;
`;
const MobileTermBox = (props: ComponentProps) => {
  const navigate = useNavigate();
  const locationTo = () => {
    console.log(props.locationTo);
    navigate(`${props.locationTo}`, {
      state: {
        service: props.service,
        private: props.private,
      },
    });
  };
  const className = "check";
  let customStyle: React.CSSProperties;
  let textNecessary;
  const narrowRightStyle = {
    color: "var(--color_gray6)",
  };
  if (!props.type) {
    customStyle = {
      color: "var(--color_gray5)",
      backgroundColor: "var(--color_white)",
      borderRadius: "50%",
      border: "1px solid var(--color_gray5)",
    };
  } else {
    customStyle = {
      color: `var(--color_white)`,
      backgroundColor: "var(--color_primary1)",
      borderRadius: "50%",
      border: "1px solid var(--color_primary1)",
    };
  }
  if (props.isNecessary) {
    textNecessary = "(필수)";
  }
  return (
    <>
      <TermBoxStyle>
        <div className={"dp_fx_row fx_jc_ct ps_rl "}>
          <CustomIcon
            iconName={className}
            customStyle={customStyle}
            onClick={props.onClick}
            className="cs_pt"
          />
          <div>
            <SubText
              style={{
                color: "var(--color_gray9)",
                fontSize: "var(--fs_xs)",
                fontWeight: "var(--fw_m)",
              }}
            >
              {props.name}
              <NecessaryText>{textNecessary}</NecessaryText>
            </SubText>
          </div>
        </div>

        <CustomIcon
          iconName={"nav-arrow-right"}
          size={24}
          customStyle={narrowRightStyle}
          onClick={locationTo}
        />
      </TermBoxStyle>
    </>
  );
};

export default MobileTermBox;
