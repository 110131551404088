import styled from "@emotion/styled";
import React, { PropsWithChildren } from "react";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  all?: number;
  symmetric?: { vertical?: number; horizontal?: number };
  only?: { top?: number; left?: number; bottom?: number; right?: number };
}

export default function PaddingBox(props: Props) {
  return <BoxLayout styleProps={props} {...props} />;
}

const BoxLayout = styled.div<{ styleProps: Props }>`
  width: 100%;
  ${(props) =>
    props.styleProps.all &&
    `
  padding: ${props.styleProps.all}px;
`}
  ${(props) =>
    props.styleProps.symmetric &&
    `
  padding: ${props.styleProps.symmetric.vertical || 0}px ${
      props.styleProps.symmetric.horizontal || 0
    }px;
`}
  ${(props) =>
    props.styleProps.only &&
    `
  padding: ${props.styleProps.only.top || 0}px ${
      props.styleProps.only.right || 0
    }px ${props.styleProps.only.bottom || 0}px ${
      props.styleProps.only.left || 0
    }px;
`}
`;
