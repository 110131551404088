import styled from "@emotion/styled";
import React, { CSSProperties } from "react";

interface Props {
  size?: 24 | 14 | 44 | 58;
  iconName: string;
  style?: CSSProperties;
  onClick?: () => void;
}

export default function IconImage(props: Props) {
  return (
    <IconImgLayout
      src={`/imgs/icons/${props.iconName}.svg`}
      size={props.size}
      style={props.style}
      onClick={props.onClick}
    />
  );
}

const IconImgLayout = styled.img<{ size?: number }>`
  width: ${(props) => props.size || 24}px;
  height: ${(props) => props.size || 24}px;
  flex-shrink: 0;
`;
