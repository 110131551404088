export const BOOKING_PAGE_PARAM = {
        COMMON: "common",
        SINGLE: "single",
      } as const;
      export type BookingPageParam =
        typeof BOOKING_PAGE_PARAM[keyof typeof BOOKING_PAGE_PARAM];
      
      export const LOGIN_PAGE_EXCEPTION_PARAM = {
        NOT_LOGIN: {
          name: "NOT_LOGIN",
          title:
            `로그인되어 있지 않습니다.`,
          subTitle: `원활한 서비스 이용을 위해 로그인을 먼저 해주세요.`,
        },
        LOGIN_WRONG_INPUT: {
          name: "LOGIN_WRONG_INPUT",
          title:
            `비밀번호를 잘못 입력했습니다.`,
          subTitle: `입력하신 내용을 다시 확인해 주세요.`,
        },
        LOGIN_INTERNAL: {
          name: "LOGIN_INTERNAL",
          title:
            `내부 시스템 문제로 인해 요청을 처리할 수 없습니다.`,
          subTitle:`관리자에게 문의하세요.`,
        },
        LOGIN_USER_NOT_FOUND: {
          name: "LOGIN_USER_NOT_FOUND",
          title: `계정이 존재하지 않습니다.`,
          subTitle: `회원가입 후 로그인 해주세요.`,
        },
        LOGIN_DENIED: {
          name: "LOGIN_DENIED",
          title: `인증 요청이 거부되었습니다.`,
          subTitle: `관리자에게 문의하세요.`,
        },
        LOGIN_ERROR_DEFAULT: {
          name: "LOGIN_ERROR_DEFAULT",
          title:
            `알 수 없는 이유로 로그인에 실패하였습니다.`,
          subTitle: `관리자에게 문의하세요.`,
        },
        // TODO: 2022-11-24 YongSu Chang
        //  나중에 이런 케이스들도 추가되면 다시 주석 풀어야
        MEMBER_NOT_REGISTERED: {
          name: "MEMBER_NOT_REGISTERED",
          title: `회원가입 승인 절차 진행중입니다.`,
          subTitle: `승인 후 로그인이 가능합니다.`,
        },
        MEMBER_REJECTED: {
          name: "MEMBER_REJECTED",
          title: `회원가입 승인이 반려된 계정입니다.`,
          subTitle: `관리자에게 문의하세요.`,
        },
        // MEMBER_NOT_ACCEPT_TERMS: {
        //   name: "MEMBER_NOT_ACCEPT_TERMS",
        //   title: `약관에 동의하지 않았습니다.`,
        //   subTitle: `약관 동의 후 서비스 이용 가능합니다.`,
        // },
        // MEMBER_ROLE_NOT_ADMIN: {
        //   name: "MEMBER_ROLE_NOT_ADMIN",
        //   title: `관리자 계정이 아닙니다.`,
        //   subTitle: `관리자 계정으로 로그인하시기 바랍니다.`,
        // },
      } as const;
      export type LoginPageExceptionParamKey =
        keyof typeof LOGIN_PAGE_EXCEPTION_PARAM;
      export type LoginPageExceptionParam =
        typeof LOGIN_PAGE_EXCEPTION_PARAM[keyof typeof LOGIN_PAGE_EXCEPTION_PARAM];
      