import React, { useState } from "react";
import {
  FlexColLayout,
  FlexRowLayout,
} from "../../../../components/layouts/styles";
import styled from "@emotion/styled";
import PaddingBox from "../../../../components/boxes/PaddingBox";
import CustomCheckbox from "../../../../components/checkboxes/CustomCheckbox";
import Review from "../items/Review";
import CenterBox from "../../../../components/boxes/CenterBox";
import StandardTag from "../../../../components/tags/StandardTag";
import { ReviewApiResponse } from "../../../../types/review";
import ConfirmModal from "../../../../components/modals/ConfirmModal";
import { useNavigate } from "react-router-dom";
import ReviewWriteModal from "../../../../components/modals/custom/ReviewWriteModal";
import { useAuth } from "../../../../hooks/useAuth";

interface Props {
  contentId: number;
  reviewList: ReviewApiResponse[];
}

// 내가 쓴 댓글보기, 로그인되어 있으면 후기 작성창, 아니면 확인창 뜨도록
export default function ReviewSection(props: Props) {
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [reviewModalVisible, setReviewModalVisible] = useState(false);
  const [selectedReview, setSelectedReview] = useState<ReviewApiResponse>();
  const [isMine, setIsMine] = useState(false);

  const auth = useAuth();
  const navigate = useNavigate();

  return (
    <PaddingBox symmetric={{ horizontal: 16 }}>
      <FlexColLayout gap={20}>
        <FlexColLayout
          gap={21}
          style={{ marginBottom: "21px" }}
          onClick={() => {
            if (auth?.loggedInMember) {
              setReviewModalVisible(true);
            } else {
              setConfirmModalVisible(true);
            }
          }}
        >
          <FlexRowLayout gap={8}>
            <AddDescriptionLayout>
              더 재밌는 콘텐츠 제작을 위해 멋진 생각을 남겨주세요:)
            </AddDescriptionLayout>
            <AddButton>등록</AddButton>
          </FlexRowLayout>
          <ReviewCount>총 {props.reviewList.length}개의 댓글</ReviewCount>
        </FlexColLayout>
        {props.reviewList.length > 0 ? (
          <>
            <CustomCheckbox
              checked={isMine}
              label={"내가 쓴 댓글보기"}
              onClick={() => {
                setIsMine((prev) => !prev);
              }}
            />
            <FlexColLayout>
              {props.reviewList.map((review) => {
                if (isMine) {
                  if (review.member_id !== auth?.loggedInMember?.id) {
                    return null;
                  }
                }
                return (
                  <Review
                    key={review.id}
                    data={review}
                    updateFn={() => {
                      setSelectedReview(review);
                      setReviewModalVisible(true);
                    }}
                  />
                );
              })}
            </FlexColLayout>
          </>
        ) : (
          <CenterBox className="ta_ct">
            <StandardTag scaleKey="BODY2" color="GRAYSCALE2">
              댓글이 없습니다.
              <br /> 첫 댓글을 달아주세요!
            </StandardTag>
          </CenterBox>
        )}
      </FlexColLayout>
      {confirmModalVisible && (
        <ConfirmModal
          visible={confirmModalVisible}
          resetFn={() => {
            setConfirmModalVisible(false);
          }}
          title={undefined}
          subTitle={"로그인이 필요한 기능입니다.\n로그인 하시겠습니까?"}
          okText={undefined}
          cancelText={undefined}
          okFn={() => {
            navigate("/login ");
          }}
          cancelFn={undefined}
        />
      )}
      {reviewModalVisible && (
        <ReviewWriteModal
          visible={reviewModalVisible}
          resetFn={() => {
            setReviewModalVisible(false);
            setSelectedReview(undefined);
          }}
          contentId={props.contentId}
          review={selectedReview}
        />
      )}
    </PaddingBox>
  );
}

const AddDescriptionLayout = styled.div`
  width: 100%;
  height: 34px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #8d8a8a;
  font-size: var(--fs_xxs);
  font-weight: var(--fw_r);
  letter-spacing: -0.5px;
  background-color: var(--color_gray8);
  border-radius: 4px;
  border: 1px solid #4a4646;
`;

const AddButton = styled.div`
  width: 46px;
  height: 34px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: #242426;
  font-size: var(--fs_xxs);
  font-weight: var(--fw_r);
  border-radius: 4px;
  background: var(--color_gray6);
`;

const ReviewCount = styled.div`
  color: var(--color_primary1);
  font-size: var(--fs_xxs);
  font-weight: var(--fw_r);
`;
