import styled from "@emotion/styled";
import React from "react";

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

export default function CenterBox(props: Props) {
  return <BoxLayout {...props} />;
}

const BoxLayout = styled.div`
  width: 100%;
  height: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;
