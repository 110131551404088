import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import TopLogoBarPageLayout from "../../components/layouts/TopLogoBarPageLayout";
import SearchBar from "../../components/inputs/SearchBar";
import SizedBox from "../../components/boxes/SizedBox";
import PaddingBox from "../../components/boxes/PaddingBox";
import { FlexRowLayout } from "../../components/layouts/styles";
import StandardTag from "../../components/tags/StandardTag";
import MainBanner from "./components/items/MainBanner";
import { useGetRecommendedContentList } from "../../apis/content";
import { ContentApiResponse } from "../../types/content";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { useTitle } from "../../hooks/useTitle";

export default function MainCustomerPage() {
  useTitle("메인");
  const [searchWord, setSearchWord] = useState("");
  const [contentList, setContentList] = useState<ContentApiResponse[]>([]);

  const auth = useAuth();
  const navigate = useNavigate();

  const res = useGetRecommendedContentList(false);

  useEffect(() => {
    if (res.data?.data) {
      setContentList(res.data.data);
    }
  }, [res]);

  return (
    <TopLogoBarPageLayout isDark={true}>
      <SizedBox height={23} />
      <PaddingBox symmetric={{ horizontal: 16 }}>
        <SearchBar searchWord={searchWord} setSearchWord={setSearchWord} />
      </PaddingBox>
      <SizedBox height={18} />
      <PaddingBox only={{ top: 25, left: 16, bottom: 60 }}>
        <FlexRowLayout className="fx_jc_btw">
          <StandardTag scaleKey="TITLE2" color="WHITE">
            이번주 추천 로케트립!
          </StandardTag>
          <StandardTag
            scaleKey="BUTTON1"
            className="dp_fx_col fx_jc_end cs_pt"
            customStyle={{
              color: "rgba(255,255,255,0.3)",
              marginRight: "16px",
            }}
            onClick={() => {
              if (auth?.isAdminPage) {
                navigate("/admin/management");
              } else {
                navigate("/more");
              }
            }}
          >
            {auth?.isAdminPage ? <>리스트 관리</> : <>더보기</>}
          </StandardTag>
        </FlexRowLayout>
        <SizedBox height={28} />
        <FlexRowLayout
          gap={10}
          // style={{ width: "fit-content" }}
          className="scroll_hz"
        >
          {contentList &&
            contentList.map((content) => {
              if (!checkContentIncludesSearchWord(content, searchWord)) {
                return null;
              }
              return <MainBanner key={content.id} data={content} />;
            })}
        </FlexRowLayout>
      </PaddingBox>
    </TopLogoBarPageLayout>
  );
}

export const checkContentIncludesSearchWord = (
  content: ContentApiResponse,
  searchWord: string
) => {
  if (searchWord.length === 0) {
    return true;
  }
  if (
    content.title.includes(searchWord) ||
    content.subtitle?.includes(searchWord) ||
    content.tag?.includes(searchWord) ||
    content.detail?.includes(searchWord)
  ) {
    return true;
  }
  return false;
};
