import { useState, useEffect } from "react";
import PaddingBox from "../../../../components/boxes/PaddingBox";
import { FlexColLayout } from "../../../../components/layouts/styles";
import CenterBox from "../../../../components/boxes/CenterBox";
import StandardTag from "../../../../components/tags/StandardTag";
import { ContentApiResponse } from "../../../../types/content";
import { useGetLikeContentList } from "../../../../apis/content";
import MainContent from "../../../main/components/items/MainContent";
import styled from "@emotion/styled";

export default function LikeSection() {
  const [contentList, setContentList] = useState<ContentApiResponse[]>([]);

  const res = useGetLikeContentList(false);
  useEffect(() => {
    if (res.data?.data) {
      setContentList(res.data.data);
    }
  }, [res]);

  return (
    <PaddingBox
      symmetric={{ vertical: 16, horizontal: 8 }}
      className="dp_fx_col fx_grow"
    >
      {contentList.length > 0 ? (
        <ContentLayout>
          {contentList.map((content) => (
            <MainContent key={content.id} data={content} isInMyPage />
          ))}
        </ContentLayout>
      ) : (
        <CenterBox>
          <StandardTag scaleKey="BODY2" color="GRAYSCALE2">
            좋아요 누른 콘텐츠가 없습니다.
          </StandardTag>
        </CenterBox>
      )}
    </PaddingBox>
  );
}

const ContentLayout = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px 2px;
`;
