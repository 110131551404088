import styled from "@emotion/styled";
import IconImage from "../../icons/IconImage";
import { useAuth } from "../../../hooks/useAuth";
import { Segmented } from "antd";
import StandardTag from "../../tags/StandardTag";
import { useState } from "react";
import ConfirmModal from "../../modals/ConfirmModal";
import { useNavigate } from "react-router-dom";
import { checkAdminOrHigher } from "../../../utils/auth";
import { isValidString } from "../../../utils/checkType";

interface PartProps {
  isDark: boolean;
}

export default function TopLogoBar(props: PartProps) {
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const navigate = useNavigate();
  const auth = useAuth();

  const handleClickModuleButton = (isAdminModule: boolean) => {
    auth?.changeModule(isAdminModule);
  };

  return (
    <MobilePageTitlePartLayout isDark={props.isDark}>
      <LogoImg src="imgs/logos/loketrip_logo.svg" />
      {auth && checkAdminOrHigher(auth) && (
        <Segmented
          style={{
            width: "174px",
          }}
          defaultValue={auth.isAdminPage ? "admin" : "customer"}
          options={[
            {
              value: "admin",
              icon: (
                <div
                  style={
                    auth.isAdminPage
                      ? {
                          display: "flex",
                          justifyContent: "center",
                          color: "#000",
                          width: "87px",
                          height: "100%",
                        }
                      : {
                          display: "flex",
                          color: "#8c8c8c",
                          justifyContent: "center",
                          width: "87px",
                          height: "100%",
                        }
                  }
                >
                  <StandardTag scaleKey={`BUTTON1`} className="fs_xs">
                    관리자 모드
                  </StandardTag>
                </div>
              ),
            },
            {
              value: "customer",
              icon: (
                <div
                  style={
                    !auth.isAdminPage
                      ? {
                          display: "flex",
                          color: "#000",
                          justifyContent: "center",
                          width: "87px",
                          height: "100%",
                        }
                      : {
                          display: "flex",
                          color: "#8c8c8c",
                          justifyContent: "center",
                          width: "87px",
                          height: "100%",
                        }
                  }
                >
                  <StandardTag scaleKey={`BUTTON1`} className="fs_s">
                    사용자 모드
                  </StandardTag>
                </div>
              ),
            },
          ]}
          onChange={(value) => {
            let result: boolean = false;

            if (value === "admin") {
              result = true;
            } else {
              result = false;
            }
            setTimeout(() => {
              handleClickModuleButton(result);
            }, 300);
          }}
          onResize={undefined}
          onResizeCapture={undefined}
        />
      )}
      {auth?.loggedInMember ? (
        <>
          {isValidString(auth.loggedInMember.profile_url) ? (
            <ProfileImgInside
              src={auth.loggedInMember.profile_url}
              alt="프로필 사진"
              onClick={() => navigate("/my_page")}
            />
          ) : (
            <IconImage
              iconName="mypage"
              onClick={() => navigate("/my_page")}
            />
          )}
        </>
      ) : (
        <IconImage
          iconName="mypage"
          onClick={() => {
            setConfirmModalVisible(true);
          }}
        />
      )}
      {confirmModalVisible && (
        <ConfirmModal
          visible={confirmModalVisible}
          resetFn={() => {
            setConfirmModalVisible(false);
          }}
          title={undefined}
          subTitle={"로그인이 필요한 기능입니다.\n로그인 하시겠습니까?"}
          okText={undefined}
          cancelText={undefined}
          okFn={() => {
            navigate("/login ");
          }}
          cancelFn={undefined}
        />
      )}
    </MobilePageTitlePartLayout>
  );
}

const MobilePageTitlePartLayout = styled.div<{ isDark: boolean }>`
  width: 100%;
  height: 42px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 16px;
  background-color: transparent;
`;

const LogoImg = styled.img`
  width: 114px;
  height: 30px;
`;

const ProfileImgInside = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
`
