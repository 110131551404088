export function isNumber(value: string | number) {
  return !isNaN(Number(value));
}

export function checkDateFormat(value: string) {
  const regExp = /^(\d{4})-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/;
  return regExp.test(value);
}

export function isValidDate(value: string) {
  var result = true;
  try {
    var date = value.split("-");
    var y = parseInt(date[0], 10),
      m = parseInt(date[1], 10),
      d = parseInt(date[2], 10);

    var dateRegex =
      /^(?=\d)(?:(?:31(?!.(?:0?[2469]|11))|(?:30|29)(?!.0?2)|29(?=.0?2.(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00)))(?:\x20|$))|(?:2[0-8]|1\d|0?[1-9]))([-.\/])(?:1[012]|0?[1-9])\1(?:1[6-9]|[2-9]\d)?\d\d(?:(?=\x20\d)\x20|$))?(((0?[1-9]|1[012])(:[0-5]\d){0,2}(\x20[AP]M))|([01]\d|2[0-3])(:[0-5]\d){1,2})?$/;
    result = dateRegex.test(d + "-" + m + "-" + y);
  } catch (err) {
    result = false;
  }
  return result;
}

export function isValidString(value: string | undefined): boolean {
  if(value === undefined) {
    return false;
  }
  if(value === ``) {
    return false;
  }
  if(value === null) {
    return false;
  }
  return true;
}

export function isValidList(list: any[] | undefined | null): boolean {
  if(list === null) {
    return false;
  }
  if(list === undefined) {
    return false;
  }
  return true;
}
