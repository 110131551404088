import styled from "@emotion/styled";
import React, { PropsWithChildren } from "react";
import { ContentLayout, MainLayout, PageLayout } from "./styles";

export default function InitCenterPageLayout(props: PropsWithChildren) {
  return (
    <PageLayout>
      <ContentLayout>
        <CustomMainLayout>{props.children}</CustomMainLayout>
      </ContentLayout>
    </PageLayout>
  );
}

const CustomMainLayout = styled(MainLayout)`
  justify-content: center;
`;
