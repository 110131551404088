import { DndContext, closestCenter } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import React, { useEffect, useState } from "react";
import {
  useGetContentListByStatus,
  useUpdateShowIndexForContentList,
} from "../../../../apis/content";
import CenterBox from "../../../../components/boxes/CenterBox";
import PaddingBox from "../../../../components/boxes/PaddingBox";
import IconImage from "../../../../components/icons/IconImage";
import SearchBar from "../../../../components/inputs/SearchBar";
import { FlexColLayout } from "../../../../components/layouts/styles";
import StandardTag from "../../../../components/tags/StandardTag";
import {
  ContentApiResponse,
  ContentShowIndexRequest,
} from "../../../../types/content";
import ContentItem from "../items/ContentItem";
import { TitleBoxLayout } from "../../ContentManagementAdminPage";
import { checkContentIncludesSearchWord } from "../../../main/MainCustomerPage";

export default function PublishedSection() {
  const [searchWord, setSearchWord] = useState("");
  const [contentList, setContentList] = useState<ContentApiResponse[]>([]);
  const [recommendedContentList, setRecommendedContentList] = useState<
    ContentApiResponse[]
  >([]);
  const [normalContentList, setNormalContentList] = useState<
    ContentApiResponse[]
  >([]);
  const [requestList, setRequestList] = useState<ContentShowIndexRequest[]>([]);

  const res = useGetContentListByStatus(true, "PUBLISHED");
  const updateShowIndexMutation = useUpdateShowIndexForContentList();

  useEffect(() => {
    if (res.data?.data) {
      setContentList(res.data.data);
    }
  }, [res]);

  useEffect(() => {
    if (contentList.length > 0) {
      const newRecommendedContentList: ContentApiResponse[] = [];
      const newNormalContentList: ContentApiResponse[] = [];
      for (const content of contentList) {
        if (content.recommended) {
          newRecommendedContentList.push(content);
        } else {
          newNormalContentList.push(content);
        }
      }
      setRecommendedContentList(
        newRecommendedContentList.sort((a, b) => {
          if (a.show_index === null) {
            return -1;
          }
          if (b.show_index === null) {
            return -1;
          }
          if (a.show_index > b.show_index) {
            return 1;
          } else if (a.show_index < b.show_index) {
            return -1;
          } else {
            return 0;
          }
        })
      );
      setNormalContentList(newNormalContentList);
    }
  }, [contentList]);

  useEffect(() => {
    if (recommendedContentList.length > 0) {
      const newRequestList: ContentShowIndexRequest[] = [];
      for (const idx in recommendedContentList) {
        const content = recommendedContentList[idx];
        const request: ContentShowIndexRequest = {
          id: content.id,
          show_index: Number(idx),
        };
        newRequestList.push(request);
      }
      setRequestList(newRequestList);
    }
  }, [recommendedContentList]);

  useEffect(() => {
    if (requestList.length > 0) {
      updateShowIndexMutation.mutate(requestList);
    }
  }, [requestList]);

  //드래그 종료 시 리스트 정렬 함수
  function handleDragEnd(event: any) {
    const { active, over } = event;
    if (active !== null && over !== null && active.id !== over.id) {
      setRecommendedContentList((prev) => {
        let activeIndex: number = 0;
        let overIndex: number = 0;
        prev.map((content, index) => {
          if (content.id === active.id) {
            activeIndex = index;
          }
          if (content.id === over.id) {
            overIndex = index;
          }
        });
        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  }
  return (
    <>
      <PaddingBox symmetric={{ horizontal: 16, vertical: 14 }}>
        <SearchBar searchWord={searchWord} setSearchWord={setSearchWord} />
      </PaddingBox>
      <PaddingBox only={{ top: 10, right: 10, bottom: 18, left: 16 }}>
        <TitleBoxLayout>
          <StandardTag scaleKey="TITLE3">메인 추천 영역</StandardTag>
          {searchWord.length === 0 && (
            <StandardTag scaleKey="SUBTITLE1">
              ({recommendedContentList.length}/6)
            </StandardTag>
          )}
        </TitleBoxLayout>
      </PaddingBox>
      {recommendedContentList.length > 0 ? (
        <FlexColLayout
          gap={4}
          className="w_100"
          style={{
            touchAction: "none",
            overflow: "hidden",
            flexShrink: "0",
          }}
        >
          <DndContext
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={recommendedContentList.map((content) => content.id)}
              strategy={verticalListSortingStrategy}
            >
              {recommendedContentList.map((content) => {
                if (!checkContentIncludesSearchWord(content, searchWord)) {
                  return null;
                }
                if (searchWord.length > 0) {
                  return (
                    <ContentItem
                      key={content.id}
                      data={content}
                      canSort={false}
                    />
                  );
                }
                return (
                  <ContentItem key={content.id} data={content} canSort={true} />
                );
              })}
            </SortableContext>
          </DndContext>
        </FlexColLayout>
      ) : (
        <>
          {searchWord.length === 0 && (
            <CenterBox className="bg_bk" style={{ height: "124px" }}>
              <FlexColLayout gap={9} className="fx_ai_ct">
                <IconImage iconName="empty" />
                <div className="fs_xxs cl_wt">
                  해당 콘텐츠 영역이 비었습니다.
                </div>
              </FlexColLayout>
            </CenterBox>
          )}
        </>
      )}
      <PaddingBox only={{ top: 42, right: 10, bottom: 18, left: 16 }}>
        <TitleBoxLayout>
          <StandardTag scaleKey="TITLE3">전체 발행 콘텐츠</StandardTag>
          {searchWord.length === 0 && (
            <StandardTag scaleKey="SUBTITLE1">
              ({normalContentList.length}개)
            </StandardTag>
          )}
        </TitleBoxLayout>
      </PaddingBox>
      {normalContentList.length > 0 ? (
        <FlexColLayout gap={4} className="w_100">
          {normalContentList.map((content) => {
            if (!checkContentIncludesSearchWord(content, searchWord)) {
              return null;
            }
            return (
              <ContentItem
                key={content.id}
                data={content}
                canRecommend={recommendedContentList.length < 6}
              />
            );
          })}
        </FlexColLayout>
      ) : (
        <>
          {searchWord.length === 0 && (
            <CenterBox className="bg_bk">
              <FlexColLayout gap={9} className="fx_ai_ct">
                <IconImage iconName="empty" />
                <div className="fs_xxs cl_wt">
                  해당 콘텐츠 영역이 비었습니다.
                </div>
              </FlexColLayout>
            </CenterBox>
          )}
        </>
      )}
    </>
  );
}
