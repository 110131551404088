import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { FlexColLayout } from "../../../../components/layouts/styles";
import ImageInput from "../../../../components/inputs/ImageInput";
import { InputWithoutBorder } from "../../../../components/inputs/custom/styles";
import PaddingBox from "../../../../components/boxes/PaddingBox";
import { ContentApiRequest } from "../../../../types/content";
import { FileProps } from "../../../../types/files";

interface Props {
  isSelected: boolean;
  request: ContentApiRequest;
  updateBannerInfo: (name: keyof ContentApiRequest, value: string) => void;
  updateBannerImage: (image: File) => void;
  bannerImageUrl: string | null;
}

export default function BannerSection(props: Props) {
  const [files, setFiles] = useState<FileProps[]>([]);

  useEffect(() => {
    if (files.length > 0) {
      props.updateBannerImage(files[0].object);
    }
  }, [files]);

  return (
    <SectionLayout isSelected={props.isSelected}>
      <FlexColLayout>
        <ImageInput
          inputId="bannerImg"
          files={files}
          setFiles={setFiles}
          bannerImageUrl={files.length === 0 ? props.bannerImageUrl : null}
        />
        <PaddingBox all={12}>
          <FlexColLayout gap={8}>
            <InputWithoutBorder
              placeholder="타이틀(최대 50자)"
              value={props.request.title}
              onChange={(e) => {
                props.updateBannerInfo("title", e.target.value);
              }}
            />
            <Divider />
            <InputWithoutBorder
              placeholder="서브타이틀(최대 10자)"
              value={props.request.subtitle || ""}
              onChange={(e) => {
                props.updateBannerInfo("subtitle", e.target.value);
              }}
            />
            <Divider />
            <InputWithoutBorder
              placeholder="# 태그(최대 10개)"
              value={props.request.tag || ""}
              onChange={(e) => {
                props.updateBannerInfo("tag", e.target.value);
              }}
            />
            <Divider />
            <InputWithoutBorder
              placeholder="내용(최대 30자)"
              value={props.request.detail || ""}
              onChange={(e) => {
                props.updateBannerInfo("detail", e.target.value);
              }}
            />
          </FlexColLayout>
        </PaddingBox>
      </FlexColLayout>
    </SectionLayout>
  );
}

const SectionLayout = styled.div<{ isSelected: boolean }>`
  width: 100%;
  flex-grow: 1;
  display: ${(props) => (props.isSelected ? "flex" : "none")};
  flex-direction: column;
  justify-content: space-between;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: rgba(239, 239, 239, 0.3);
`;
