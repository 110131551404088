import Modal from "antd/lib/modal/Modal";
import React, { CSSProperties, useEffect, useState } from "react";
import { BasicModalProps } from "../../../types/modals";
import ModalLayout from "../../layouts/ModalLayout";
import { FlexColLayout } from "../../layouts/styles";
import PaddingBox from "../../boxes/PaddingBox";
import { Button } from "../../buttons/Button";
import styled from "@emotion/styled";
import CustomRate from "../../rates/CustomRate";
import { ReviewApiRequest, ReviewApiResponse } from "../../../types/review";
import { useCreateReview, useUpdateReview } from "../../../apis/review";
import AlertModal from "../AlertModal";
import { useQueryClient } from "@tanstack/react-query";

const modalBodyLayout: CSSProperties = {
  height: "444px",
  display: "flex",
  flexDirection: "column",
};

interface ModalProps extends BasicModalProps {
  contentId: number;
  review?: ReviewApiResponse;
}

export default function ReviewWriteModal(props: ModalProps) {
  const [isHidden, setIsHidden] = useState(false);
  const [alertModalVisible, setAlertModalVisible] = useState(false);
  const [request, setRequest] = useState<ReviewApiRequest>({
    id: null,
    member_id: null,
    content_id: props.contentId,
    rate: 0,
    detail: "",
  });

  useEffect(() => {
    if (props.review) {
      const newRequest: ReviewApiRequest = {
        id: props.review.id,
        member_id: props.review.member_id,
        content_id: props.review.content_id,
        rate: props.review.rate,
        detail: props.review.detail,
      };
      setRequest(newRequest);
    }
  }, [props.review]);

  const queryClient = useQueryClient();
  const createReviewMutation = useCreateReview();
  const updateReviewMutation = useUpdateReview();

  const saveReview = () => {
    if (props.review) {
      updateReview();
    } else {
      createReview();
    }
  };

  const createReview = () => {
    createReviewMutation.mutate(request, {
      onSuccess(data, variables, context) {
        queryClient.invalidateQueries(["content", props.contentId]);
        setIsHidden(true);
        setAlertModalVisible(true);
      },
    });
  };

  const updateReview = () => {
    updateReviewMutation.mutate(request, {
      onSuccess(data, variables, context) {
        queryClient.invalidateQueries(["content", props.contentId]);
        setIsHidden(true);
        setAlertModalVisible(true);
      },
    });
  };

  const hideModal = () => {
    props.resetFn();
  };

  return (
    <Modal
      open={props.visible}
      centered
      width={330}
      closable={false}
      maskClosable={false}
      bodyStyle={modalBodyLayout}
      footer={false}
      onCancel={props.resetFn}
      afterClose={props.resetFn}
      transitionName=""
      maskTransitionName=""
      style={{ display: isHidden ? "none" : undefined }}
    >
      <ModalLayout
        existsBackBtn={false}
        existsCloseBtn={false}
        backFn={undefined}
        closeFn={hideModal}
      >
        <div className="dp_fx_col fx_jc_btw fx_grow">
          <FlexColLayout className="fx_jc_end fx_grow">
            <div className="ta_ct ell fx_auto fs_l fw_b">후기 작성하기</div>
            <div className="dp_fx_row fx_jc_ct" style={{ marginTop: "20px" }}>
              <CustomRate
                size={32}
                value={request.rate}
                onChange={(value) => {
                  setRequest((prev) => ({ ...prev, rate: value }));
                }}
              />
            </div>
            <ReviewTextarea
              placeholder={
                "광고성 작성글은 관리자 권한에 의해 접근이 제한될 수 있습니다. 작성한 후기는 마이페이지-내가 작성한 리뷰에서 확인하실 수 있습니다."
              }
              value={request.detail}
              onChange={(e) => {
                setRequest((prev) => ({ ...prev, detail: e.target.value }));
              }}
            />
          </FlexColLayout>
          <PaddingBox all={6}>
            <FlexColLayout gap={6}>
              <Button
                colorType={"SECONDARY2"}
                isWithIcon={false}
                onClick={() => {
                  // props.okFn();
                  // hideModal();
                  saveReview();
                }}
                height="44px"
                fontSize="XS"
                borderRadius="M"
              >
                등록하기
              </Button>
              <Button
                colorType={"GRAY05_FONT_BLACK_OUTLINE"}
                isWithIcon={false}
                onClick={() => {
                  hideModal();
                }}
                height="44px"
                fontSize="XS"
                borderRadius="M"
              >
                취소
              </Button>
            </FlexColLayout>
          </PaddingBox>
        </div>
      </ModalLayout>
      {alertModalVisible && (
        <AlertModal
          visible={alertModalVisible}
          resetFn={() => {
            setAlertModalVisible(false);
            hideModal();
          }}
          title={undefined}
          subTitle={
            "후기가  성공적으로 작성되었습니다. \n 작성하신 후기글은 ‘마이페이지’>’내가 작성한 리뷰’에서 확인할 수 있습니다."
          }
          okText={undefined}
          okFn={() => {
            hideModal();
            setAlertModalVisible(false);
          }}
          hasPrimaryButton
        />
      )}
    </Modal>
  );
}

const ReviewTextarea = styled.textarea`
  height: 140px;
  padding: 0 22px;
  margin-top: 30px;
  margin-bottom: 20px;
  border: none;

  &::placeholder {
    color: #ccc;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    letter-spacing: -0.28px;
  }
`;
