import { useMutation, useQuery } from "@tanstack/react-query";
import { axiosAdminNoTimeout, axiosCustomerNoTimeout } from "./axiosInstances";
import { ReviewApiRequest, ReviewApiResponse } from "../types/review";
import { AxiosError, AxiosResponse } from "axios";

const target = "reviews";

export const useGetReviewList = () => {
  return useQuery<AxiosResponse<ReviewApiResponse[]>, AxiosError>(
    ["review", "list", "my"],
    () => axiosCustomerNoTimeout.get(`${target}/my`)
  );
};

export const useCreateReview = () => {
  return useMutation((request: ReviewApiRequest) =>
    axiosCustomerNoTimeout.post(`${target}`, request)
  );
};

export const useUpdateReview = () => {
  return useMutation((request: ReviewApiRequest) =>
    axiosCustomerNoTimeout.put(`${target}`, request)
  );
};

export const useDeleteReview = (isAdmin: boolean) => {
  return useMutation((id: number) =>
    isAdmin
      ? axiosAdminNoTimeout.delete(`${target}/${id}`)
      : axiosCustomerNoTimeout.delete(`${target}/${id}`)
  );
};
