import { useQueryClient } from "@tanstack/react-query";
import React, {
  createContext,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { removeCookie, setCookie } from "../../utils/cookie";
import { axiosCustomerNoTimeout } from "../../apis/axiosInstances";
import { useToken } from "../../hooks/useToken";
import { LoggedInUserDataResponse } from "../../types/member";
import { useGetLoggedInData2 } from "../../apis/member";

export interface AuthObj {
  token: string | null;
  onLogin: (token: string) => void;
  onLogout: (redirectToLoginPage?: boolean) => void;
  loggedInMember?: LoggedInUserDataResponse | null;
  isAdminPage: boolean;
  changeModule: (isAdminPage: boolean) => void;
}

export const AuthContext = createContext<AuthObj | null>(null);

export default function AuthProvider(props: PropsWithChildren) {
  const navigate = useNavigate();
  const storedToken = useToken();
  const [token, setToken] = useState<string | null>(null);
  const [loggedInMember, setLoggedInMember] =
    useState<LoggedInUserDataResponse | null>();
  const [isAdminPage, setIsAdminPage] = useState(false);

  const queryClient = useQueryClient();
  const loggedInData = useGetLoggedInData2(false, token);

  const handleLogin = async (token: string) => {
    if (token) {
      setToken(token);
      localStorage.setItem("Authorization", token);
      setCookie("Authorization", token, { path: `/` });
    }
    navigate(`/`);
  };

  useEffect(() => {
    if (token != null) {
      setCookie("Authorization", token);
    }
    if (token === "LOGOUT") {
      localStorage.removeItem("Authorization");
      localStorage.removeItem("Authorization");
      localStorage.clear();
      removeCookie("Authorization");
    }
  }, [token]);

  useEffect(() => {
    if (storedToken) {
      setToken(storedToken);
    }
  }, [storedToken]);

  useEffect(() => {
    if (loggedInData.isError) {
      setLoggedInMember(null);
    } else {
      if (loggedInData.data) {
        setLoggedInMember(loggedInData.data.data);
      }
    }
  }, [loggedInData]);

  const handleLogout = (redirectToLoginPage?: boolean) => {
    axiosCustomerNoTimeout
      .get(`/members/logout`, {
        withCredentials: true,
      })
      .then((res) => {
        setToken("LOGOUT");
        // queryClient.removeQueries(["loggedInUser"]);
        queryClient.clear();
        if (redirectToLoginPage) {
          // navigate("/login", { replace: true });
          navigate("/");
        }
      });
  };

  const changeModule = (isAdminPage: boolean) => {
    setIsAdminPage(isAdminPage);
  };

  const value: AuthObj = {
    token,
    onLogin: handleLogin,
    onLogout: handleLogout,
    loggedInMember: loggedInMember,
    isAdminPage: isAdminPage,
    changeModule: changeModule,
  };
  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
}
