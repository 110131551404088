import axios from "axios";
import { getCookie } from "../utils/cookie";

export const adminUrl = "/admin/api";
export const customerUrl = "/customer/api";

export const baseCustomerUrl = `${process.env.REACT_APP_CUSTOMER_HOST}${customerUrl}`;
export const baseAdminUrl = `${process.env.REACT_APP_ADMIN_HOST}${adminUrl}`;

export const doAxiosGetByModule = (
  isAdmin: boolean,
  target: string,
  postUrl: string,
  params?: any
) => {
  return isAdmin
    ? axios.get(`${baseAdminUrl}/${target}/${postUrl}`, {
        withCredentials: true,
        headers: {
          Authorization: getCookie("Authorization"),
        },
        params: params,
      })
    : axios.get(`${baseCustomerUrl}/${target}/${postUrl}`, {
        withCredentials: true,
        headers: {
          Authorization: getCookie("Authorization"),
        },
        params: params,
      });
};
export const getFunctionByModule = (
  isAdmin: boolean,
  target: string,
  postUrl: string,
  params?: any
) => {
  return doAxiosGetByModule(isAdmin, target, postUrl, params);
};
