import React, { useEffect, useState } from "react";
import { FlexColLayout } from "../../../../components/layouts/styles";
import styled from "@emotion/styled";
import {
  CustomTextarea,
  InputGray,
} from "../../../../components/inputs/custom/styles";
import IconImage from "../../../../components/icons/IconImage";
import ImageInput from "../../../../components/inputs/ImageInput";
import PaddingBox from "../../../../components/boxes/PaddingBox";
import { Button } from "../../../../components/buttons/Button";
import { LocationApiRequest } from "../../../../types/location";
import { TotalAddressResponse } from "../../../../types/daum";
import DaumPostcodeEmbed from "react-daum-postcode";
import { FileProps, UploadFileResponse } from "../../../../types/files";

interface Props {
  request: LocationApiRequest;
  deleteLocation: (location: LocationApiRequest) => void;
  updateLocationInfo: (
    location: LocationApiRequest,
    name: keyof LocationApiRequest,
    value: string
  ) => void;
  updateLocationImageList: (
    location: LocationApiRequest,
    imageList: File[]
  ) => void;
  updateLocationDeletedFileList: (
    location: LocationApiRequest,
    image: UploadFileResponse
  ) => void;
}

export default function LocationWriteItem(props: Props) {
  const [openPostcode, setOpenPostcode] = useState(false);
  const [postcodeResult, setPostcodeResult] = useState<TotalAddressResponse>();
  const [coordinate, setCoordinate] = useState<{ x: string; y: string }>();
  const [files, setFiles] = useState<FileProps[]>([]);

  useEffect(() => {
    if (postcodeResult) {
      setOpenPostcode(false);
      props.updateLocationInfo(
        props.request,
        "address",
        postcodeResult.address
      );
    }
  }, [postcodeResult]);

  useEffect(() => {
    if (files.length > 0) {
      props.updateLocationImageList(
        props.request,
        files.map((file) => file.object)
      );
    }
  }, [files]);

  useEffect(() => {
    if (coordinate) {
      props.updateLocationInfo(props.request, "xaxis", coordinate.x);
      props.updateLocationInfo(props.request, "yaxis", coordinate.y);
    }
  }, [coordinate]);

  return (
    <FlexColLayout className="w_100">
      <TitleBox>
        <InputGray
          placeholder="타이틀"
          value={props.request.title}
          onChange={(e) => {
            props.updateLocationInfo(props.request, "title", e.target.value);
          }}
        />
        <div
          className="ps_rl"
          onClick={() => {
            setOpenPostcode(true);
          }}
        >
          {(postcodeResult !== undefined || !openPostcode) && (
            <>
              <InputGray
                placeholder="주소 검색"
                value={
                  postcodeResult
                    ? postcodeResult.address
                    : props.request.address || ""
                }
                style={{ cursor: "default" }}
              />
              <IconImage
                iconName="search"
                style={{
                  position: "absolute",
                  top: "8px",
                  right: "12px",
                  color: "var(--color_gray6)",
                }}
              />
            </>
          )}
          {openPostcode && (
            <DaumPostcodeEmbed
              onComplete={(addrData: TotalAddressResponse) => {
                if (addrData.address) {
                  setPostcodeResult(addrData);
                }
                var geocoder = new window.kakao.maps.services.Geocoder();
                geocoder.addressSearch(
                  addrData.address, // 검색된 주소
                  function (result: any, status: any) {
                    // 5) 성공시 좌표 값을 가져온다.
                    if (status === window.kakao.maps.services.Status.OK) {
                      var currentPos = new window.kakao.maps.LatLng(
                        result[0].y,
                        result[0].x
                      );
                      setCoordinate({
                        x: currentPos.getLng().toString(),
                        y: currentPos.getLat().toString(),
                      });
                    }
                  }
                );
              }}
              autoClose={false}
            />
          )}
        </div>
      </TitleBox>
      <ImageInput
        inputId={"locationImg" + props.request.temp_id}
        isDetail={true}
        files={files}
        setFiles={setFiles}
        uploadImages={props.request.upload_file_list}
        updateLocationDeletedFileList={(image) => {
          props.updateLocationDeletedFileList(props.request, image);
        }}
      />
      <PaddingBox symmetric={{ horizontal: 4, vertical: 9 }}>
        <FlexColLayout gap={9}>
          <CustomTextarea
            placeholder="내용(최대 500자)"
            value={props.request.detail || ""}
            onChange={(e) => {
              props.updateLocationInfo(props.request, "detail", e.target.value);
            }}
          />
          <Button
            colorType={"PRIMARY"}
            isWithIcon={false}
            onClick={() => {
              props.deleteLocation(props.request);
            }}
            height="39px"
            fontSize="XS"
            borderRadius="M"
          >
            삭제하기
          </Button>
        </FlexColLayout>
      </PaddingBox>
    </FlexColLayout>
  );
}

const TitleBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 5px 8px;
  gap: 5px;
  background: rgba(138, 138, 138, 0.4);
  backdrop-filter: blur(10.376810073852539px);
`;
