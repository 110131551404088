import { InputStatus } from "../../status";
import { COLOR_TYPE } from "./basicColor";

export type InputColorType = {
  [k in InputStatus]: { color: string };
};

export const INPUT_COLOR_TYPE: InputColorType = {
  NORMAL: {
    color: COLOR_TYPE.GRAYSCALE4,
  },
  ERROR: {
    color: COLOR_TYPE.ERROR,
  },
  OK: {
    color: COLOR_TYPE.GRAYSCALE6,
  }
};
