import { useMutation } from "@tanstack/react-query";
import { LikesApiRequest } from "../types/likes";
import { axiosCustomerNoTimeout } from "./axiosInstances";

const target = "likes";

export const useChangeLike = () => {
  return useMutation((request: LikesApiRequest) =>
    axiosCustomerNoTimeout.put(`${target}/update`, request)
  );
};
