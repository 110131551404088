import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import SizedBox from "../../components/boxes/SizedBox";
import PaddingBox from "../../components/boxes/PaddingBox";
import TitlePageLayout from "../../components/layouts/TitlePageLayout";
import CustomTab from "../../components/tabs/CustomTab";
import BannerSection from "./components/sections/BannerSection";
import DetailSection from "./components/sections/DetailSection";
import { ContentApiRequest, ContentDetailResponse } from "../../types/content";
import { LocationApiRequest } from "../../types/location";
import { getRandomString, isNumber } from "../../utils/utils";
import {
  useCreateContent,
  useDeleteContent,
  useGetContentDetail,
  useUpdateContent,
} from "../../apis/content";
import { Button } from "../../components/buttons/Button";
import ConfirmModal from "../../components/modals/ConfirmModal";
import { FileRequest, UploadFileResponse } from "../../types/files";
import { useMessage } from "../../hooks/useMessage";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import ProtectedRoute from "../../containers/contexts/ProtectedRoute";
import { checkAdminOrHigher } from "../../utils/auth";
import { useQueryClient } from "@tanstack/react-query";
import { FlexRowLayout } from "../../components/layouts/styles";
import StandardTag from "../../components/tags/StandardTag";
import {
  CONTENT_STATUS,
  ContentStatusKey,
} from "../../types/unions/enums/ContentStatus";
import IconImage from "../../components/icons/IconImage";
import ContentStatusDropdown from "./components/items/ContentStatusDropdown";
import AlertModal from "../../components/modals/AlertModal";
import { useTitle } from "../../hooks/useTitle";

type TabKey = "BANNER" | "DETAIL";

export default function WriteAdminPage() {
  useTitle("글작성");
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [alertModalVisible, setAlertModalVisible] = useState(false);
  const [confirmModalTitle, setConfirmModalTitle] = useState<string>();
  const [confirmModalSubTitle, setConfirmModalSubTitle] = useState<string>();
  const [confirmModalOkFn, setConfirmModalOkFn] = useState<() => () => void>(
    () => () => {}
  );
  const [selectedTab, setSelectedTab] = useState<TabKey>("BANNER");
  const [request, setRequest] = useState<ContentApiRequest>({
    id: null,
    member_id: null,
    title: "",
    subtitle: null,
    detail: null,
    tag: null,
    description: null,
    status: "PENDING",
    recommended: false,
    show_index: null,
    content_image: null,
    location_list: [
      {
        id: null,
        member_id: null,
        content_id: null,
        title: "",
        address: null,
        detail: null,
        xaxis: null,
        yaxis: null,
        file_list: [],
        index: 0,
        image_list: [],
        temp_id: getRandomString(),
      },
    ],
  });
  const [contentId, setContentId] = useState<number>();
  const [contentDetail, setContentDetail] = useState<ContentDetailResponse>();
  const [isSet, setIsSet] = useState(false);
  const [showContentStatusDropdown, setShowContentStatusDropdown] =
    useState(false);
  const [selectedContentStatus, setSelectedContentStatus] =
    useState<ContentStatusKey>();

  const auth = useAuth();
  const [messageFn, messageContextHolder] = useMessage();

  // useEffect(() => {
  //   console.log(request);
  // }, [request]);

  // const { id } = useParams() as { id: string };
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const navigate = useNavigate();
  const res = useGetContentDetail(false, contentId);
  const useCreateContentMutation = useCreateContent();
  const useUpdateContentMutation = useUpdateContent();
  const useDeleteContentMutation = useDeleteContent();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!id) {
    } else {
      if (isNumber(id)) {
        setContentId(Number(id));
      }
    }
  }, [id]);

  useEffect(() => {
    if (res.data?.data) {
      setContentDetail(res.data.data);
    }
  }, [res]);

  useEffect(() => {
    if (contentDetail && !isSet) {
      console.log(contentDetail);
      const newRequest: ContentApiRequest = {
        id: contentDetail.content_api_response.id,
        member_id: contentDetail.content_api_response.member_id,
        title: contentDetail.content_api_response.title,
        subtitle: contentDetail.content_api_response.subtitle,
        detail: contentDetail.content_api_response.detail,
        tag: contentDetail.content_api_response.tag,
        description: contentDetail.content_api_response.description,
        status: contentDetail.content_api_response.status,
        recommended: contentDetail.content_api_response.recommended,
        show_index: contentDetail.content_api_response.show_index,
        content_image: null,
        location_list: [],
      };
      for (const idx in contentDetail.location_list) {
        const locationResponse = contentDetail.location_list[idx];
        const newLocationRequest: LocationApiRequest = {
          id: locationResponse.id,
          member_id: locationResponse.member_id,
          content_id: locationResponse.content_id,
          title: locationResponse.title,
          address: locationResponse.address,
          detail: locationResponse.detail,
          xaxis: locationResponse.xaxis,
          yaxis: locationResponse.yaxis,
          file_list: [],
          index: Number(idx),
          image_list: [],
          temp_id: getRandomString(),
          upload_file_list: locationResponse.image_list,
          deleted_file_list: [],
        };
        newRequest.location_list.push(newLocationRequest);
      }
      setRequest(newRequest);
      setIsSet(true);
      setSelectedContentStatus(contentDetail.content_api_response.status);
    }
  }, [contentDetail, isSet]);

  const saveContent = () => {
    messageFn(
      "loading",
      "글 저장중입니다. 완료 팝업이 나타날 때까지 잠시만 기다려 주세요."
    );
    const formData = getFormData();
    if (contentDetail) {
      useUpdateContentMutation.mutate(formData, {
        onSuccess(data, variables, context) {
          // messageFn("success", "글 수정이 완료되었습니다.");
          setAlertModalVisible(true);
        },
      });
    } else {
      useCreateContentMutation.mutate(formData, {
        onSuccess(data, variables, context) {
          // messageFn("success", "글 저장이 완료되었습니다.");
          setAlertModalVisible(true);
        },
      });
    }
  };

  const getFormData = () => {
    const formData = new FormData();

    if (selectedContentStatus) {
      request.status = selectedContentStatus;
    }

    if (request.content_image) {
      formData.append("contentImage", request.content_image);
    }

    const locationImageList: File[] = [];
    for (const locationRequest of request.location_list) {
      const locationImageRequestList: FileRequest[] = [];
      for (const file of locationRequest.file_list) {
        locationImageRequestList.push({ name: file.name });
        locationImageList.push(file);
      }
      locationRequest.image_list = locationImageRequestList;
    }
    locationImageList.forEach((file) =>
      formData.append("locationImageList", file)
    );

    formData.append(
      "request",
      new Blob([JSON.stringify(request)], {
        type: "application/json",
      })
    );

    return formData;
  };

  const addLocation = () => {
    const newRequest = { ...request };
    newRequest.location_list.push({
      id: null,
      member_id: null,
      content_id: null,
      title: "",
      address: null,
      detail: null,
      xaxis: null,
      yaxis: null,
      image_list: [],
      index: newRequest.location_list.length,
      file_list: [],
      temp_id: getRandomString(),
    });
    setRequest(newRequest);
  };

  const deleteLocation = (location: LocationApiRequest) => {
    const newRequest = { ...request };
    let deletedLocationIdx = newRequest.location_list.findIndex(
      (item, index) => item.id === location.id && index === location.index
    );
    if (deletedLocationIdx !== -1) {
      delete newRequest.location_list[deletedLocationIdx];
      let newLocationList = newRequest.location_list.filter(
        (location) => location !== undefined
      );
      newLocationList.map((location, index) => (location.index = index));
      newRequest.location_list = [...newLocationList];
      setRequest(newRequest);
    }
  };

  const updateBannerInfo = (name: keyof ContentApiRequest, value: string) => {
    setRequest((prev) => ({ ...prev, [name]: value }));
  };

  const updateBannerImage = (image: File) => {
    setRequest((prev) => ({ ...prev, content_image: image }));
  };

  const updateLocationInfo = (
    location: LocationApiRequest,
    name: keyof LocationApiRequest,
    value: string
  ) => {
    setRequest((prev) =>
      getNewRequestAfterUpdatingLocationInfo(prev, location, name, value)
    );
  };

  const updateLocationImageList = (
    location: LocationApiRequest,
    imageList: File[]
  ) => {
    setRequest((prev) =>
      getNewRequestAfterUpdatingLocationImageList(prev, location, imageList)
    );
  };

  const updateLocationDeletedFileList = (
    location: LocationApiRequest,
    image: UploadFileResponse
  ) => {
    setRequest((prev) =>
      getNewRequestAfterUpdatingLocationDeletedFileList(prev, location, image)
    );
  };

  const getNewRequestAfterUpdatingLocationInfo = (
    request: ContentApiRequest,
    location: LocationApiRequest,
    name: keyof LocationApiRequest,
    value: string
  ) => {
    const newRequest = { ...request };
    let newLocation = newRequest.location_list.find(
      (item, index) => item.id === location.id && index === location.index
    );
    if (newLocation) {
      if (
        name === "title" ||
        name === "address" ||
        name === "detail" ||
        name === "xaxis" ||
        name === "yaxis"
      ) {
        newLocation[name] = value;
      }
    }
    return newRequest;
  };

  const getNewRequestAfterUpdatingLocationImageList = (
    request: ContentApiRequest,
    location: LocationApiRequest,
    imageList: File[]
  ) => {
    const newRequest = { ...request };
    let newLocation = newRequest.location_list.find(
      (item, index) => item.id === location.id && index === location.index
    );
    if (newLocation) {
      newLocation["file_list"] = imageList;
    }
    return newRequest;
  };

  const getNewRequestAfterUpdatingLocationDeletedFileList = (
    request: ContentApiRequest,
    location: LocationApiRequest,
    image: UploadFileResponse
  ) => {
    const newRequest = { ...request };
    let newLocation = newRequest.location_list.find(
      (item, index) => item.id === location.id && index === location.index
    );
    if (newLocation && newLocation.upload_file_list) {
      const uploadFileIdx = newLocation.upload_file_list.findIndex(
        (file) => file.id === image.id
      );
      if (uploadFileIdx !== -1) {
        delete newLocation.upload_file_list[uploadFileIdx];
        let newFileList = newLocation.upload_file_list.filter(
          (file) => file !== undefined
        );
        newLocation.upload_file_list = [...newFileList];
        if (newLocation.deleted_file_list) {
          newLocation.deleted_file_list.push(image);
        } else {
          newLocation.deleted_file_list = [image];
        }
      }
    }
    return newRequest;
  };

  return (
    <ProtectedRoute isAdminPage>
      <TitlePageLayout
        title={contentDetail ? "수정하기" : "글작성"}
        isDark={true}
      >
        {checkAdminOrHigher(auth) && contentDetail && (
          <DeleteButton
            onClick={() => {
              setConfirmModalTitle("삭제된 콘텐츠는 복구가 불가능합니다.");
              setConfirmModalSubTitle("그래도 삭제하시겠습니까?");
              setConfirmModalOkFn(() => () => {
                useDeleteContentMutation.mutate(
                  contentDetail.content_api_response.id,
                  {
                    onSuccess(data, variables, context) {
                      queryClient.invalidateQueries([
                        "content",
                        "list",
                        { filter: "PUBLISHED" },
                      ]);
                      setTimeout(() => {
                        navigate("/admin/management");
                      }, 1000);
                    },
                  }
                );
                setConfirmModalVisible(false);
              });
              setConfirmModalVisible(true);
            }}
          >
            삭제하기
          </DeleteButton>
        )}
        <PaddingBox only={{ top: 0, left: 32, bottom: 0, right: 33 }}>
          <div className="w_100 dp_fx_row fx_jc_btw">
            <CustomTab
              label={"메인배너"}
              isSelected={selectedTab === "BANNER"}
              size={"S"}
              isDark
              onClick={() => {
                setSelectedTab("BANNER");
              }}
            />
            <CustomTab
              label={"상세페이지"}
              isSelected={selectedTab === "DETAIL"}
              size={"S"}
              isDark
              onClick={() => {
                setSelectedTab("DETAIL");
              }}
            />
          </div>
        </PaddingBox>
        <BannerSection
          isSelected={selectedTab === "BANNER"}
          request={request}
          updateBannerInfo={updateBannerInfo}
          updateBannerImage={updateBannerImage}
          bannerImageUrl={contentDetail?.content_api_response.image_url || null}
        />
        <DetailSection
          isSelected={selectedTab === "DETAIL"}
          request={request}
          locationList={request.location_list}
          addLocation={addLocation}
          deleteLocation={deleteLocation}
          updateBannerInfo={updateBannerInfo}
          updateLocationInfo={updateLocationInfo}
          updateLocationImageList={updateLocationImageList}
          updateLocationDeletedFileList={updateLocationDeletedFileList}
        />
        {contentDetail ? (
          <EditButtonLayout>
            <FlexRowLayout gap={10} className="fx_ai_ct">
              <StandardTag scaleKey="TAG2" color="GRAYSCALE2">
                저장 위치
              </StandardTag>
              {selectedContentStatus && (
                <div className="ps_rl">
                  <ContentStatusLayout
                    onClick={() => {
                      setShowContentStatusDropdown(true);
                    }}
                  >
                    {CONTENT_STATUS[selectedContentStatus]}
                    <IconImage iconName="nav_arrow_down" size={14} />
                  </ContentStatusLayout>
                  {showContentStatusDropdown && (
                    <ContentStatusDropdown
                      setShowOptionList={setShowContentStatusDropdown}
                      selectedStatus={selectedContentStatus}
                      setSelectedStatus={setSelectedContentStatus}
                    />
                  )}
                </div>
              )}
            </FlexRowLayout>
            <Button
              colorType={"PRIMARY"}
              backgroundColor="BORDER1"
              fontWeight="BOLD"
              isWithIcon={false}
              onClick={() => {
                setConfirmModalTitle(undefined);
                setConfirmModalSubTitle("글을 수정하시겠습니까?");
                setConfirmModalOkFn(() => () => {
                  saveContent();
                  setConfirmModalVisible(false);
                });
                setConfirmModalVisible(true);
              }}
              height="44px"
              fontSize="XS"
              customStyle={{ borderRadius: "8px" }}
            >
              수정완료
            </Button>
          </EditButtonLayout>
        ) : (
          <PaddingBox symmetric={{ horizontal: 16 }}>
            <Button
              colorType={"CUSTOM"}
              backgroundColor="BORDER1"
              borderColor="BORDER1"
              fontColor="WHITE"
              fontWeight="BOLD"
              isWithIcon={false}
              onClick={() => {
                setConfirmModalTitle(undefined);
                setConfirmModalSubTitle("글을 저장하시겠습니까?");
                setConfirmModalOkFn(() => () => {
                  saveContent();
                  setConfirmModalVisible(false);
                });
                setConfirmModalVisible(true);
              }}
              height="44px"
              fontSize="XS"
              customStyle={{ borderRadius: "8px" }}
            >
              저장하기
            </Button>
          </PaddingBox>
        )}
        <SizedBox height={30} />
        {confirmModalVisible && (
          <ConfirmModal
            visible={confirmModalVisible}
            resetFn={() => {
              setConfirmModalVisible(false);
            }}
            title={confirmModalTitle}
            subTitle={confirmModalSubTitle}
            okText={undefined}
            cancelText={undefined}
            okFn={() => {
              confirmModalOkFn();
            }}
            cancelFn={undefined}
          />
        )}
        {alertModalVisible && (
          <AlertModal
            visible={alertModalVisible}
            resetFn={() => {
              setAlertModalVisible(false);
            }}
            title={undefined}
            subTitle={`해당 콘텐츠는 '${
              selectedContentStatus
                ? CONTENT_STATUS[selectedContentStatus]
                : CONTENT_STATUS["PENDING"]
            } 콘텐츠'에 저장이 완료되었습니다.`}
            okText={undefined}
            okFn={() => {
              setAlertModalVisible(false);
              navigate("/admin/management");
            }}
          />
        )}
        {messageContextHolder}
      </TitlePageLayout>
    </ProtectedRoute>
  );
}

const DeleteButton = styled.div`
  position: absolute;
  margin-right: 16px;
  cursor: pointer;
  top: 10px;
  right: 0;
  color: var(--color_primary1);
  font-size: 14px;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.28px;
`;

const EditButtonLayout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding: 10px 16px 30px 16px;
  background-color: white;
`;

const ContentStatusLayout = styled.div`
  width: 104px;
  height: 35px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border: 1px solid #f0f0f0;
  background: #fff;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  letter-spacing: -0.28px;
`;
