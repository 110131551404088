const PRODUCT_NAME = "빌딩케어 스페이스";

export const ACTION_BACK = `돌아가기`;
export const ALERT_CHECK_EMAIL = `이메일을 다시 확인해주세요`;
export const ALERT_CHECK_PASSWORD = `비밀번호를 다시 확인해주세요.`;
export const ALERT_ERROR_OCCURRED = `오류가 발생하였습니다. 다시 시도 해 주시기 바라겠습니다.`;
export const LABEL_EMAIL_ADDRESS = `이메일 주소`;
export const LABEL_PASSWORD = `비밀번호`;
export const MESSAGE_WRITE_EMAIL = `이메일을 입력해주세요.`;
export const MESSAGE_WRITE_PASSWORD = `비밀번호를 입력해주세요.`;
export const MESSAGE_RE_ERROR_EMAIL = `올바른 이메일 주소가 아닙니다.`;

export const PAGE_LOGIN = `로그인`;
export const TEXT_EMAIL_LOGIN = `이메일로 로그인하기`;
export const TEXT_KAKAO_LOGIN = `카카오톡으로 로그인하기`;
export const TEXT_REGISTER = `회원가입하기`;
export const TEXT_FORGOT_PASSWORD = `비밀번호를 잊으셨나요?`;
export const MOBILE_TEXT_FORGOT_PASSWORD = `비밀번호 찾기`;
//terms
export const CHECK = "확인";
export const PAGE_TERM = "약관";
export const PAGE_COMPLETE_REGISTER = `회원가입 완료`;

//register
export const ALERT_ABNORMAL_ACCESS = `비정상적인 접근입니다.`;
export const ALERT_ABNORMAL_ACCESS_SUBTITLE = `로그인 페이지에서 회원가입 버튼을 통해 접속 가능합니다.`;
export const ALERT_AUTHENTICATED = `인증되었습니다.`;
export const ALERT_CHECK_AUTH_STATUS = `인증 번호 및 인증 완료를 확인해주세요`;
export const ALERT_CHECK_EMAIL_STATUS = `이메일 형식 및 메일 인증 여부를 확인해주세요`;
export const ALERT_EMAIL_SENT = `인증 메일이 발송되었습니다.`;
export const ALERT_NOT_ALLOWED_EMPTY_PHONE = `연락처를 입력해주세요`;
export const ALERT_PASSWORD_NOT_EQUALS = `입력된 비밀번호가 일치하지 않습니다`;
export const ALERT_REGISTERED = `회원가입이 완료되었습니다.`;
export const ALERT_SERVE_BUILDING_CARE_BY_PHONE = `정확한 연락처 입력을 통해 빌딩케어의 편리함을 누리세요`;
export const ALERT_WRITE_NAME = `이름을 입력해주세요`;
export const ALERT_WRONG_PHONE_FORMAT = `잘못된 연락처 형식입니다`;

export const LABEL_NAME = `이름`;
export const LABEL_EMAIL = `이메일 주소`;
export const LABEL_AUTH_CODE = `인증번호`;
export const LABEL_CURRENT_PASSWORD = `현재 비밀번호`;
export const LABEL_NEW_PASSWORD = `새 비밀번호`;
export const LABEL_CHECK_NEW_PASSWORD = `새 비밀번호 확인`;
export const LABEL_CHECK_PASSWORD = `비밀번호 확인`;
export const MESSAGE_SEND_EMAIL_FIRST = `인증 메일을 먼저 발송해 주세요.`;
export const MESSAGE_AUTHENTICATED = `인증되었습니다.`;

export const MESSAGE_AUTHENTICATION_MAIL_SENT = `인증 메일이 발송되었습니다.`;
export const MESSAGE_BLANK_NOT_ALLOWED = `공백을 포함할 수 없습니다.`;
export const MESSAGE_PASSWORD_NOT_EQUALS = `비밀번호가 일치하지 않아요.`;
export const MESSAGE_PASSWORD_EQUALS = `입력된 비밀번호가 일치합니다.`;
export const MESSAGE_PRESS_SEND_AUTHENTICATION = `인증번호 발송 요청 버튼을 눌러주세요`;
export const MESSAGE_PRESS_DO_AUTHENTICATION = `인증 버튼을 눌러주세요`;

export const MESSAGE_ERROR_COMPANY_CODE = `10자리 기업코드를 입력해주세요`;
export const MESSAGE_ERROR_NOT_FOUND_COMPANY_CODE = `올바르지 않은 기업코드입니다`;
export const MESSAGE_RE_ERROR_AUTH_CODE = `6자리 인증번호를 입력해주세요.`;
export const MESSAGE_RE_ERROR_PASSWORD = `영문,숫자,특수문자 조합 8~15자로 설정해주세요.`;
export const MESSAGE_WRITE_PASSWORD_AGAIN = `비밀번호 확인을 위해 재입력해주세요.`;
export const MESSAGE_WRITE_PASSWORD_FIRST = `먼저 규격에 맞는 비밀번호를 입력해주세요.`;
export const TEXT_BUTTON_REGISTER = `가입하기`;
export const TEXT_BUTTON_RESET_PASSWORD = `비밀번호 변경`;
export const TEXT_BUTTON_SEND_AUTHENTICATION = `인증요청`;
export const TEXT_BUTTON_DO_AUTHENTICATION = `인증확인`;
export const TEXT_BUTTON_AUTHENTICATED = `인증완료`;
export const TEXT_BASIC_INFO = `기본정보`;
export const TEXT_REQUEST_DO_AUTHENTICATION = `인증 확인`;
export const TEXT_REQUEST_SEND_AGAIN = `다시요청`;
export const TEXT_REQUEST_SEND_AUTHENTICATION = `인증번호 발송 요청`;
export const TEXT_START_LAND = `${PRODUCT_NAME} 시작하기`;
export const TEXT_TITLE_REGISTER = `${PRODUCT_NAME} 회원가입`;
export const PHONE_NUMBER = `연락처 (Option)`;

//Register Placeholder
export const PLACEHOLDER_REGISTER_NAME = `이름을 입력해주세요`;
export const PLACEHOLDER_REGISTER_EMAIL = `abc123@buildingcare.co.kr`;
export const PLACEHOLDER_REGISTER_AUTHENTICATION = `인증번호를 입력해주세요`;
export const PLACEHOLDER_CURRENT_PASSWORD = `현재 비밀번호를 입력해 주세요`;
export const PLACEHOLDER_REGISTER_PASSWORD = `영문,숫자,특수문자 조합 8~15자`;
export const PLACEHOLDER_REGISTER_PASSWORDCHECK = `비밀번호를 한번 더 입력해주세요`;
export const PLACEHOLDER_REGISTER_COMPANY_CODE = `10자리 기업코드`;
export const PLACEHOLDER_REGISTER_COMPANY_POSITION = `사원`;
export const PLACEHOLDER_REGISTER_PHONE = `연락처를 입력해주세요`;

export const PAGE_REGISTER = `회원가입`;
export const PAGE_PASSWORD = `비밀번호 변경`;

export const TEXT_TITLE_CHANGE_PASSWORD = `비밀번호 변경`;
export const TEXT_SUBTITLE_FORGOT_PASSWORD = `비밀번호를 잊으셨나요?`;
export const ALERT_CHANGED_PASSWORD = `비밀번호 변경이 완료되었습니다`;
export const ACTION_CONFIRM = `확인`;
export const TEXT_CHANGE_PASSWORD_SUCCESS = `비밀번호 변경완료`;
