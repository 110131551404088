import styled from "@emotion/styled";
import TitlePageLayout from "../../components/layouts/TitlePageLayout";
import PaddingBox from "../../components/boxes/PaddingBox";
import CustomTab from "../../components/tabs/CustomTab";
import { useState } from "react";
import ReviewSection from "./components/sections/ReviewSection";
import IconImage from "../../components/icons/IconImage";
import { FlexColLayout, FlexRowLayout } from "../../components/layouts/styles";
import StandardTag from "../../components/tags/StandardTag";
import { CustomIcon } from "../../components/icons/CustomIcon";
import { useAuth } from "../../hooks/useAuth";
import LikeSection from "./components/sections/LikeSection";
import ProtectedRoute from "../../containers/contexts/ProtectedRoute";
import { useNavigate } from "react-router-dom";
import { useTitle } from "../../hooks/useTitle";
import { isValidString } from "../../utils/checkType";

interface PageProps {}
type TabKey = "REVIEW" | "LIKE";

export default function MyPage(props: PageProps) {
  useTitle("마이페이지")
  const [selectedTab, setSelectedTab] = useState<TabKey>("REVIEW");
  const auth = useAuth();
  const navigate = useNavigate();
  return (
    <ProtectedRoute isAdminPage={false}>
      <TitlePageLayout title={`MY`} isDark={true}>
        {auth?.loggedInMember && (
          <>
            <MyInfoLayout>
              <ProfileImg>
                {isValidString(auth.loggedInMember.profile_url) ? (
                  <ProfileImgInside
                    src={auth.loggedInMember.profile_url}
                  />
                ) : (
                  <IconImage iconName="mypage" size={44} />
                )}
              </ProfileImg>
              <FlexColLayout gap={4} className="fx_jc_ct">
                <StandardTag scaleKey="TITLE3" color="WHITE">
                  {auth.loggedInMember.name}님
                </StandardTag>
                <GoToMyInfoPage
                  onClick={() => {
                    navigate("/my_info_page");
                  }}
                >
                  내정보관리
                  <CustomIcon
                    iconName="nav-arrow-right"
                    size={16}
                    className="cl_gy5"
                  />
                </GoToMyInfoPage>
              </FlexColLayout>
            </MyInfoLayout>
            <MyPageLayout>
              <PaddingBox only={{ top: 0, left: 32, bottom: 0, right: 33 }}>
                <div className="w_100 dp_fx_row fx_jc_btw">
                  <CustomTab
                    label={`내가 작성한 리뷰`}
                    isSelected={selectedTab === "REVIEW"}
                    size={"M"}
                    onClick={() => {
                      setSelectedTab("REVIEW");
                    }}
                  />
                  <CustomTab
                    label={`좋아요 누른 콘텐츠`}
                    isSelected={selectedTab === "LIKE"}
                    size={"M"}
                    onClick={() => {
                      setSelectedTab("LIKE");
                    }}
                  />
                </div>
              </PaddingBox>
              {selectedTab === "REVIEW" && <ReviewSection />}
              {selectedTab === "LIKE" && <LikeSection />}
            </MyPageLayout>
          </>
        )}
      </TitlePageLayout>
    </ProtectedRoute>
  );
}

const MyInfoLayout = styled.div`
  width: 100%;
  height: 114px;
  display: flex;
  gap: 16px;
  padding: 24px 16px;
  background-color: #202022;
`;

const ProfileImg = styled.div`
  width: 66px;
  height: 66px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--color_gray8);
`;

const ProfileImgInside = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
`

const GoToMyInfoPage = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  color: rgba(255, 255, 255, 0.38);
  font-size: 14px;
  line-height: 18px; /* 128.571% */
  letter-spacing: -0.28px;
`;

const MyPageLayout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: var(--color_gray05);
`;
