import styled from "@emotion/styled";
import { PropsWithChildren, useEffect, useRef } from "react";

interface Props extends PropsWithChildren {
  setOpen: (b: boolean) => void;
  width?: number;
  style?: React.CSSProperties;
}

export default function SelectLayout(props: Props) {
  const optionRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    function handleOutside(e: MouseEvent) {
      if (optionRef.current && !optionRef.current.contains(e.target as Node)) {
        props.setOpen(false);
      }
    }
    document.addEventListener("mousedown", handleOutside);
    return () => {
      document.removeEventListener("mousedown", handleOutside);
    };
  }, [optionRef]);

  return (
    <SelectListLayout width={props.width} ref={optionRef} style={props.style}>
      {props.children}
    </SelectListLayout>
  );
}

const SelectListLayout = styled.div<{
  width?: number;
}>`
  width: ${(props) => (props.width ? props.width : 135)}px;
  position: absolute;
  top: calc(100% - var(--gap_2));
  right: 0;
  border-radius: var(--border_radius_m);
  background-color: var(--color_white);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  z-index: 100;
  overflow: hidden;
`;

const SelectItemLayout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px 8px 9px 12px;
  gap: 4px;
  height: 36px;
  font-size: var(--fs_xs);
  font-weight: var(--fw_m);
  color: var(--color_black);
  cursor: pointer;

  &:hover {
    background-color: var(--color_gray1);
  }
  /* &.selected {
    background-color: var(--color_primary1);
  } */
`;

export const BasicSelectItem = styled(SelectItemLayout)``;

// export const HighlightSelectItem = styled(SelectItemLayout)`
//   &:hover {
//     color: var(--color_primary3);
//   }
// `;
