export const serviceTermsContent = `
서비스 이용 약관
 
제1장 총칙

제1조 (목적)
이 약관(이하 "약관"이라 합니다)은 ㈜디원더(이하 "회사"라 합니다)와 이용 고객(이하 "회원"이라 합니다)간에 회사가 제공하는 서비스(이하 "서비스"라 합니다)를 이용함에 있어 회원과 회사간의 권리, 의무 및 책임사항, 이용조건 및 절차 등 기본적인 사항을 규정함을 목적으로 합니다.
 
제2조 (용어의 정의)
본 약관에서 사용되는 용어의 정의는 다음과 같습니다.
1. "서비스" : 회사가 제공하는 모든 자산 및 임대 관리 관련 서비스 등을 위한 온라인 서비스를 의미합니다. 서비스는 구현되는 장치나 단말기(PC, TV, 휴대형 단말기 등의 각종 유무선 장치를 포함하며 이에 한정되지 않음)와 상관없이 [㈜디원더] 및 [㈜디원더] 관련 웹(Web)∙앱(App) 등의 제반 서비스를 의미하며, 회사가 공개한 API를 이용하여 제3자가 개발 또는 구축한 프로그램이나 서비스를 통하여 이용자에게 제공되는 경우를 포함합니다. 서비스는 시장의 변화와 기술이 발전함에 따라 지속적으로 함께 변화∙성장해 나갑니다.
2. "회원" : 서비스에 접속하여 본 약관에 따라 회사와 이용계약을 체결하고 회사가 제공하는 서비스를 이용하는 고객을 말합니다. 실제 부동산을 소유하거나 운영 위탁을 받아 임대차 계약을 맺어 임대사업을 영위하고 있거나 이와 유관한 업무를 진행하고 있는 자로서, 서비스의 정보를 지속적으로 제공 받으며, 회사가 제공하는 서비스를 지속적으로 이용할 수 있는 자를 말합니다. 회사는 서비스의 원활한 제공을 위해 회원의 권한을 서비스 내부의 규정에 따라 나눌 수 있습니다.
3. “아이디(ID)” : 회원의 식별과 서비스 이용을 위하여 회원이 설정하고 회사가 승인한 문자나 숫자의 조합을 말합니다.
4. “비밀번호” :  회원이 부여 받은 아이디와 일치되는 회원임을 확인하고 비밀보호를 위해 회원자신이 정한 문자 또는 숫자의 조합을 의미합니다.
5. "게시물" : 회원 및 이용 고객이 서비스에 게시 또는 등록하는 부호(URL 포함), 문자, 음성, 음향, 영상(동영상 포함), 이미지(사진 포함), 파일 등을 말합니다.
6. 위 항에서 정의되지 않은 이 약관 상의 용어의 의미는 일반적인 거래관행에 의합니다.
 
제3조 (약관의 효력 및 변경)
1. 본 약관은 서비스를 이용하고자 하는 모든 회원에 대하여 그 효력을 발생합니다. 또한, 회사의 웹, 앱을 통해 콘텐츠 및 서비스를 이용하는 경우 이 약관에 동의한 것으로 봅니다. 약관에 동의한 시점부터 약관의 적용을 받고 약관의 변경이 있을 경우에는 변경의 효력이 발생한 시점부터 변경된 약관의 적용을 받습니다.
2. 본 약관은 회원이 서비스 가입 시 열람 할 수 있으며, 회사는 회원이 원할 때 언제든지 약관을 열람 할 수 있도록 회사 홈페이지 또는 서비스 내에 게시합니다.
3. 회사는 『약관의 규제에 관한 법률』, 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』(이하 "정보통신망법"이라 합니다), 등 관련법에 위배되지 않는 범위 내에서 본 약관을 개정할 수 있습니다.
4. 회사가 약관을 변경할 경우에는 적용일자, 변경사유를 명시하여 적용일로부터 7일 이전부터 공지합니다. 다만, 회원에게 불리한 약관 변경인 경우에는 그 적용일로부터 30일전부터 위와 같은 방법으로 공지합니다. 약관 변경시 웹 사이트 초기화면에 공지하거나 회원에게 통지합니다.
5. 회사가 제4항에 따라 개정약관을 공지 또는 통지하면서, 회원에게 약관 변경 적용일 까지 거부의사를 표시하지 않으면 약관의 변경에 동의한 것으로 간주한다는 내용을 공지 또는 통지하였음에도 회원이 명시적으로 약관 변경에 대한 거부의사를 표시하지 아니하는 경우 회원이 개정약관에 동의한 것으로 간주합니다.
6. 회원은 변경된 약관에 동의하지 아니하는 경우 서비스의 이용을 중단하고 이용계약을 해지할 수 있습니다.
7. 회원은 약관 변경에 대하여 주의의무를 다하여야 하며, 변경된 약관의 부지로 인한 회원의 피해는 회사가 책임지지 않습니다.
 
제4조 (약관 외 준칙 및 관련법령과의 관계)
1. 본 약관 또는 개별약관에서 정하지 않은 사항은 『전기통신사업법』, 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』, 『개인정보보호법』 등 관련 법의 규정 및 회사가 정한 서비스의 세부이용지침 등의 규정에 따릅니다. 또한 본 약관에서 정한 회사의 책임 제한 규정은 관련 법령이 허용하는 최대한도의 범위 내에서 적용됩니다.
2. 회사는 필요한 경우 서비스 내의 개별항목에 대하여 개별약관 또는 운영원칙을 정할 수 있으며, 본 약관과 개별약관 또는 운영원칙의 내용이 상충되는 경우에는 개별약관 또는 운영원칙의 내용이 우선 적용됩니다.
 
제2장 이용계약의 체결

제5조 (이용계약의 성립)
1. 이용계약은 회원이 되고자 하는 자(이하 "가입신청자")가 약관의 내용에 동의를 하고, 회사가 정한 가입 양식에 따라 회원정보를 기입하여 회원가입신청을 하고 회사가 이러한 신청에 대하여 승인함으로써 체결됩니다.

제6조 (이용계약의 유보 및 거절)
1. 회사는 다음 각 호에 해당하는 신청에 대하여는 승인하지 않거나 사후에 이용계약을 해지할 수 있습니다.
(1) 가입신청서의 내용을 허위로 기재하거나 기재누락∙오기가 있는 경우
(2) 다른 사람의 명의, E-mail, 연락처 등을 이용한 경우
(3) 관계법령에 위배되거나 사회의 안녕질서 또는 미풍양속을 저해할 목적으로 신청한 경우
(4) 가입신청자가 본 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 단 회사의 사전 재가입 승낙을 얻은 경우에는 예외로 함
(5) 회원의 귀책사유로 인하여 승인이 불가능한 경우
(6) 이미 가입된 회원과 전화번호나 E-mail 정보가 동일한 경우
(7) 부정한 용도 또는 영리를 추구할 목적으로 본 서비스를 이용하고자 하는 경우
(8) 기타 본 약관에 위배되거나 위법 또는 부당한 이용신청임이 확인된 경우 및 회사가 합리적인 판단에 의하여 필요하다고 인정하는 경우
(9) 만 14세 미만의 사용자가 신청할 경우
2. 제1항에 따른 신청에 있어 회사는 전문기관을 통한 휴대전화번호 인증 내지 이메일 인증 또는 실명확인을 통한 본인인증을 요청할 수 있습니다.
3. 제1항에 따른 신청에 있어 회사는 제공하는 서비스의 종류에 따라 필요한 경우 가입신청자에게 추가 정보를 요청할 수 있습니다.
4. 회사는 아래 각 호에 해당하는 경우에는 회원등록의 승낙을 유보할 수 있습니다.
(1) 제공서비스 설비용량에 현실적인 여유가 없는 경우
(2) 서비스를 제공하기에는 기술적으로 문제가 있다고 판단되는 경우
(3) 회사가 재정적, 기술적으로 필요하다고 인정하는 경우
(4) 제2항에 따른 실명확인 및 본인인증 절차를 진행 중인 경우
5. 제1항과 제4항에 따라 회원가입신청의 승낙을 하지 아니하거나 유보한 경우, 회사는 승낙거절 또는 유보 사실을 가입신청자에게 회사가 정하는 방법에 따라 통지합니다.
6. 회원가입은 반드시 본인의 진정한 정보를 통하여만 가입할 수 있으며, 회사는 회원이 등록한 정보에 대하여 확인조치를 할 수 있습니다. 회원은 회사의 확인조치에 대하여 적극 협력하여야 하며, 만일 이를 준수하지 아니할 경우 회사는 회원이 등록한 정보가 부정한 것으로 처리할 수 있습니다.
7. 회사는 회원을 등급별로 구분하여 이용시간, 이용회수, 이용인원, 서비스 메뉴, 임대차 부동산 규모 등을 세분하여 이용에 차등을 둘 수 있습니다.
8. 이용계약의 성립 시기는 회사가 ‘가입완료’ 사실을 신청절차 상에 표시하거나 별도로 통지한 시점으로 합니다.
 
제7조 (회원 정보의 관리)
1. 회원은 웹사이트 또는 앱 내에 구비된 정보수정 기능 내지 고객센터 등을 통해 개인정보를 열람∙변경∙수정할 수 있습니다. 다만, 일부 개인정보의 경우 본인인증 절차가 필요할 수 있습니다.
2. 회원은 등록한 E-mail 주소 또는 연락처가 변경된 경우 회원정보의 최신성 유지를 위해 제1항에 따라 변경해야 하며 이를 변경하지 않아 발생할 수 있는 모든 불이익은 회원이 부담합니다.
 
제8조 (계정정보의 관리책임)
1. 아이디, 비밀번호 등 계정정보의 관리책임은 회원에게 있으며, 회원은 계정정보를 타인에게 양도 내지 대여할 수 없습니다.
2. 회사는 회사의 귀책사유로 인한 경우를 제외하고 계정정보의 유출, 양도, 대여, 공유 등으로 인한 손실이나 손해에 대하여 아무런 책임을 지지 않습니다.
3. 회원은 제3자가 본인의 계정을 사용하고 있음(대여 포함)을 인지한 경우에는 즉시 비밀번호를 수정하는 등의 조치를 취하고 이를 회사에 통보하여야 합니다. 회원이 본항에 따른 통지를 하지 아니하여 발생하는 모든 불이익에 대한 책임은 회원에게 있습니다.

제9조 (회원정보의 수집과 보호)
1.  회사는 서비스를 제공함에 있어 개인정보 관련 법령을 준수하고 그에 따라 회원 정보를 수집∙이용∙보관∙제공합니다.
2. 회사는 회원이 서비스 이용 과정에서 직접 제공한 정보 외에도 개인정보 보호 등 관련 법령에서 정한 절차에 따라 그밖의 정보를 수집 및 이용 또는 제3자에게 제공할 수 있습니다. 이 경우 회사는 관련 법령에 따라 회원으로부터 필요한 동의를 받거나 관련 법령에서 정한 절차를 준수합니다.
3. 회사는 개인정보 보호 관련 법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해 노력하며, 회사의 개인정보 처리에 관한 자세한 사항은 개인정보처리방침을 통해 언제든지 확인할 수 있습니다.
4. 회사의 공식 사이트 또는 서비스 중인 웹, 앱 이외에 링크된 사이트에서는 회사의 개인정보처리방침이 적용되지 않습니다. 링크된 사이트 및 서비스를 제공하는 제3자의 개인정보 처리에 대해서는 해당 사이트 및 제3자의 개인정보처리방침을 확인할 책임이 회원에게 있으며, 회사는 이에 대하여 책임을 부담하지 않습니다.

제3장 서비스 이용

제10조 (서비스의 이용개시)
회원은 회사가 이용신청을 승낙한 때부터 서비스를 사용할 수 있고 회사는 위 승낙한 때부터 본 약관에 따라 서비스를 제공합니다.

제11조 (서비스의 범위 및 이용요금)
회사는 제공한 서비스와 관련하여 이용자 또는 사업자 에게 일정 금액의 이용요금을 청구할 수 있습니다. 회사가 제공하는 서비스의 가격, 지급방법 등 구체적인 사항은 상호 간의 협의 또는 웹사이트 게시 등을 통해 확인할 수 있습니다. 서비스의 범위 및 내용, 서비스 이용요금 등 관련 제반 사항은 회사의 정책에 따라 수시로 변경될 수 있습니다.

제12조 (서비스의 이용시간)
1. 서비스는 회사의 업무상 또는 기술상 특별한 사유가 없는 한 연중무휴 1일 24시간 제공함을 원칙으로 합니다. 회사는 서비스를 일정범위로 분할하여 각 범위 별로 이용가능시간을 별도로 지정할 수 있습니다.
2. 회사는 서비스의 제공에 필요한 경우 정기점검 내지 수시점검을 실시할 수 있으며, 정기점검 내지 수시점검 시간은 서비스 제공화면에 공지한 바에 따릅니다.

제13조 (서비스의 내용)
1. 서비스는 제2조 제1호에서 정의한 바에 따라 회사가 제공하는 모든 자산 및 임대 관리 관련 서비스 등을 위한 온라인 서비스를 의미합니다. 서비스는 현재 제공되는 서비스 등에 한정되지 않으며, 향후 추가로 개발되거나 다른 회사와의 제휴 등을 통해 추가∙변경∙수정될 수 있고, 이 과정에서 일부 서비스의 경우 본인 인증 절차를 요구할 수 있습니다.
2. 회사는 회원 감소 등으로 인한 원활한 서비스 제공의 곤란 및 수익성 악화, 기술 진보에 따른 차세대 서비스로의 전환 필요성, 서비스 제공과 관련한 회사 정책의 변경 등 기타 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고 있는 전부 또는 일부 서비스를 변경 또는 중단할 수 있습니다.
3. 서비스의 내용, 이용방법, 이용시간에 대하여 변경 또는 서비스 중단이 있는 경우에는 변경 또는 중단될 서비스의 내용 및 사유와 일자 등은 그 변경 또는 중단 전에 회사 홈페이지 또는 서비스 내 "공지사항" 화면 등 회원이 충분히 인지할 수 있는 방법으로 30일의 기간을 두고 사전에 공지합니다.
4. 서비스 이용에 관한 개별 안내, 서비스 등에 대한 정보, 유의사항 등은 개별 서비스 이용안내∙소개 등을 통해 제공하고 있습니다.
5. 회원은 전항의 안내∙소개 등을 충분히 숙지하고 서비스를 이용하여야 합니다. 회원이 이 내용을 제대로 숙지하지 못해 발생한 피해에 대해서는 회사가 책임을 부담하지 않습니다.
 
제14조 (서비스의 변경 및 중지)
1. 회사는 변경될 서비스의 내용 및 제공일자를 본 약관 제13조에서 정한 방법으로 회원에게 고지하고 서비스를 변경하여 제공할 수 있습니다.
2. 회사는 다음 각 호에 해당하는 경우 서비스의 전부 또는 일부를 제한하거나 중지할 수 있습니다.
(1) 일부 서비스를 이용함에 있어 요구되는 본인인증 절차를 거치지 않거나, 본인인증 정보가 위조·변조·허위의 정보임이 확인된 경우
(2) 회원이 회사의 영업활동을 방해하는 경우
(3) 시스템 정기점검, 서버의 증설 및 교체, 네트워크의 불안정 등의 시스템 운영상 필요한 경우
(4) 정전, 서비스 설비의 장애, 서비스 이용폭주, 기간통신사업자의 설비 보수 또는 점검∙중지 등으로 인하여 정상적인 서비스 제공이 불가능한 경우
(5) 기타 중대한 사유로 인하여 회사가 서비스 제공을 지속하는 것이 부적당하다고 인정하는 경우
(6) 기타 천재지변, 국가비상사태 등 불가항력적 사유가 있는 경우
3. 제2항에 의한 서비스 중단의 경우 회사는 제13조에서 정한 방법으로 회원에게 통지합니다. 다만, 회사가 통제할 수 없는 사유로 인한 서비스의 중단(운영자의 고의∙과실이 없는 장애, 시스템다운 등)으로 인하여 사전 통지가 불가능한 경우에는 그러하지 아니합니다.
4. 회사는 제2항에 따른 서비스의 변경, 중지로 발생하는 문제에 대해서 어떠한 책임도 지지 않습니다.

제15조 (권리의 귀속)
1. 서비스에 대한 저작권 및 지식재산권은 회사에 귀속됩니다. 단, 게시물 및 제휴계약에 따라 제공된 저작물 등은 제외합니다.
2. 회사가 제공하는 서비스의 디자인, 회사가 만든 텍스트, 스크립트(script), 그래픽, 회원 상호간 전송 기능 등 회사가 제공하는 서비스에 관련된 모든 상표, 서비스 마크, 로고 등에 관한 저작권 기타 지적재산권은 대한민국 및 외국의 법령에 기하여 회사가 보유하고 있거나 회사에게 소유권 또는 사용권이 있습니다.
3. 회원은 본 이용약관으로 인하여 서비스를 소유하거나 서비스에 관한 저작권을 보유하게 되는 것이 아니라, 회사로부터 서비스의 이용을 허락 받게 되는바, 서비스는 정보취득 또는 개인용도로만 제공되는 형태로 회원이 이용할 수 있습니다.
4. 회원은 명시적으로 허락된 내용을 제외하고는 서비스를 통해 얻어지는 회원 상태정보를 영리 목적으로 사용, 복사, 유통하는 것을 포함하여 회사가 만든 텍스트, 스크립트, 그래픽의 회원 상호간 전송기능 등을 복사하거나 유통할 수 없습니다.
5. 회사는 서비스와 관련하여 회원에게 회사가 정한 이용조건에 따라 계정, ID, 콘텐츠 등을 이용할 수 있는 이용권만을 부여하며, 회원은 이를 양도, 판매, 담보제공 등의 처분행위를 할 수 없습니다.
6. 회원은 서비스를 이용하는 과정에서 얻은 정보를 회사의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송, 편집, 재가공 등 기타 방법에 의하여 영리 목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.

제4장 계약당사자의 의무

제16조 (회사의 의무)
1. 회사는 관련법과 본 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 계속적이고 안정적으로 서비스를 제공하기 위하여 최선을 다하여 노력합니다.
2. 회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보보호를 위해 보안시스템을 갖추어야 하며 개인정보처리방침을 공시하고 준수하며, 회원의 개인정보를 본인의 승낙 없이 제3자에게 누설, 배포하지 않고, 이를 보호하기 위하여 노력합니다.
3. 회사는 서비스이용과 관련하여 회원으로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 그에 필요한 조치를 취하여야 합니다.
4. 회사가 제공하는 서비스로 인하여 회원에게 손해가 발생한 경우 그러한 손해가 회사의 고의나 과실에 의해 발생한 경우에 한하여 회사에서 책임을 부담하며, 그 책임의 범위는 통상손해에 한합니다.

제17조 (회원의 의무)
1. 회원은 기타 관계 법령, 본 약관의 규정, 이용안내 및 서비스상에 공지한 주의사항, 회사가 통지하는 사항 등을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 아니 됩니다.
2. 회원은 서비스의 이용권한, 기타 서비스 이용계약상의 지위를 타인에게 양도, 증여할 수 없으며 이를 담보로 제공할 수 없습니다. 
3. 회원은 서비스 이용과 관련하여 다음 각 호의 행위를 하여서는 안됩니다.
(1) 서비스 신청 변경, 본인인증 등 서비스 이용과정에서 허위내용을 기재·등록·전송 등을 하는 행위
(2) 다른 회원의 아이디 및 비밀번호를 도용하여 부당하게 서비스를 이용하거나, 정보를 도용하는 행위
(3) 타인의 계좌번호 및 신용카드번호 등 타인의 허락 없이 타인의 결제정보를 이용하여 회사의 유료서비스를 이용하는 행위
(4) 정당한 사유 없이 당사의 영업을 방해하는 내용을 기재하는 행위
(5) 회사가 게시한 정보를 변경하는 행위
(6) 회사가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등을 송신 또는 게시하는 행위
(7) 회사와 기타 제3자의 저작권 등 지적재산권을 침해하는 행위
(8) 회사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
(9) 외설 또는 폭력적인 메시지, 화상, 음성 기타 공공질서 미풍양속에 반하는 정보를 공개 또는 게시하는 행위
(10) 회사의 동의 없이 영리를 목적으로 서비스를 사용하는 행위
(11) 회사의 직원이나 서비스의 관리자를 가장하거나 사칭하여 또는 타인의 명의를 도용하여 글을 게시하거나 메일을 발송하는 행위
(12) 서비스와 관련된 설비의 오동작이나 정보 등의 파괴 및 혼란을 유발시키는 컴퓨터 바이러스, 기타 다른 컴퓨터 코드, 파일, 프로그램 자료를 등록 또는 유포하는 행위
(13) 회사가 제공하는 소프트웨어 등을 개작하거나 리버스 엔지니어링, 디컴파일, 디스어셈블 및 기타 일체의 가공행위를 통하여 서비스를 복제, 분해 또는 모방 기타 변형하는 행위
(14) 자동 접속 프로그램 등을 사용하는 등 정상적인 용법과 다른 방법으로 서비스를 이용하여 회사의 서버에 부하를 일으켜 회사의 정상적인 서비스를 방해하는 행위
(15) 다른 회원의 개인정보를 그 동의 없이 수집, 저장, 공개하는 행위
(16) 기타 불법적이거나 회사에서 정한 규정을 위반하는 행위
4. 회사는 회원이 제1항의 행위를 하는 경우 해당 게시물 등을 삭제 또는 임시 삭제할 수 있으며 서비스의 이용을 제한하거나 일방적으로 본 계약을 해지할 수 있습니다.
5. 회원은 회원정보, 계정정보에 변경이 있는 경우 제7조에 따라 이를 즉시 변경하여야 하며, 더불어 비밀번호를 철저히 관리하여야 합니다. 회원의 귀책으로 말미암은 관리소홀, 부정사용 등에 의하여 발생하는 모든 결과에 대한 책임은 회원 본인이 부담하며, 회사는 이에 대한 어떠한 책임도 부담하지 않습니다.
6. 회원가입 및 기본 서비스는 14세 이상만 사용 가능합니다.
7. 회원은 회사에서 공식적으로 인정한 경우를 제외하고는 서비스를 이용하여 상품을 판매하는 영업 활동을 할 수 없으며, 특히 해킹, 광고를 통한 수익, 음란사이트를 통한 상업행위, 상용소프트웨어 불법배포 등을 할 수 없습니다. 이를 위반하여 발생한 영업 활동의 결과 및 손실, 관계기관에 의한 구속 등 법적 조치 등에 관해서는 회사가 책임을 지지 않으며, 회원은 이와 같은 행위와 관련하여 회사에 대하여 손해배상 의무를 집니다.
8. 회원은 직접 본인의 정보로 본인인증을 하여야 하고 이를 타인으로 하여금 하게 하거나 인증번호 등의 정보를 노출하여서는 안됩니다.

제18조 (회원에 대한 통지)
1. 회사가 회원에 대한 통지를 하는 경우 본 약관에 별도 규정이 없는 한 회원이 기재한 E-mail로 할 수 있습니다.
2. 회사는 불특정 다수 회원에 대한 통지의 경우 서비스 게시판 등에 게시함으로써 개별 통지에 갈음할 수 있습니다.

제19조 (서비스 이용해지)
1. 회원이 이용계약을 해지하고자 하는 때에는 사이트 또는 앱을 통해 또는 회사에 직접 연락을 통해 안내된 해지방법에 따라 해지를 신청할 수 있습니다.
2. 회사는 등록 해지신청이 접수되면 회원이 원하는 시점에 해당 회원의 서비스 이용을 해지하여야 합니다.
3. 회원이 계약을 해지할 경우, 관련법 및 개인정보처리방침에 따라 회사가 회원정보를 보유할 수 있는 경우를 제외하고 회원의 개인정보는 해지 즉시 삭제됩니다.

제20조 (서비스 이용제한)
1. 회사는 이용제한정책에서 정하는 바에 따라 회원이 본 약관상 의무를 위반하거나 서비스의 정상적인 운영을 방해한 경우, 경고∙일시적 이용정지∙영구적 이용정지 등의 단계로 서비스 이용을 제한하거나 이용계약을 해지할 수 있습니다. 단, 회원에게 제6조 제1항의 사유가 있음이 확인되거나 회원이 서비스 이용과 관련하여 불법행위를 하거나 조장∙방조한 경우에는 즉시 영구적 이용정지 조치 또는 이용계약 해지를 할 수 있습니다.
2. 회사는 회원이 계속해서 1년 이상 서비스를 이용하지 않은 경우에는, 정보통신망법에 따라 필요한 조치를 취할 수 있고, 회원정보의 보호 및 운영의 효율성을 위해 이용을 제한할 수 있습니다.
3 .본 조에 따라 서비스 이용을 제한하거나 계약을 해지하는 경우에는 회사는 제19조에 따라 회원에게 통지합니다.
4. 회원은 본 조에 따른 서비스 이용정지 기타 서비스 이용과 관련된 이용제한에 대해 회사가 정한 절차에 따라 이의신청을 할 수 있으며, 회사는 회원의 이의신청이 정당하다고 판단되는 경우 즉시 서비스 이용을 재개합니다.

제5장 기타

제21조 (손해배상)
1. 회원이 본 약관의 규정을 위반함으로 인하여 회사에 손해가 발생하게 되는 경우, 본 약관을 위반한 회원은 회사에 발생하는 모든 손해를 배상하여야 합니다.
2. 회원이 서비스를 이용하는 과정에서 행한 불법행위나 본 약관 위반행위로 인하여 회사가 당해 회원 이외의 제3자로부터 손해배상 청구 또는 소송을 비롯한 각종 이의제기를 받는 경우 당해 회원은 자신의 책임과 비용으로 회사를 면책시켜야 하며, 회사가 면책되지 못한 경우 당해 회원은 그로 인하여 회사에 발생한 모든 손해를 배상하여야 합니다.

제22조 (면책사항)
1. 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면책됩니다.
2. 회사는 회원의 귀책사유로 인한 서비스의 이용장애에 대하여 책임을 지지 않습니다.
3. 회사는 회원이 서비스를 이용하여 기대하는 수익을 상실한 것에 대하여 책임을 지지 않으며 그 밖에 서비스를 통하여 얻은 자료로 인한 손해 등에 대하여도 책임을 지지 않습니다. 회사는 회원이 게재한 게시물(커뮤니티 및 댓글 등)의 정확성 등 내용에 대하여는 책임을 지지 않습니다.
4. 회사는 회원 상호간 또는 회원과 제3자 상호간에 서비스를 매개로 발생한 분쟁에 대해서는 개입할 의무가 없으며 이로 인한 손해를 배상할 책임도 없습니다.
5. 회사는 회사 및 회사의 임직원 그리고 회사 대리인의 고의 또는 중대한 과실이 없는 한 다음 각 호의 사항으로부터 발생하는 손해에 대해 책임을 지지 아니합니다.
(1) 회원정보 등의 허위 또는 부정확성에 기인하는 손해
(2) 서비스에 대한 접속 및 서비스의 이용과정에서 발생하는 개인적인 손해
(3) 서버에 대한 제3자의 모든 불법적인 접속 또는 서버의 불법적인 이용으로부터 발생하는 손해
(4) 서버에 대한 전송 또는 서버로부터의 전송에 대한 제3자의 모든 불법적인 방해 또는 중단행위로부터 발생하는 손해
(5) 제3자가 서비스를 이용하여 불법적으로 전송, 유포하거나 또는 전송, 유포되도록 한 모든 바이러스, 스파이웨어 및 기타 악성 프로그램으로 인한 손해
(6) 전송된 데이터의 오류 및 생략, 누락, 파괴 등으로 발생되는 손해
(7) 회원간의 회원 상태정보 등록 및 서비스 이용 과정에서 발생하는 명예훼손 기타 불법행위로 인한 각종 민∙형사상 책임

제23조 (분쟁 조정 및 관할법원)
본 약관과 회사와 회원간에 발생한 분쟁 등에 관하여는 대한민국 법령이 적용되며, 회사의 주소지를 관할하는 법원을 관할법원으로 합니다.

부칙
이 약관은 2023년 01월 01일부터 적용됩니다.

`;

export const privacyTermsContent = `
개인정보처리방침

㈜디원더는 「개인정보 보호법」 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.

제1조(총칙)

1. 개인정보란 생존하는 개인에 관한 정보로서 당해 정보에 포함되어 있는 성명, 주민등록번호 등의 사항에 의하여 당해 개인을 식별할 수 있는 정보(당해 정보만으로는 특정 개인을 식별할 수 없더라도 다른 정보와 용이하게 결합하여 식별할 수 있는 것을 포함합니다.)를 말합니다.
2. ㈜디원더(이하 "회사"라 함)는 귀하의 개인정보보호를 매우 중요시하며, 『개인정보 보호법』상의 개인정보보호규정을 준수하고 있습니다. 회사는 개인정보처리방침을 통하여 귀하께서 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.
3. 개인정보처리방침은 정부의 법령이나 지침의 변경, 또는 보다 나은 서비스의 제공을 위하여 그 내용이 변경될 수 있습니다. 이 경우 회사는 웹 사이트에서 공지사항에 이를 올리거나 이메일을 통해서 공지하고 있습니다.
4. 회사는 개인정보처리방침을 사이트 첫 화면 또는 첫 화면과의 연결화면을 통해 공개함으로써 서비스에 가입한 사용자가 언제나 용이하게 보실 수 있도록 조치하고 있습니다.
5. 회사는 개인정보처리방침의 지속적인 개선을 위하여 개인정보처리방침을 개정하는데 필요한 절차를 정하고 있습니다.

제2조(개인정보의 처리 목적)

㈜디원더는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
1. 홈페이지 회원가입 및 관리
회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 서비스 부정이용 방지, 각종 고지·통지, 고충처리 목적으로 개인정보를 처리합니다.
2. 민원사무 처리
민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락·통지, 처리결과 통보 목적으로 개인정보를 처리합니다. 
3. 재화 또는 서비스 제공 및 개선
서비스 제공, 서비스 개선, 콘텐츠 제공, 맞춤서비스 제공, 본인인증을 목적으로 개인정보를 처리합니다.
4. 마케팅 및 광고에의 활용
신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공 , 인구통계학적 특성에 따른 서비스 제공 및 광고 게재 , 서비스의 유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.
 
제 3 조 (개인정보 수집에 대한 동의 및 수집 방법)

1. 회사는 이용자께서 회사의 동의문 또는 이용약관의 내용에 대해 ‘동의함’ 또는 ‘동의하지 않음’을 클릭할 수 있는 절차를 마련하여 ‘동의함’을 클릭하면 개인정보 수집에 대해 동의한 것으로 봅니다. 단, 회사는 다음 각 호의 어느 하나에 해당하는 경우에는 동의 없이 이용자의 개인정보를 수집∙이용할 수 있습니다.
1) 정보통신서비스의 제공에 관한 계약을 이행하기 위하여 필요한 개인정보로서 경제적∙기술적인 사유로 통상적인 동의를 받는 것이 뚜렷하게 곤란한 경우
2) 그 밖에 법률에 특별한 규정이 있는 경우
3) 계약을 이행하기 위하여 필요한 개인정보로서 경제적ㆍ기술적인 사유로 통상적인 동의를 받는 것이 뚜렷하게 곤란한 경우
- 디바이스 ID, 서비스이용기록, IP 주소, 접속로그, 단말기 OS와 버전, 단말기 모델명, 브라우저 버전
5) 수집 목적과 합리적인 관련성이 있고 정보주체에게 불이익이 발생하지 않으며 안전성 확보 조치를 적용한 경우
- 당초 수집 목적과 관련성
- 수집 정황 또는 처리 관행에 비춰봤을때 개인정보의 추가적인 이용에 대한 예측 가능성
- 정보주체의 이익 침해 여부
- 가명처리 또는 암호화 등 안전성 확보에 필요한 조치 적용 등의 사항을 모두 고려하여 판단
2. 회사가 개인정보를 수집하는 경우에는 반드시 사전에 이용자에게 해당 사실을 알리고 동의를 구하고 있으며, 아래와 같은 방법을 통해 개인정보를 수집합니다.
1) 홈페이지 회원가입 및 서비스 이용 과정에서 이용자가 개인정보 수집에 대해 동의를 하고 직접 정보를 입력하는 경우
2) 고객센터를 통한 상담 과정에서 웹페이지, 앱, 메일, 팩스, 전화 등을 통하는 경우

제 4 조 (수집하는 개인정보 항목 및 이용 목적)

1. 이용자는 회사의 회원제 서비스를 이용하시고자 할 경우 다음의 필수정보를 입력해주셔야 하며 선택항목을 입력하시지 않았다 하여 서비스 이용에 제한은 없습니다.
필수항목 : 이메일, 이름, 휴대폰 번호
선택항목 : 관리 운영 건축물 정보(건물주소 등), 임대차 계약 정보(임차인 이름, 월세 입금자 이름, 임차인 연락처, 계약 정보, 계좌정보, 계약서 사본 등), 고객상담 시 상담내용 및 상담에 필요한 개인정보
※ 위의 개인정보 수집‧이용에 대한 동의를 거부할 권리가 있으며, 동의를 거부할 경우 ㈜디원더의 서비스 이용이 제한됩니다.
2. 법령에 의해 수집 이용되는 회원 정보
1) 통계·학술·연구 목적
가. 수집항목 : 비식별화, 익명화 작업을 거친 정보 보유 (준영구)
나. 근거법률 : 개인정보보호법
2) 법원의 영장을 받아 수사기관이 요청 시 제공
가. 수집항목 : IP 등 로그기록 (3개월)
나. 근거법률 : 통신비밀보호법
3) 소비자의 불만 또는 분쟁처리에 관한 기록확인
가. 수집항목 : 이용자 식별정보, 분쟁처리 기록 등 (3년)
나. 근거법률 : 전자상거래등에서의 소비자보호에관한 법률
4) 대금결제 및 재화 등의 공급에 관한 기록확인 / 계약 또는 청약철회 등에 관한 기록확인
가. 수집항목 : 이용자 식별정보, 계약/청약철회 기록 등 (5년)
나. 근거법률 : 전자상거래등에서의 소비자보호에관한 법률
5) 전자금융거래기록 확인
가. 수집항목 : 전자금융거래에 관한 기록 (5년)
나. 근거법률 : 전자금융거래법
6) 신용정보의 수집/처리 및 이용 등에 관한 기록확인
가. 수집항목 : 신용정보의 수집/처리 및 이용 등에 관한 기록 (3년)
나. 근거법률 : 신용정보의 이용 및 보호에 관한 법률
3. 이용자의 기본적 인권 침해의 우려가 있는 민감한 개인정보(인종 및 민족, 사상 및 신조, 출신지 및 본적지, 정치적 성향 및 범죄기록, 의료정보 등)는 수집하지 않습니다.
4. 이용자의 개인정보는 서비스를 이용하는 시점부터 서비스를 제공하는 기간 동안에만 제한적으로 이용하고 있습니다. 이용자가 회원탈퇴를 요청하거나 이용자의 마지막 로그인 일시를 기준으로 1년이 초과되어 이용자의 계정이 휴면 처리되는 경우, 제공한 개인정보 수집 및 이용에 대한 정보는 별도 분리 보관하며, 이용자가 회원탈퇴를 요청하거나 휴면회원 전환 후 3년간 미 이용으로 자동 탈퇴 처리 및 제공한 개인정보의 수집 및 이용에 대한 동의를 철회하는 경우, 또는 수집 및 이용목적이 달성되거나 보유 및 이용기간이 종료된 경우 해당 이용자의 개인정보는 지체 없이 파기됩니다.
1) 종이에 출력된 개인 정보 : 분쇄기로 분쇄하거나 소각
2) 전자적 파일 형태로 저장된 개인 정보 : 기록을 재사용할 수 없는 기술적 방법을 사용하여 삭제
5. 회사가 서비스 제공을 위해 수집한 이용자의 모든 개인정보는 수집 단계부터 안전한 암호화 통신을 이용하여, AWS(Amazon Web Services) 상의 대한민국 리전에 위치한 데이타센터로 전송되며, 개인정보처리방침의 보관기간 동안 저장합니다. 클라우드의 보안은 AWS가 취득한 국제 인증 프로그램을 통해서 확인할 수 있으며, 회사와 클라우드 제공사인 AWS의 공동 책임 문서는 이 링크에서 확인할 수 있습니다. (https://aws.amazon.com/ko/compliance/shared-responsibility-model/).

제 5 조 (개인정보 자동수집 장치의 설치∙운영 및 그 거부에 관한 사항)

1. 쿠키(cookie)는 웹사이트가 이용자의 브라우저(인터넷 익스플로러, 크롬, 파이어폭스, 기타 모바일 브라우저)로 전송하는 소량의 정보입니다. 회사는 이용자에 대한 정보를 저장하고 수시로 찾아내는 ‘쿠키(cookie)’를 사용합니다. 쿠키는 이용자의 컴퓨터 또는 모바일 기기는 식별하지만 이용자를 개인적으로 식별하지는 않습니다. 또한 이용자는 쿠키에 대한 선택권이 있습니다.
2. 쿠키(cookie) 설정 방법의 예
1) Internet Explorer : 웹 브라우저 상단의 도구 메뉴 > 인터넷 옵션 > 개인정보 > 설정
2) Chrome : 웹 브라우저 우측의 상단 설정 메뉴 > 화면 하단의 고급 설정 표시 > 개인정보의 콘텐츠 설정 버튼 > 쿠키
3) Edge : 웹 브라우저 우측 상단의 점선(…) 메뉴 > 설정 > 업데이트 및 보안 > 쿠키
3. 회사의 쿠키(cookie) 운용
1) 쿠키(cookie)에 광고식별자, 디바이스 ID를 적재해 기기별로 차별화된 정보를 제공
2) 회원과 비회원의 접속빈도 또는 머문 시간 등을 분석하여 이용자의 취향과 관심분야를 파악하여 타겟(target) 마케팅에 활용
3) 클릭한 정보들에 대한 세부정보와 관심 있게 둘러본 정보들에 대한 자취를 분석하여 다음 번 접속 때 개인 맞춤 서비스를 제공
4) 회원들의 습관을 분석하여 서비스 개편 등의 척도로 이용

제 6 조 (개인정보의 제3자 제공에 대한 동의)

1. 회사는 회원의 개인정보를 제2조, 제3조에서 고지한 범위 내에서 사용하며, 동 범위를 초과하여 이용하거나 타인 또는 타기업, 기관에 제공하지 않습니다.
2. 전항에도 불구하고, 다음의 경우에는 예외로 합니다.
1) 법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 불가피한 경우
2) 정보주체 또는 그 법정대리인이 의사표시를 할 수 없는 상태에 있거나 주소불명 등으로 사전 동의를 받을 수 없는 경우로서 명백히 정보주체 또는 제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요하다고 인정되는 경우
3) 정보통신서비스의 제공에 따른 요금정산을 위하여 필요한 경우
4) 수집 목적과 합리적인 관련성이 있고 정보주체에게 불이익이 발생하지 않으며 안전성 확보 조치를 적용한 경우
- 당초 수집 목적과 관련성
- 수집 정황 또는 처리 관행에 비춰봤을때 개인정보의 추가적인 이용∙제공에 대한 예측 가능성
- 정보주체의 이익 침해 여부
- 가명처리 또는 암호화 등 안전성 확보에 필요한 조치 적용 등의 사항을 모두 고려하여 판단

제 7 조 (개인정보의 열람, 정정)

1. 이용자는 언제든지 등록되어 있는 본인의 개인정보를 열람하거나 정정하실 수 있습니다. 개인정보 열람 및 정정을 하고자 할 경우에는 서비스 내 ‘설정’을 클릭하여 직접 열람 또는 정정하거나, 개인정보 보호책임자 및 담당자에게 서면, 전화 또는 이메일 등으로 연락하시면 지체 없이 조치하겠습니다.
2. 이용자가 개인정보의 오류에 대한 정정을 요청한 경우, 정정을 완료하기 전까지 당해 개인 정보를 이용 또는 제공하지 않습니다.
3. 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정하도록 조치하겠습니다.

제 8 조 (동의 철회)

1. 회원가입 등을 통해 개인정보의 수집, 이용, 제공에 대해 회원이 동의한 내용을 회원은 언제든지 철회하실 수 있습니다. 동의 철회는 ‘설정’을 클릭하거나 개인정보 보호책임자에게 서면, 전화 또는 이메일 등으로 연락하시면 개인정보의 삭제 등 필요한 조치를 하겠습니다. 동의 철회를 하고 개인정보를 파기하는 등의 조치를 취한 경우에는 그 사실을 귀하께 지체 없이 통지하도록 하겠습니다.
2. 회사는 개인정보의 수집에 대한 동의철회(회원탈퇴)를 개인정보를 수집하는 방법보다 쉽게 할 수 있도록 필요한 조치를 취하겠습니다.

제 9 조 (개인정보보호를 위한 기술 및 관리적 대책)

1. 기술적 대책: 회사는 이용자의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적 대책을 강구하고 있습니다.
1) 이용자의 개인정보는 비밀번호에 의해 보호되며 파일 및 전송데이터를 암호화하거나 파일 잠금기능(Lock)을 사용하여 중요한 데이터는 별도의 보안기능을 통해 보호되고 있습니다.
2) 회사는 백신프로그램을 이용하여 컴퓨터바이러스에 의한 피해를 방지하기 위한 조치를 취하고 있습니다. 백신프로그램은 주기적으로 업데이트되며 갑작스런 바이러스가 출현할 경우 백신이 나오는 즉시 이를 제공함으로써 개인정보가 침해되는 것을 방지하고 있습니다.
3)해킹 등 외부침입에 대비하여 각 서버마다 침입차단시스템 및 취약점 분석시스템 등을 이용하여 보안에 만전을 기하고 있습니다.
2. 관리적 대책
1) 회사는 귀하의 개인정보에 대한 접근권한을 최소한의 인원으로 제한하고 있습니다. 그 최소한의 인원에 해당하는 자는 다음과 같습니다.
(1) 이용자를 직접 상대로 하여 마케팅 업무를 수행하는 자
(2) 고객의 불만 및 이용문의 처리 업무를 수행하는 자
(3) 개인정보보호책임자 및 담당자 등 개인정보관리업무를 수행하는 자
(4) 기타 업무상 개인정보의 처리가 불가피한 자
2) 입사 시 전 직원의 보안서약서를 통하여 사람에 의한 정보유출을 사전에 방지하고 개인정보처리방침에 대한 이행사항 및 직원의 준수여부를 감사하기 위한 내부절차를 마련하고 있습니다.
3) 개인정보 관련 처리자의 업무 인수인계는 보안이 유지된 상태에서 철저하게 이뤄지고 있으며 입사 및 퇴사 후 개인정보 사고에 대한 책임을 명확화하고 있습니다.
4) 사내 개인정보보호 전담기구를 통해 개인정보처리방침의 이행사항 및 담당자의 준수여부를 확인하여 문제가 발견될 경우 즉시 수정하고 바로 잡을 수 있도록 노력하고 있습니다.
5) 회사는 이용자 개인의 실수나 기본적인 인터넷의 위험성 때문에 일어나는 일들에 대해 책임을 지지 않습니다. 회원 개개인이 본인의 개인정보를 보호하기 위해서 자신의 아이디와 비밀번호를 적절하게 관리하고 여기에 대한 책임을 져야 합니다.
6) 그 외 내부 관리자의 실수나 기술관리 상의 사고로 인해 개인정보의 상실, 유출, 변조, 훼손이 유발될 경우 회사는 즉각 귀하께 사실을 알리고 적절한 대책과 보상을 강구할 것입니다.

제 10 조 (링크사이트)

회사는 이용자들께 다른 회사의 웹사이트 또는 자료에 대한 링크를 제공할 수 있습니다. 이 경우 회사는 외부사이트 및 자료에 대한 아무런 통제권이 없으므로 그로부터 제공받는 서비스나 자료의 유용성에 대해 책임질 수 없으며 보증할 수 없습니다. 회사가 포함하고 있는 링크를 클릭(click)하여 타 사이트(site)의 페이지로 옮겨갈 경우 해당 사이트의 개인정보처리방침은 회사와 무관하므로 새로 방문한 사이트의 정책을 검토해 보시기 바랍니다.

제 11 조 (개인정보의 위탁처리)

회사는 경영 효율성의 제고, 서비스 품질 향상 등을 위하여 업무의 일부를 외부 전문업체 등 제 3자에게 용역을 주어 수행하며, 이를 위해 제3자에게 이용자의 개인정보 및 사업자가 보유하고 있는 고객 정보를 수집, 보관, 처리, 이용, 제공, 관리, 파기 등을 할 수 있도록 업무를 처리위탁합니다. 이와 관련하여, 수탁자 및 위탁업무의 내용은 다음과 같습니다.
1. 회사의 개인정보 위탁처리 기관 및 위탁업무 내용은 아래와 같습니다.
1) Amazon Web Services, Inc. / 데이터 보관 및 인프라 관리
2) 카카오(주) / 알림톡 및 SMS 발송
수탁자의 개인정보 보유 및 이용기간 : 회원 탈퇴시, 서비스 종료시 혹은 위탁 계약 종료시까지
채널은 그 밖에 추가적으로 제3자에게 개인정보를 처리위탁 해야 하는 경우에 본 개인정보처리방침을 수정하여 이를 공개하는 등 법령상 필요한 조치를 취합니다.

제 12 조(이용자의 권리와 의무)

1. 이용자의 개인정보를 최신의 상태로 정확하게 입력하여 불의의 사고를 예방해 주시기 바랍니다. 이용자가 입력한 부정확한 정보로 인해 발생하는 사고의 책임은 이용자 자신에게 있으며 타인 정보의 도용 등 허위정보를 입력할 경우 회원자격이 상실될 수 있습니다.
2. 이용자는 개인정보를 보호받을 권리와 함께 스스로를 보호하고 타인의 정보를 침해하지 않을 의무도 가지고 있습니다. 귀하의 개인정보가 유출되지 않도록 조심하시고 게시물을 포함한 타인의 개인정보를 훼손하지 않도록 유의해 주십시오. 만약 이 같은 책임을 다하지 못하고 타인의 정보 및 존엄성을 훼손할 시에는 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』, 『개인정보보호법』 등에 의해 처벌받을 수 있습니다.

제 13 조 (의견수렴 및 불만처리)

1. 회사는 이용자의 의견을 소중하게 생각하며, 이용자는 의문사항으로부터 언제나 성실한 답변을 받을 권리가 있습니다.
2. 회사는 이용자와의 원활한 의사소통을 위해 고객센터를 운영하고 있으며 연락처는 아래와 같습니다.
•	▶ CX 책임자
•	성명 : 김현철
•	직책 : CX 총괄
•	연락처 : tony@theoneder.co.kr
3. 기타 개인정보에 관한 상담이 필요한 경우에는 회사의 위 전자우편으로 문의하실 수 있으며, 국가기관에 신고나 상담이 필요한 경우에는 아래의 연락처에 문의하셔서 도움을 받으실 수 있습니다.
1) 개인정보침해신고센터(http://privacy.kisa.or.kr / 국번 없이 118)
2) 대검찰청 사이버수사과(http://spo.go.kr / 국번 없이 1301)
3) 경찰청 사이버수사국(http://ecrm.cyber.go.kr / 국번 없이 182)
4) 개인정보 분쟁조정위원회(http://www.kopico.go.kr / 1833-6972)

제 13 조(개인정보 보호책임자)
회사는 이용자께서 좋은 정보를 안전하게 이용할 수 있도록 최선을 다하고 있습니다. 개인정보를 보호하는데 있어 이용자께 고지한 사항들에 반하는 사고가 발생할 시에 개인정보 보호책임자가 모든 책임을 집니다. 그러나 기술적인 보완조치를 했음에도 불구하고, 해킹 등 기본적인 네트워크상의 위험성에 의해 발생하는 예기치 못한 사고로 인한 정보의 훼손 및 방문자가 작성한 게시물에 의한 각종 분쟁에 관해서는 책임이 없습니다. 귀하의 개인정보를 처리하는 책임자 및 담당자는 다음과 같으며 개인정보 관련 문의사항에 신속하고 성실하게 답변해드리고 있습니다.
•	▶ 개인정보 보호책임자
•	성명 : 안이서
•	직책 : 운영 총괄
•	연락처 : lucas@theoneder.co.kr

•	▶ 개인정보 보호 담당부서
•	부서명 :개발팀
•	담당자 :이다은
•	연락처 : elin@theoneder.co.kr

제 14 조(개인정보 처리방침의 개정과 그 공지)

개인정보 처리방침을 변경하는 경우 회사는 공지사항 또는 별도의 창을 통하여 지체 없이 공지하고, 이용자가 언제든지 변경된 사항을 쉽게 알아볼 수 있도록 조치하겠습니다.

공지일자 : 2023년 01월 01일
시행일자 : 2023년 01월 01일


`;
