import styled from "@emotion/styled";
import React from "react";
import StandardTag from "../../../../components/tags/StandardTag";
import { LocationApiResponse } from "../../../../types/location";

interface Props {
  data: LocationApiResponse;
}

export default function DetailContent(props: Props) {
  return (
    <ItemLayout>
      <ImgBoxLayout>
        <LocationInfoLayout>
          <StandardTag
            scaleKey="TAG1"
            color="GRAYSCALE1"
            customStyle={{ fontWeight: "var(--fw_m)" }}
          >
            {props.data.title}
          </StandardTag>
          <StandardTag scaleKey="TAG2" color="GRAYSCALE1">
            {props.data.address}
          </StandardTag>
        </LocationInfoLayout>
        <ImgLayout
          src={
            props.data.image_list.length > 0
              ? props.data.image_list[0].stored_file_path
              : "/imgs/logos/loketrip_logo.svg"
          }
        />
      </ImgBoxLayout>
      <Description>
        <StandardTag scaleKey="BODY1" color="GRAYSCALE1">
          {props.data.detail}
        </StandardTag>
      </Description>
    </ItemLayout>
  );
}

const ItemLayout = styled.div`
  width: 358px;
  min-height: 380px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex-shrink: 0;
`;

const ImgBoxLayout = styled.div`
  width: 100%;
  height: 332px;
  position: relative;
  background-color: var(--color_black);
`;

const ImgLayout = styled.img`
  width: 100%;
  height: 100%;
`;

const LocationInfoLayout = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  color: var(--color_gray1);
  padding: 11px 23px;
  z-index: 10;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
`;

const Description = styled.div`
  width: 100%;
  white-space: pre-wrap;
  word-break: keep-all;

  & div {
    font-weight: var(--fw_r);
  }
`;
