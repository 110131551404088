import styled from "@emotion/styled";
import { CustomIcon } from "../../../components/icons/CustomIcon";

interface ComponentProps {
  text: string;
  type: boolean;
  justifyContentEnd: boolean;
  isNecessary?: boolean;
  onClick?: () => void;
}

export const CheckBoxWrap = styled.div`
  margin-bottom: 20px;
`;

const BoxWrap = styled.span<{
  justifyContentEnd: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  justify-content: ${(props) => (props.justifyContentEnd ? `end` : `start`)};
  align-items: center;
  height: 42px;
`;

const SubText = styled.span`
  margin-top: 1px;
  color: var(--color_black);
  font-size: var(--fs_s);
  margin-left: 16px;
`;
const NecessaryText = styled.span`
  margin-top: 1px;
  color: var(--color_primary1);
  font-size: var(--fs_xs);
  margin-left: 4px;
`;

const MobileCheckBox = (props: ComponentProps) => {
  const className = "check";
  let customStyle: React.CSSProperties;
  let textNecessary;
  if (!props.type) {
    customStyle = {
      color: "var(--color_gray5)",
      backgroundColor: "var(--color_white)",
      borderRadius: "50%",
      border: "1px solid var(--color_gray5)",
    };
  } else {
    customStyle = {
      color: `var(--color_white)`,
      backgroundColor: "var(--color_primary1)",
      borderRadius: "50%",
      border: "1px solid var(--color_primary1)",
    };
  }
  if (props.isNecessary) {
    textNecessary = "(필수)";
  }
  return (
    <>
      <BoxWrap justifyContentEnd={props.justifyContentEnd}>
        <CustomIcon
          iconName={className}
          customStyle={customStyle}
          onClick={props.onClick}
          className="cs_pt"
        />
        <SubText
          style={{
            color: "var(--color_gray9)",
            fontSize: "var(--fs_s)",
            fontWeight: "var(--fw_m)",
          }}
        >
          {props.text}
          <NecessaryText>{textNecessary}</NecessaryText>
        </SubText>
      </BoxWrap>
    </>
  );
};

export default MobileCheckBox;
