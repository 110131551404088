import React from "react";
import { AuthContext } from "../containers/contexts/AuthProvider";
import { getCookie } from "../utils/cookie";

export function useToken() {
  const auth = React.useContext(AuthContext);
  if (auth?.token) {
    return auth.token;
  }
  const storedToken = getCookie("Authorization");
  if (storedToken) {
    return storedToken;
  }
  const storedLocalToken = localStorage.getItem("Authorization");
  if (storedLocalToken) {
    return storedLocalToken;
  }
  return null;
}
