import { AuthObj } from "../containers/contexts/AuthProvider";
import { MEMBER_ROLE, MemberRoleKey } from "../types/unions/enums/memberRole";

export const checkAdminOrHigher = (auth: AuthObj | null) => {
  return checkRoleAvailable("ROLE_ADMIN", auth);
};

export const checkSuperAdminOrHigher = (auth: AuthObj | null) => {
  return checkRoleAvailable("ROLE_SUPER_ADMIN", auth);
};

export const checkRoleAvailable = (
  targetRoleKey: MemberRoleKey,
  auth: AuthObj | null
) => {
  if (!auth || !auth.loggedInMember) {
    return false;
  }
  const loggedInMember = auth.loggedInMember;
  const currentRole = MEMBER_ROLE[loggedInMember.role];
  const targetRole = MEMBER_ROLE[targetRoleKey];
  // if (loggedInMember.super_admin) {
  //   return true;
  // }
  if (currentRole.id < targetRole.id) {
    return false;
  }
  return true;
};
