import axios from "axios";
import { baseAdminUrl, baseCustomerUrl } from "../constants/ApiConstants";
import { getCookie } from "../utils/cookie";

export const axiosAdmin = axios.create({
  baseURL: baseAdminUrl,
  timeout: 2500,
  withCredentials: true,
  headers: {
    Authorization: getCookie("Authorization"),
  },
});

export const axiosCustomer = axios.create({
  baseURL: baseCustomerUrl,
  timeout: 2500,
  withCredentials: true,
  headers: {
    Authorization: getCookie("Authorization"),
  },
});

export const axiosAdminNoTimeout = axios.create({
  baseURL: baseAdminUrl,
  withCredentials: true,
  headers: {
    Authorization: getCookie("Authorization"),
  },
});

export const axiosCustomerNoTimeout = axios.create({
  baseURL: baseCustomerUrl,
  withCredentials: true,
  headers: {
    Authorization: getCookie("Authorization"),
  },
});
