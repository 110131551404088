import { ConfigProvider, Rate, RateProps } from "antd";
import React from "react";

interface Props extends RateProps {
  size?: number;
}

export default function CustomRate(props: Props) {
  return (
    <ConfigProvider
      theme={{
        components: {
          Rate: {
            colorFillContent: "#b3b3b3",
          },
        },
      }}
    >
      <Rate style={{ fontSize: props.size }} {...props} />
    </ConfigProvider>
  );
}
