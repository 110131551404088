import { COLOR_TYPE } from "./basicColor";

export type ButtonColorKey =
  | "GRAY05_FONT_BLACK_OUTLINE" // 모달창 취소
  | "GRAY1_FONT_BLACK" // 닫기,
  | "GRAY2_FONT_BLACK2" // 페이지 취소
  | "GRAY5" // 비활성화 확인, 가입하기, 인증요청,
  | "GRAY6" // 등록
  | "PRIMARY" // 로그인, 활성화 확인, 활성화 가입하기, 로그인하러 가기,
  | "BLACK" // 네,
  | "BLACK_OUTLINE" // 발행하기, 저장하기
  | "DANGER"
  | "SECONDARY2" // 등록하기
  | "OK"
  | "CUSTOM";

export type ButtonColorType = {
  [k in ButtonColorKey]: {
    font: string;
    background: string;
    border: string;
    opacity?: number;
  };
};

export const BUTTON_COLOR_TYPE: ButtonColorType = {
  GRAY05_FONT_BLACK_OUTLINE: {
    font: COLOR_TYPE.BLACK,
    background: COLOR_TYPE.GRAYSCALE05,
    border: COLOR_TYPE.BLACK3,
  },
  GRAY1_FONT_BLACK: {
    font: COLOR_TYPE.BLACK,
    background: COLOR_TYPE.GRAYSCALE1,
    border: COLOR_TYPE.GRAYSCALE1,
  },
  GRAY2_FONT_BLACK2: {
    font: COLOR_TYPE.BLACK2,
    background: COLOR_TYPE.GRAYSCALE2,
    border: COLOR_TYPE.GRAYSCALE2,
  },
  GRAY5: {
    font: COLOR_TYPE.WHITE,
    background: COLOR_TYPE.GRAYSCALE5,
    border: COLOR_TYPE.GRAYSCALE5,
  },
  GRAY6: {
    font: COLOR_TYPE.WHITE,
    background: COLOR_TYPE.GRAYSCALE6,
    border: COLOR_TYPE.GRAYSCALE6,
  },
  PRIMARY: {
    font: COLOR_TYPE.WHITE,
    background: COLOR_TYPE.PRIMARY1,
    border: COLOR_TYPE.PRIMARY1,
  },
  BLACK: {
    font: COLOR_TYPE.WHITE,
    background: COLOR_TYPE.BACKGROUND2,
    border: COLOR_TYPE.BACKGROUND2,
  },
  BLACK_OUTLINE: {
    font: COLOR_TYPE.WHITE,
    background: COLOR_TYPE.BACKGROUND2,
    border: COLOR_TYPE.BORDER1,
  },
  DANGER: {
    font: COLOR_TYPE.WHITE,
    background: COLOR_TYPE.ERROR,
    border: COLOR_TYPE.ERROR,
  },
  SECONDARY2: {
    font: COLOR_TYPE.WHITE,
    background: COLOR_TYPE.SECONDARY2,
    border: COLOR_TYPE.SECONDARY2,
  },
  OK: {
    font: COLOR_TYPE.WHITE,
    background: COLOR_TYPE.OK,
    border: COLOR_TYPE.OK,
  },
  CUSTOM: {
    font: COLOR_TYPE.BLACK,
    background: COLOR_TYPE.GRAYSCALE05,
    border: COLOR_TYPE.BLACK,
  },
};
