import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import SearchBar from "../../components/inputs/SearchBar";
import SizedBox from "../../components/boxes/SizedBox";
import PaddingBox from "../../components/boxes/PaddingBox";
import StandardTag from "../../components/tags/StandardTag";
import TitlePageLayout from "../../components/layouts/TitlePageLayout";
import MainContent from "./components/items/MainContent";
import CenterBox from "../../components/boxes/CenterBox";
import { ContentApiResponse } from "../../types/content";
import { useGetContentListByStatus } from "../../apis/content";
import { checkContentIncludesSearchWord } from "./MainCustomerPage";
import { useTitle } from "../../hooks/useTitle";

export default function MainMoreCustomerPage() {
  useTitle("콘텐츠 리스트")
  const [searchWord, setSearchWord] = useState("");
  const [contentList, setContentList] = useState<ContentApiResponse[]>([]);

  const res = useGetContentListByStatus(false, "PUBLISHED");

  useEffect(() => {
    if (res.data?.data) {
      setContentList(res.data.data);
    }
  }, [res]);

  return (
    <TitlePageLayout title="로케트립 전체로 즐기기!" isDark={true}>
      <SizedBox height={23} />
      <PaddingBox symmetric={{ horizontal: 16 }}>
        <SearchBar searchWord={searchWord} setSearchWord={setSearchWord} />
      </PaddingBox>
      <SizedBox height={18} />
      <PaddingBox symmetric={{ horizontal: 8 }} className="dp_fx_col fx_grow">
        {contentList.length > 0 ? (
          <ContentLayout>
            {contentList.map((content) => {
              if (!checkContentIncludesSearchWord(content, searchWord)) {
                return null;
              }
              return <MainContent key={content.id} data={content} />;
            })}
          </ContentLayout>
        ) : (
          <CenterBox>
            <StandardTag scaleKey="BODY2" color="GRAYSCALE2">
              콘텐츠를 준비중입니다.
            </StandardTag>
          </CenterBox>
        )}
      </PaddingBox>
    </TitlePageLayout>
  );
}

const ContentLayout = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px 2px;
`;
