import { useMutation, useQuery } from "@tanstack/react-query";
import {
  LoggedInUserDataResponse,
  MemberApiRequest,
  MemberApiResponse,
  MemberDeleteRequest,
  MemberRoleApiRequest,
  RegisterApiRequest,
  RegisterApiRequestForPassword,
} from "../types/member";
import { axiosAdminNoTimeout, axiosCustomerNoTimeout } from "./axiosInstances";
import { AxiosError, AxiosResponse } from "axios";
import {
  doAxiosGetByModule,
  getFunctionByModule,
} from "../constants/ApiConstants";

const target = "members";

// GET
export const useGetLoggedInData = (isAdmin: boolean) => {
  return useQuery<AxiosResponse<LoggedInUserDataResponse>, AxiosError>(
    ["loggedInUser"],
    () => {
      return doAxiosGetByModule(isAdmin, target, "data");
    }
  );
};

export const useGetLoggedInData2 = (isAdmin: boolean, token: string | null) => {
  return useQuery<AxiosResponse<LoggedInUserDataResponse>, AxiosError>(
    ["loggedInUser", token],
    () => {
      return doAxiosGetByModule(isAdmin, target, "data");
    },
    {
      onError(err) {
        console.log(err);
      },
    }
  );
};

export const useGetMyData = (isAdmin: boolean) => {
  return useQuery<AxiosResponse<MemberApiResponse>, AxiosError>(
    ["myData"],
    () => {
      return doAxiosGetByModule(isAdmin, target, "my_data");
    }
  );
};

// POST
export const UserCreateMember = () => {
  return useMutation((request: RegisterApiRequest) =>
    axiosCustomerNoTimeout.post(`/${target}`, request)
  );
};
export const useCheckMatchingPassword = () => {
  return useMutation((request: RegisterApiRequestForPassword) =>
    axiosCustomerNoTimeout.post(`/${target}/checkMatchingPassword`, request)
  );
};
export const UseUpdatePassword = () => {
  return useMutation((request: RegisterApiRequestForPassword) =>
    axiosCustomerNoTimeout.put(`/${target}/update`, request)
  );
};
export const useUpdateMemberByCustomer = () => {
  return useMutation((request: MemberApiRequest) =>
    axiosCustomerNoTimeout.put(`/${target}`, request)
  );
};
// PUT
export const useUpdateMember = () => {
  return useMutation((request: MemberApiRequest) =>
    axiosAdminNoTimeout.put(`/${target}`, request)
  );
};

export const useUpdateMemberRole = () => {
  return useMutation((request: MemberRoleApiRequest) =>
    axiosAdminNoTimeout.put(`/${target}/role`, request)
  );
};

// DELETE
export const useDeleteMember = () => {
  return useMutation((request: MemberDeleteRequest) =>
    axiosCustomerNoTimeout.put(`/${target}/delete`, request)
  );
};
