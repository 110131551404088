import React, { PropsWithChildren, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { useToken } from "../../hooks/useToken";
import { MemberRoleKey } from "../../types/unions/enums/memberRole";
import { checkAdminOrHigher } from "../../utils/auth";
import TitlePageLayout from "../../components/layouts/TitlePageLayout";

interface RouteProps extends PropsWithChildren {
  isAdminPage: boolean;
}

interface decodedToken {
  role: MemberRoleKey;
  exp: number;
  email: string;
}

export default function ProtectedRoute(props: RouteProps) {
  const auth = useAuth();
  const token = useToken();
  const navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (auth && auth.loggedInMember !== undefined) {
      if (!token || token === "LOGOUT") {
        auth?.onLogout(props.isAdminPage);
        // return <Navigate to={`/login`} />;
        navigate("/");
      }

      if (props.isAdminPage && !checkAdminOrHigher(auth)) {
        auth?.onLogout(props.isAdminPage);
        // return <Navigate to={`/login`} />;
        navigate("/login");
      }
      setIsLoaded(true);
    } else if (auth && auth.loggedInMember === null) {
      navigate("/login");
    }
  }, [auth, token]);

  // try {
  //   const decodedToken = jwtDecode<decodedToken>(token);
  //   const memberRole = MEMBER_ROLE[decodedToken.role];

  //   // 멤버 권한 체크
  //   if (memberRole.name === MEMBER_ROLE.ROLE_UNREGISTERED.name) {
  //     auth?.onLogout(props.isAdminPage);
  //     return (
  //       <Navigate
  //         to={`/login?error=true&exception=${LOGIN_PAGE_EXCEPTION_PARAM.MEMBER_NOT_REGISTERED.name}`}
  //       />
  //     );
  //   }

  //   if (memberRole.name === MEMBER_ROLE.ROLE_REJECTED.name) {
  //     auth?.onLogout(props.isAdminPage);
  //     return (
  //       <Navigate
  //         to={`/login?error=true&exception=${LOGIN_PAGE_EXCEPTION_PARAM.MEMBER_REJECTED.name}`}
  //       />
  //     );
  //   }
  // } catch {}

  return (
    <>
      {isLoaded ? (
        <>{props.children}</>
      ) : (
        <>
          <TitlePageLayout title={""} isDark={true}></TitlePageLayout>
        </>
      )}
    </>
  );
}
