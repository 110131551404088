import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { axiosCustomerNoTimeout } from "../../apis/axiosInstances";
import { useTitle } from "../../hooks/useTitle";
import styled from "@emotion/styled";
import NoTitlePageLayout from "../../components/layouts/NoTitlePageLayout";
import StandardTag from "../../components/tags/StandardTag";
import { useAuth } from "../../hooks/useAuth";

export default function Oauth2Page(props: any) {
  useTitle("카카오 로그인")
  const [isLoading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const auth = useAuth();
  const code = new URL(window.location.href).searchParams.get("code");
  useEffect(() => {
    kakaoLogin();
  }, [])
  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);
  const kakaoLogin = async () => {
    if(isLoading) {
      setLoading(false);
      await axiosCustomerNoTimeout({
        method: "GET",
        url: `/login/oauth2/kakao?code=${code}`,
        headers: {
          "Content-Type": "application/json;charset=utf-8", //json형태로 데이터를 보내겠다는뜻
        },
      }).then((res) => { //백에서 완료후 우리사이트 전용 토큰 넘겨주는게 성공했다면
        auth?.onLogin(res.headers.authorization);
        //로그인이 성공하면 이동할 페이지
        navigate("/");
      });
    }
  };
  return (
    <NoTitlePageLayout title={`카카오 로그인`} isDark={false}>
      <Oauth2PageLayout>
        <StandardTag scaleKey={`TITLE3`}>
          카카오 로그인 중입니다.
        </StandardTag>
        <StandardTag
          scaleKey={`TITLE5`}
        >
          잠시만 기다려주세요.
        </StandardTag>
      </Oauth2PageLayout>
    </NoTitlePageLayout>
  )
}

const Oauth2PageLayout = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--gap_16);
  padding-bottom: 120px;
`
