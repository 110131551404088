import { LABEL_AUTH_CODE, LABEL_CHECK_PASSWORD, LABEL_EMAIL, LABEL_PASSWORD, MESSAGE_PASSWORD_NOT_EQUALS, MESSAGE_RE_ERROR_AUTH_CODE, MESSAGE_RE_ERROR_EMAIL, MESSAGE_RE_ERROR_PASSWORD, MESSAGE_SEND_EMAIL_FIRST, MESSAGE_WRITE_PASSWORD_FIRST, TEXT_BUTTON_AUTHENTICATED, TEXT_BUTTON_DO_AUTHENTICATION, TEXT_BUTTON_SEND_AUTHENTICATION } from "../strings/string";
import { MemberInputCheckDto } from "../types/member";
import { InputStatus } from "../types/unions/status";
import { ButtonColorKey } from "../types/unions/styles/colors/buttonColor";
import { isValidString } from "./checkType";

const iconCheckCircle = "check-circle";
const iconWarningCircle = "warning-circle";
const ButtonDeactivated: ButtonColorKey = `GRAY5`;
const ButtonActivated: ButtonColorKey = `BLACK`;
const ButtonConfirmed: ButtonColorKey = `OK`;
const emailRule = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/;

//버튼 비활성화를 위한 noActionFunction
const noActionFunction = () => {};

type styleType = `small_normal` | `small_deactivated` | `big_normal` | `big_deactivated`;

export function checkEmail(email: string): MemberInputCheckDto {
  // 이메일 입력되면 인증번호 체크는 바로 무효화 됨.
  let authCodeCheck: boolean = false;
  // 이전에 보낸 메일도 무효화
  let emailLabel: string = LABEL_EMAIL;
  let emailButtonLabel: string = TEXT_BUTTON_SEND_AUTHENTICATION;
  let emailIcon: string = ``;
  let emailIconStyle: React.CSSProperties = {};
  let emailButtonColor: ButtonColorKey = ButtonDeactivated;
  let emailStatus: InputStatus = `NORMAL`;

  // 입력은 했지만 정규표현식 안 지킬 경우
  if(!checkEmailRegEx(email)) {
    emailLabel = MESSAGE_RE_ERROR_EMAIL;
    emailStatus = `ERROR`;
    emailIcon = iconWarningCircle;
    emailIconStyle = {color: `var(--color_danger)`};
  };

  // 비어있을 때는 REGEX 결과 안 나와야
  if(email === ``){
    emailLabel = LABEL_EMAIL;
    emailIcon = ``;
    emailIconStyle = {};
    emailStatus = `NORMAL`;
  }

  if(isValidString(email) && checkEmailRegEx(email)) {
    emailStatus = `OK`;
    emailButtonColor = ButtonActivated;
  }
  return {
    authCodeCheck: authCodeCheck,
    inputLabel: emailLabel,
    inputButtonLabel: emailButtonLabel,
    inputIcon: emailIcon,
    inputIconStyle: emailIconStyle,
    inputButtonColor: emailButtonColor,
    inputStatus: emailStatus,
  }
};

export function checkAuthCode(isMailSent: boolean, isAuthCodeCheck: boolean, authCode: string): MemberInputCheckDto {
  let authCodeLabel: string = LABEL_AUTH_CODE;
  let authCodeButtonLabel: string = TEXT_BUTTON_DO_AUTHENTICATION;
  let authCodeIcon: string = ``;
  let authCodeIconStyle: React.CSSProperties = {};
  let authCodeButtonColor: ButtonColorKey = ButtonDeactivated;
  let authCodeStatus: InputStatus = `NORMAL`;
  // 메일 보내졌는지 확인
  if (!isMailSent) {
    if(isValidString(authCode)) {
      authCodeLabel = MESSAGE_SEND_EMAIL_FIRST;
      authCodeStatus = `ERROR`;
      authCodeIcon = iconWarningCircle;
      authCodeIconStyle = {color: `var(--color_danger)`};
    }
    return {
      authCodeCheck: false,
      inputLabel: authCodeLabel,
      inputButtonLabel: authCodeButtonLabel,
      inputIcon: authCodeIcon,
      inputIconStyle: authCodeIconStyle,
      inputButtonColor: authCodeButtonColor,
      inputStatus: authCodeStatus,
    }
  }

  // 6자리 입력했는지 확인
  if(authCode.length !== 6) {
    authCodeLabel = MESSAGE_RE_ERROR_AUTH_CODE;
    authCodeStatus = `ERROR`;
    authCodeIcon = iconWarningCircle;
    authCodeIconStyle = {color: `var(--color_danger)`};
    return {
      authCodeCheck: isAuthCodeCheck,
      inputLabel: authCodeLabel,
      inputButtonLabel: authCodeButtonLabel,
      inputIcon: authCodeIcon,
      inputIconStyle: authCodeIconStyle,
      inputButtonColor: authCodeButtonColor,
      inputStatus: authCodeStatus,
    }
  }
  // 비어있을 때는 상관 없음
  if(authCode === ``){
    authCodeLabel = LABEL_EMAIL;
    authCodeStatus = `NORMAL`;
    authCodeIcon = ``;
    authCodeIconStyle = {};
  }

  authCodeStatus = `OK`;
  authCodeButtonColor = ButtonActivated;
  if(isAuthCodeCheck) {
    authCodeButtonLabel = TEXT_BUTTON_AUTHENTICATED;
    authCodeIcon = iconCheckCircle;
    authCodeIconStyle = {color: `var(--color_ok)`};
    authCodeButtonColor = ButtonConfirmed;
  }
  return {
    authCodeCheck: isAuthCodeCheck,
    inputLabel: authCodeLabel,
    inputButtonLabel: authCodeButtonLabel,
    inputIcon: authCodeIcon,
    inputIconStyle: authCodeIconStyle,
    inputButtonColor: authCodeButtonColor,
    inputStatus: authCodeStatus,
  }
};

export function checkPassword(password: string): MemberInputCheckDto {
  let passwordLabel: string = LABEL_PASSWORD;
  let passwordIcon: string = ``;
  let passwordIconStyle: React.CSSProperties = {};
  let passwordStatus: InputStatus = `NORMAL`;

  if(!checkPasswordRegEx(password)) {
    passwordLabel = MESSAGE_RE_ERROR_PASSWORD;
    passwordIcon = iconWarningCircle;
    passwordIconStyle = {color: `var(--color_danger)`};
    passwordStatus = `ERROR`;
  }

  if(password === ``) {
    passwordLabel = LABEL_PASSWORD;
    passwordIcon = ``;
    passwordIconStyle = {};
    passwordStatus = `NORMAL`;
  }

  if(isValidString(password) && checkPasswordRegEx(password)) {
    passwordStatus = `OK`;
    passwordIcon = iconCheckCircle;
    passwordIconStyle = {color: `var(--color_ok)`};
  }

  return {
    inputLabel: passwordLabel,
    inputIcon: passwordIcon,
    inputIconStyle: passwordIconStyle,
    inputStatus: passwordStatus,
  }
};

export function checkPasswordCheck(
  passwordStatus: InputStatus | undefined,
  passwordCheck: string, originalPassword: string): MemberInputCheckDto {
  let passwordCheckLabel: string = LABEL_CHECK_PASSWORD;
  let passwordCheckIcon: string = ``;
  let passwordCheckIconStyle: React.CSSProperties = {};
  let passwordCheckStatus: InputStatus = `NORMAL`;

  // 기존 비밀번호가 제대로 입력되었는지 확인
  if(passwordStatus !== `OK`) {
    // 아닌 상태에서 입력하면 무조건 에러 반환
    if(isValidString(passwordCheck)) {
      passwordCheckLabel = MESSAGE_WRITE_PASSWORD_FIRST;
      passwordCheckIcon = iconWarningCircle;
      passwordCheckIconStyle = {color: `var(--color_danger)`};
      passwordCheckStatus = `ERROR`;
    }
    return {
      inputLabel: passwordCheckLabel,
      inputIcon: passwordCheckIcon,
      inputIconStyle: passwordCheckIconStyle,
      inputStatus: passwordCheckStatus,
    }
  }

  if(isValidString(passwordCheck)) {
    if(passwordCheck !== originalPassword) {
      passwordCheckLabel = MESSAGE_PASSWORD_NOT_EQUALS;
      passwordCheckIcon = iconWarningCircle;
      passwordCheckIconStyle = {color: `var(--color_danger)`};
      passwordCheckStatus = `ERROR`;
    } else{
      passwordCheckIcon = iconCheckCircle;
      passwordCheckIconStyle = {color: `var(--color_ok)`};
      passwordCheckStatus = `OK`;
    }
  }
  return {
    inputLabel: passwordCheckLabel,
    inputIcon: passwordCheckIcon,
    inputIconStyle: passwordCheckIconStyle,
    inputStatus: passwordCheckStatus,
  }
};

export function checkStatus(name: string,
  emailStatus: InputStatus | undefined,
  authCodeStatus: InputStatus | undefined,
  passwordStatus: InputStatus | undefined,
  passwordCheckStatus: InputStatus | undefined): boolean {
  if(name === ``) {
    return false;
  }
  if(emailStatus !== `OK`) {
    return false;
  }
  if(authCodeStatus !== `OK`) {
    return false;
  }
  if(passwordStatus !== `OK`) {
    return false;
  }
  if(passwordCheckStatus !== `OK`) {
    return false;
  }
  return true;
}

// =================== private ==================== //
function checkEmailRegEx(email: string): boolean {
  // 메일 입력 여부
  if(!isValidString(email)) {
    return false;
  };

  // 제대로 된 메일인지 확인
  return emailRule.test(email);
};

function checkPasswordRegEx(password: string): boolean {
  let blank = password.search(/\s/);
  let num = password.search(/[0-9]/g);
  let eng = password.search(/[a-z]/gi);
  let spe = password.search(/[`~!@@#$%^&*|₩₩₩₩;:₩/?]/gi);
  if(!isValidString(password)) {
    return false;
  }
  // 조건 충족 여부 체크
  if (num <= -1 || eng <= -1 || spe <= -1 || password.length < 8 || password.length > 15) {
    return false;
  }
  // 공백 포함 여부 체크
  if (blank !== -1) {
    return false;
  }
  return true;
}