import styled from "@emotion/styled";
import React, { PropsWithChildren } from "react";
import {
  COLOR_TYPE,
  ColorKey,
} from "../../types/unions/styles/colors/basicColor";
import {
  TAG_STYLE_TYPE,
  TagStyleKey,
} from "../../types/unions/styles/tagStyle";

const DefaultTag = styled.div<{
  fontSize: string;
  fontWeight: string;
  color?: string;
  width?: number;
  height?: number;
  textRotation?: number;
  lineHeight: string;
}>`
  font-size: ${(props) => `var(--${props.fontSize})`};
  font-weight: ${(props) => `var(--${props.fontWeight})`};
  color: var(--${(props) => props.color});
  width: ${(props) => (props.width ? `${props.width}px` : ``)};
  height: ${(props) => (props.height ? `${props.height}px` : ``)};
  transform: ${(props) =>
    props.textRotation ? `rotate(${props.textRotation}deg)` : ``};
  line-height: ${(props) => `var(--${props.lineHeight})`};
`;

interface TagProps extends PropsWithChildren {
  scaleKey: TagStyleKey;
  color?: ColorKey;
  width?: number;
  height?: number;
  customStyle?: React.CSSProperties;
  className?: string;
  textRotation?: number;
  tagRef?: React.RefObject<HTMLDivElement>;
  onClick?: () => void;
}

export default function StandardTag(props: TagProps) {
  const tagStyle = TAG_STYLE_TYPE[props.scaleKey];
  let color = ``;
  if (props.color) {
    color = COLOR_TYPE[props.color];
  }
  return (
    <DefaultTag
      className={props.className}
      fontSize={tagStyle && tagStyle.size}
      fontWeight={tagStyle && tagStyle.weight}
      style={props.customStyle}
      width={props.width}
      height={props.height}
      color={color}
      textRotation={props.textRotation}
      lineHeight={tagStyle && tagStyle.lineHeight}
      ref={props.tagRef}
      onClick={props.onClick}
    >
      {props.children}
    </DefaultTag>
  );
}
