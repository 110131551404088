import styled from "@emotion/styled";
import React, { PropsWithChildren } from "react";
import {
  FONT_SIZE_TYPE,
  FONT_WEIGHT_TYPE,
  FontSizeKey,
  FontWeightKey,
} from "../../types/unions/styles/font";
import {
  ColorKey,
  COLOR_TYPE,
} from "../../types/unions/styles/colors/basicColor";
import {
  ButtonColorKey,
  BUTTON_COLOR_TYPE,
} from "../../types/unions/styles/colors/buttonColor";

const ButtonLayout = styled.div<{
  width?: string;
  height?: string;
  fontColor: string;
  backgroundColor: string;
  borderColor: string;
  opacity?: number;
  fontSize?: FontSizeKey;
  fontWeight?: FontWeightKey;
  padding?: boolean;
  disabled?: boolean;
  borderRadius?: "S" | "M" | "L";
}>`
  width: ${(props) => (props.width ? `${props.width}` : "100%")};
  height: ${(props) => (props.height ? `${props.height}` : "fit-content")};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: ${(props) => (props.width ? "0" : "1")};
  gap: var(--gap_4);
  font-size: ${(props) =>
    props.fontSize
      ? `var(--${FONT_SIZE_TYPE[props.fontSize]})`
      : "var(--fs_m)"};
  font-weight: ${(props) =>
    props.fontWeight
      ? `var(--${FONT_WEIGHT_TYPE[props.fontWeight]})`
      : "var(--fw_m)"};
  opacity: ${(props) => (props.opacity ? `${props.opacity}` : "1")};
  color: var(--${(props) => props.fontColor});
  background-color: var(--${(props) => props.backgroundColor});
  border: 1px solid;
  border-color: var(--${(props) => props.borderColor});
  border-radius: ${(props) =>
    props.borderRadius
      ? `var(--border_radius_${props.borderRadius.toLocaleLowerCase()})`
      : "0px"};
  cursor: ${(props) => props.disabled ? `not-allowed` : `pointer`};
  white-space: nowrap;
  -webkit-tap-highlight-color: transparent;
`;

/* border-radius: var(--${(props) => props.radius}); */
export interface ButtonProps extends PropsWithChildren {
  colorType: ButtonColorKey;
  onClick?: React.MouseEventHandler<HTMLElement>;
  // 커스터 마이징 시 필요한 스타일 변수
  // 너비는 부모 영역 안에 자식 요소의 개수와 크기에 따라 자동 조정되나 따로 지정 가능
  width?: string;
  // 높이는 내용 높이 + padding이 기본값이나 따로 지정 가능
  height?: string;
  // colorType이 CUSTOM일 경우에만 사용됨
  fontColor?: ColorKey;
  backgroundColor?: ColorKey;
  borderColor?: ColorKey;
  opacity?: number;
  // undefined가 아닌 경우에만 사용됨(undefined일 경우 default 값이 사용됨)
  fontSize?: FontSizeKey;
  fontWeight?: FontWeightKey;
  // boolean값에 따라 정해진 스타일로 적용됨
  isReserve?: boolean;
  isWithIcon?: boolean;
  // 따로 스타일 지정이 필요한 경우에만 제한적으로 사용
  customStyle?: React.CSSProperties;
  disabled?: boolean;
  borderRadius?: "S" | "M" | "L";
}
export const Button = (props: ButtonProps) => {
  const colorType = BUTTON_COLOR_TYPE[props.colorType];
  let radiusType;
  let isWithIcon;
  if (props.isWithIcon) {
    isWithIcon = true;
  } else {
    isWithIcon = false;
  }
  return (
    <ButtonLayout
      onClick={props.onClick}
      width={props.width}
      height={props.height}
      fontColor={
        props.colorType === "CUSTOM" && props.fontColor
          ? COLOR_TYPE[props.fontColor]
          : colorType.font
      }
      backgroundColor={
        props.colorType === "CUSTOM" && props.backgroundColor
          ? COLOR_TYPE[props.backgroundColor]
          : colorType.background
      }
      borderColor={
        props.colorType === "CUSTOM" && props.borderColor
          ? COLOR_TYPE[props.borderColor]
          : colorType.border
      }
      opacity={
        props.colorType === "CUSTOM" && props.opacity
          ? props.opacity
          : colorType.opacity
      }
      fontSize={props.fontSize}
      fontWeight={props.fontWeight}
      style={props.customStyle}
      padding={isWithIcon}
      disabled={props.disabled}
      borderRadius={props.borderRadius}
    >
      {props.children}
    </ButtonLayout>
  );
};
