import React, { useEffect, useState } from "react";
import {
  useGetContentListByStatus,
  useUpdateContentStatusToPublished,
} from "../../../../apis/content";
import CenterBox from "../../../../components/boxes/CenterBox";
import PaddingBox from "../../../../components/boxes/PaddingBox";
import IconImage from "../../../../components/icons/IconImage";
import SearchBar from "../../../../components/inputs/SearchBar";
import {
  FlexColLayout,
  FlexRowLayout,
} from "../../../../components/layouts/styles";
import StandardTag from "../../../../components/tags/StandardTag";
import { ContentApiResponse } from "../../../../types/content";
import ContentItem from "../items/ContentItem";
import { TitleBoxLayout } from "../../ContentManagementAdminPage";
import CustomCheckbox from "../../../../components/checkboxes/CustomCheckbox";
import styled from "@emotion/styled";
import { Button } from "../../../../components/buttons/Button";
import ConfirmModal from "../../../../components/modals/ConfirmModal";
import { useMessage } from "../../../../hooks/useMessage";
import { ContentStatusKey } from "../../../../types/unions/enums/ContentStatus";
import { useQueryClient } from "@tanstack/react-query";
import { checkContentIncludesSearchWord } from "../../../main/MainCustomerPage";

export default function PendingSection() {
  const [searchWord, setSearchWord] = useState("");
  const [contentList, setContentList] = useState<ContentApiResponse[]>([]);
  const [checkAll, setCheckAll] = useState(false);
  const [checkedContentList, setCheckedContentList] = useState<
    ContentApiResponse[]
  >([]);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);

  const [messageFn, messageContextHolder] = useMessage();

  const res = useGetContentListByStatus(true, "PENDING");
  const queryClient = useQueryClient();
  const updateContentStatusMutation = useUpdateContentStatusToPublished();

  useEffect(() => {
    if (res.data?.data) {
      setContentList(res.data.data);
    }
  }, [res]);

  useEffect(() => {
    if (checkAll) {
      setCheckedContentList([...contentList]);
    } else {
      setCheckedContentList([]);
    }
  }, [checkAll, contentList]);

  // 발행하기 로직
  const publish = () => {
    updateContentStatusMutation.mutate(checkedContentList, {
      onSuccess(data, variables, context) {
        messageFn("success", "발행이 완료되었습니다.");
        queryClient.invalidateQueries([
          "content",
          "list",
          { filter: "PENDING" as ContentStatusKey },
        ]);
      },
    });
  };

  return (
    <SectionLayout>
      <PaddingBox symmetric={{ horizontal: 16, vertical: 14 }}>
        <SearchBar searchWord={searchWord} setSearchWord={setSearchWord} />
      </PaddingBox>
      <PaddingBox
        only={{ top: 10, right: 10, bottom: 18, left: 16 }}
        className="dp_fx_row fx_jc_btw"
      >
        <TitleBoxLayout>
          <StandardTag scaleKey="TITLE3">저장한 콘텐츠</StandardTag>
          {searchWord.length === 0 && (
            <StandardTag scaleKey="SUBTITLE1">
              ({contentList.length}개)
            </StandardTag>
          )}
        </TitleBoxLayout>
        {searchWord.length === 0 && (
          <FlexRowLayout
            gap={10}
            className="fx_ai_ct"
            style={{ marginRight: "12px" }}
          >
            <div style={{ color: "#bebebe" }} className="fs_xs fw_r">
              전체선택
            </div>
            <CustomCheckbox
              checked={checkAll}
              onClick={() => {
                setCheckAll((prev) => !prev);
              }}
            />
          </FlexRowLayout>
        )}
      </PaddingBox>
      {contentList.length > 0 ? (
        <>
          <FlexColLayout gap={4} className="w_100">
            {contentList.map((content) => {
              if (!checkContentIncludesSearchWord(content, searchWord)) {
                return null;
              }
              return (
                <ContentItem
                  key={content.id}
                  data={content}
                  checkAll={checkAll}
                  onClickToCheck={(
                    content: ContentApiResponse,
                    isCancel: boolean
                  ) => {
                    const newList: ContentApiResponse[] = [
                      ...checkedContentList,
                    ];
                    const index = newList.findIndex(
                      (item) => item.id === content.id
                    );
                    if (isCancel) {
                      if (index > -1) {
                        newList.splice(index, 1);
                        setCheckedContentList(newList);
                      }
                    } else {
                      if (index === -1) {
                        setCheckedContentList((prev) => [...prev, content]);
                      }
                    }
                  }}
                />
              );
            })}
          </FlexColLayout>
          <PaddingBox symmetric={{ horizontal: 16 }}>
            <FlexColLayout gap={12} style={{ marginTop: "75px" }}>
              <div className="cl_error fs_xs fw_r">
                총 {checkedContentList.length}개 선택됨
              </div>
              <Button
                colorType={checkedContentList.length > 0 ? "PRIMARY" : "CUSTOM"}
                backgroundColor="BORDER1"
                borderColor="BORDER1"
                fontColor="WHITE"
                fontWeight="BOLD"
                isWithIcon={false}
                onClick={() => {
                  setConfirmModalVisible(true);
                }}
                height="44px"
                fontSize="XS"
                customStyle={{ borderRadius: "8px" }}
              >
                발행하기
              </Button>
            </FlexColLayout>
          </PaddingBox>
        </>
      ) : (
        <CenterBox className="bg_bk">
          <FlexColLayout gap={9} className="fx_ai_ct">
            <IconImage iconName="empty" />
            <div className="fs_xxs cl_wt">해당 콘텐츠 영역이 비었습니다.</div>
          </FlexColLayout>
        </CenterBox>
      )}
      {confirmModalVisible && (
        <ConfirmModal
          visible={confirmModalVisible}
          resetFn={() => {
            setConfirmModalVisible(false);
          }}
          title={"해당 컨텐츠를 발행하시겠습니까?"}
          subTitle={
            "발행 완료된 컨텐츠의 위치는 ‘수정하기’에서 다시 수정할 수 있습니다."
          }
          okText={undefined}
          cancelText={undefined}
          okFn={() => {
            publish();
            setConfirmModalVisible(false);
          }}
          cancelFn={undefined}
        />
      )}
      {messageContextHolder}
    </SectionLayout>
  );
}

const SectionLayout = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;
