import styled from "@emotion/styled";
import React, { ChangeEvent, useCallback, useRef } from "react";
import IconImage from "../icons/IconImage";
import { FileProps, UploadFileResponse } from "../../types/files";
import { fileToBase64 } from "../../utils/imageUtils";
import { LocationApiRequest } from "../../types/location";

interface Props {
  inputId: string;
  isDetail?: boolean;
  files: FileProps[];
  setFiles: (props: FileProps[]) => void;
  uploadImages?: UploadFileResponse[];
  updateLocationDeletedFileList?: (image: UploadFileResponse) => void;
  bannerImageUrl?: string | null;
}

export default function ImageInput(props: Props) {
  const fileId = useRef<number>(0);
  const onChangeFiles = useCallback(
    async (e: ChangeEvent<HTMLInputElement> | any): Promise<void> => {
      let selectFiles = e.target.files;
      let tempFiles: FileProps[] = [];
      // if (props.isDetail) {
      //   tempFiles = props.files;
      // }

      if (props.updateLocationDeletedFileList) {
        if (props.uploadImages && props.uploadImages.length > 0) {
          props.updateLocationDeletedFileList(props.uploadImages[0]);
        }
      }

      let index = 0;
      for (const file of selectFiles) {
        // if (!checkImageFile(file.name)) {
        //   messageFn("error", "사진 파일 업로드만 가능합니다.");
        //   return;
        // }
        tempFiles = [
          ...tempFiles,
          {
            id: fileId.current++,
            object: file,
            base64String:
              file.type.indexOf("image") > -1 ? await fileToBase64(file) : "",
            array_index: index,
          },
        ];
        ++index;
      }
      props.setFiles(tempFiles);
    },
    [props]
  );

  const getElement = () => {
    if (props.uploadImages && props.uploadImages.length > 0) {
      return (
        <ImgBoxLayout htmlFor={props.inputId} isDetail={props.isDetail}>
          <ImgInputBoxLayout
            src={props.uploadImages[0].stored_file_path}
            isDetail={props.isDetail}
          />
        </ImgBoxLayout>
      );
    } else if (props.bannerImageUrl) {
      return (
        <ImgBoxLayout htmlFor={props.inputId} isDetail={props.isDetail}>
          <ImgInputBoxLayout
            src={props.bannerImageUrl}
            isDetail={props.isDetail}
          />
        </ImgBoxLayout>
      );
    } else {
      if (props.files.length > 0) {
        return (
          <ImgBoxLayout htmlFor={props.inputId} isDetail={props.isDetail}>
            <ImgInputBoxLayout
              src={props.files[0].base64String}
              isDetail={props.isDetail}
            />
          </ImgBoxLayout>
        );
      } else {
        return (
          <ImgBoxLayout htmlFor={props.inputId} isDetail={props.isDetail}>
            <IconImage iconName={"add_image"} />
            <ImgInputPlaceholder>
              {/* 이미지 첨부 대표 {props.isDetail ? 10 : 1}장 */}
              이미지 첨부 대표 1장
              <br /> 권장 사이즈 : 1200*1200 권장
              <br /> 파일종류 : JPG, JPEG, PNG
            </ImgInputPlaceholder>
          </ImgBoxLayout>
        );
      }
    }
  };

  return (
    <div>
      <ImgInput id={props.inputId} type="file" onChange={onChangeFiles} />
      {getElement()}
      {/* {props.files.length > 0 ? (
        <ImgBoxLayout htmlFor={props.inputId} isDetail={props.isDetail}>
          <ImgInputBoxLayout
            src={props.files[0].base64String}
            isDetail={props.isDetail}
          />
        </ImgBoxLayout>
      ) : (
        <ImgBoxLayout htmlFor={props.inputId} isDetail={props.isDetail}>
          <IconImage iconName={"add_image"} />
          <ImgInputPlaceholder>
            이미지 첨부 대표 {props.isDetail ? 10 : 1}장
            <br /> 권장 사이즈 : 1200*1200 권장
            <br /> 파일종류 : JPG, JPEG, PNG
          </ImgInputPlaceholder>
        </ImgBoxLayout>
      )} */}
    </div>
  );
}

const ImgInputBoxLayout = styled.img<{ isDetail?: boolean }>`
  width: 100%;
  height: ${(props) => (props.isDetail ? 270 : 348)}px;
  object-fit: cover;
`;

const ImgBoxLayout = styled.label<{ isDetail?: boolean }>`
  width: 100%;
  height: ${(props) => (props.isDetail ? 270 : 348)}px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding-top: 50px; */
  gap: 30px;
  background-color: var(--color_black);
`;

const ImgInputPlaceholder = styled.div`
  color: rgba(255, 255, 255, 0.38);
  text-align: center;
  font-size: var(--fs_xs);
  font-weight: var(--fw_r);
  line-height: 18px; /* 128.571% */
  letter-spacing: -0.5px;
`;

const ImgInput = styled.input`
  width: 100%;
  height: 100%;
  display: none;
`;
