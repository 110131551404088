export type ColorKey =
  | "WHITE"
  | "GRAYSCALE05"
  | "GRAYSCALE1"
  | "GRAYSCALE2"
  | "GRAYSCALE3"
  | "GRAYSCALE4"
  | "GRAYSCALE5"
  | "GRAYSCALE6"
  | "GRAYSCALE7"
  | "GRAYSCALE8"
  | "GRAYSCALE9"
  | "BLACK"
  | "BLACK2"
  | "BLACK3"
  | "BORDER1"
  | "PRIMARY1"
  | "PRIMARY2"
  | "ERROR"
  | "OK"
  | "SECONDARY1"
  | "SECONDARY2"
  | "BACKGROUND1"
  | "BACKGROUND2"
  | "KAKAO_YELLOW"
  | "KAKAO_BLACK"
;

type ColorType = {
  [k in ColorKey]: string;
};

export const COLOR_TYPE: ColorType = {
  WHITE: "color_white",
  GRAYSCALE05: "color_gray05",
  GRAYSCALE1: "color_gray1",
  GRAYSCALE2: "color_gray2",
  GRAYSCALE3: "color_gray3",
  GRAYSCALE4: "color_gray4",
  GRAYSCALE5: "color_gray5",
  GRAYSCALE6: "color_gray6",
  GRAYSCALE7: "color_gray7",
  GRAYSCALE8: "color_gray8",
  GRAYSCALE9: "color_gray9",

  BLACK: "color_black",
  BLACK2: "color_black2",
  BLACK3: "color_black3",
  BORDER1: "color_border1",

  PRIMARY1: "color_primary1",
  PRIMARY2: "color_primary2",

  ERROR: "color_error",
  OK: "color_ok",

  SECONDARY1: "color_secondary1",
  SECONDARY2: "color_secondary2",

  BACKGROUND1: "color_bg1",
  BACKGROUND2: "color_bg2",

  KAKAO_YELLOW: "color_kakao_yellow",
  KAKAO_BLACK: "color_kakao_black",
};
