import styled from "@emotion/styled";
import React, { useState } from "react";
import { CustomTextarea } from "../../../../components/inputs/custom/styles";
import PaddingBox from "../../../../components/boxes/PaddingBox";
import LocationWriteItem from "../items/LocationWriteItem";
import { FlexColLayout } from "../../../../components/layouts/styles";
import StandardTag from "../../../../components/tags/StandardTag";
import IconImage from "../../../../components/icons/IconImage";
import { ContentApiRequest } from "../../../../types/content";
import { LocationApiRequest } from "../../../../types/location";
import { UploadFileResponse } from "../../../../types/files";

interface Props {
  isSelected: boolean;
  request: ContentApiRequest;
  locationList: LocationApiRequest[];
  addLocation: () => void;
  deleteLocation: (location: LocationApiRequest) => void;
  updateBannerInfo: (name: keyof ContentApiRequest, value: string) => void;
  updateLocationInfo: (
    location: LocationApiRequest,
    name: keyof LocationApiRequest,
    value: string
  ) => void;
  updateLocationImageList: (
    location: LocationApiRequest,
    imageList: File[]
  ) => void;
  updateLocationDeletedFileList: (
    location: LocationApiRequest,
    image: UploadFileResponse
  ) => void;
}

export default function DetailSection(props: Props) {
  return (
    <SectionLayout isSelected={props.isSelected}>
      <PaddingBox only={{ top: 39, right: 4, left: 4, bottom: 22 }}>
        <CustomTextarea
          placeholder="내용(최대 500자)"
          value={props.request.description || ""}
          onChange={(e) => {
            props.updateBannerInfo("description", e.target.value);
          }}
        />
      </PaddingBox>
      <FlexColLayout gap={22}>
        {props.locationList.map((request) => {
          return (
            <LocationWriteItem
              key={request.temp_id}
              request={request}
              updateLocationInfo={props.updateLocationInfo}
              updateLocationImageList={props.updateLocationImageList}
              updateLocationDeletedFileList={
                props.updateLocationDeletedFileList
              }
              deleteLocation={props.deleteLocation}
            />
          );
        })}
      </FlexColLayout>
      <AddButtonLayout onClick={props.addLocation}>
        <StandardTag
          scaleKey="LABEL1"
          customStyle={{ color: "rgba(255, 255, 255, 0.74)" }}
        >
          템플릿 추가하기
        </StandardTag>
        <div className="circle">
          <div className="inner_circle">
            <IconImage iconName="plus" size={14} />
          </div>
        </div>
      </AddButtonLayout>
    </SectionLayout>
  );
}

const SectionLayout = styled.div<{ isSelected: boolean }>`
  width: 100%;
  flex-grow: 1;
  display: ${(props) => (props.isSelected ? "flex" : "none")};
  flex-direction: column;
  padding: 0 12px;
`;

const AddButtonLayout = styled.div`
  width: 100%;
  height: 117px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;

  & .circle {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 50%;
  }

  & .inner_circle {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.25);
    border-radius: 50%;
  }
`;
