import styled from "@emotion/styled";
import React from "react";
import { FlexRowLayout } from "../layouts/styles";

interface Props {
  checked: boolean;
  label?: string;
  onClick: () => void;
}

export default function CustomCheckbox(props: Props) {
  return (
    <FlexRowLayout gap={10} className="fx_ai_ct cs_pt" onClick={props.onClick}>
      <CheckboxLayout>
        {props.checked && <img src="/imgs/icons/check.svg" />}
      </CheckboxLayout>
      {props.label && <Label>{props.label}</Label>}
    </FlexRowLayout>
  );
}

const CheckboxLayout = styled.div`
  width: 14px;
  height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #6f6f6f;
  background: #414040;
`;

const Label = styled.div`
  color: #fff;
  font-size: 11px;
  font-weight: var(--fw_r);
`;
