import React, { useEffect, useState } from "react";
import { FlexColLayout } from "../../../../components/layouts/styles";
import styled from "@emotion/styled";
import StandardTag from "../../../../components/tags/StandardTag";
import { ContentDetailResponse } from "../../../../types/content";
import { Map, MapMarker } from "react-kakao-maps-sdk";
import { LocationApiResponse } from "../../../../types/location";

interface Props {
  data: ContentDetailResponse;
}

export default function MapSection(props: Props) {
  const [selectedLocation, setSelectedLocation] =
    useState<LocationApiResponse>();

  useEffect(() => {
    for (const location of props.data.location_list) {
      if (location.xaxis && location.yaxis) {
        setSelectedLocation(location);
        break;
      }
    }
  }, [props.data]);

  return (
    <FlexColLayout gap={8} className="w_100">
      <MapLayout>
        <Map
          center={{
            lat: selectedLocation ? Number(selectedLocation.yaxis) : 37.566535,
            lng: selectedLocation
              ? Number(selectedLocation.xaxis)
              : 126.9779692,
          }} // 지도의 중심 좌표
          style={{ width: "100%", height: "100%" }} // 지도 크기
          level={3} // 지도 확대 레벨
        >
          {props.data.location_list.map((location) => {
            if (location.xaxis === null || location.yaxis === null) {
              return null;
            }
            return (
              <MapMarker
                position={{
                  lat: Number(location.yaxis),
                  lng: Number(location.xaxis),
                }}
              />
            );
          })}
        </Map>
      </MapLayout>
      <FlexColLayout gap={8}>
        {props.data.location_list.map((location) => (
          <LocationInfoLayout
            key={location.id}
            onClick={() => {
              setSelectedLocation(location);
            }}
          >
            <StandardTag
              scaleKey="TAG1"
              color="GRAYSCALE1"
              customStyle={{ fontWeight: "var(--fw_m)" }}
            >
              {location.title}
            </StandardTag>
            <StandardTag scaleKey="TAG2" color="GRAYSCALE1">
              {location.address}
            </StandardTag>
          </LocationInfoLayout>
        ))}
      </FlexColLayout>
    </FlexColLayout>
  );
}

const MapLayout = styled.div`
  width: 100%;
  height: 372px;
  background-color: var(--color_black);
`;

const LocationInfoLayout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: var(--color_gray1);
  padding: 11px 23px;
  background: rgba(0, 0, 0, 0.4);
`;
