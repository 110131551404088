/* size */
export type FontSizeKey =
  | "XXXS"
  | "XXS"
  | "XS"
  | "S"
  | "M"
  | "L"
  | "XL"
  | "XXL"
  | "XXXL";

type FontSizeType = {
  [k in FontSizeKey]: string;
};

export const FONT_SIZE_TYPE: FontSizeType = {
  XXXS: "fs_3xs",
  XXS: "fs_xxs",
  XS: "fs_xs",
  S: "fs_s",
  M: "fs_m",
  L: "fs_l",
  XL: "fs_xl",
  XXL: "fs_xxl",
  XXXL: "fs_3xl",
};

/* weight */
export type FontWeightKey = "BOLD" | "MEDIUM" | "REGULAR";

type FontWeightType = {
  [k in FontWeightKey]: string;
};

export const FONT_WEIGHT_TYPE: FontWeightType = {
  BOLD: "fw_b",
  MEDIUM: "fw_m",
  REGULAR: "fw_r",
};

/* line-height */
export type LineHeightKey =
  | "XXXS"
  | "XXS"
  | "XS"
  | "S"
  | "M"
  | "L"
  | "XL"
  | "XXL"
  | "XXXL";

type LineHeightType = {
  [k in LineHeightKey]: string;
};

export const LINE_HEIGHT_TYPE: LineHeightType = {
  XXXS: "line_height_3xs",
  XXS: "line_height_xxs",
  XS: "line_height_xs",
  S: "line_height_s",
  M: "line_height_m",
  L: "line_height_l",
  XL: "line_height_xl",
  XXL: "line_height_xxl",
  XXXL: "line_height_3xl",
};
