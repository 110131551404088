import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import SizedBox from "../../components/boxes/SizedBox";
import PaddingBox from "../../components/boxes/PaddingBox";
import TitlePageLayout from "../../components/layouts/TitlePageLayout";
import CustomTab from "../../components/tabs/CustomTab";
import PublishedSection from "./components/sections/PublishedSection";
import PendingSection from "./components/sections/PendingSection";
import { useNavigate } from "react-router-dom";
import ProtectedRoute from "../../containers/contexts/ProtectedRoute";
import { useTitle } from "../../hooks/useTitle";

type TabKey = "PUBLISHED" | "PENDING";

export default function ContentManagementAdminPage() {
  useTitle("콘텐츠 리스트 관리")
  const [selectedTab, setSelectedTab] = useState<TabKey>("PUBLISHED");
  const navigate = useNavigate();

  return (
    <ProtectedRoute isAdminPage>
      <TitlePageLayout title="콘텐츠 리스트 관리" isDark={true}>
        <WriteButton
          onClick={() => {
            navigate("/admin/write");
          }}
        >
          글작성
        </WriteButton>
        <PaddingBox only={{ top: 0, left: 32, bottom: 0, right: 33 }}>
          <div className="w_100 dp_fx_row fx_jc_btw">
            <CustomTab
              label={"발행"}
              isSelected={selectedTab === "PUBLISHED"}
              size={"S"}
              isDark
              onClick={() => {
                setSelectedTab("PUBLISHED");
              }}
            />
            <CustomTab
              label={"미발행"}
              isSelected={selectedTab === "PENDING"}
              size={"S"}
              isDark
              onClick={() => {
                setSelectedTab("PENDING");
              }}
            />
          </div>
        </PaddingBox>
        {selectedTab === "PUBLISHED" && <PublishedSection />}
        {selectedTab === "PENDING" && <PendingSection />}
        <SizedBox height={67} />
      </TitlePageLayout>
    </ProtectedRoute>
  );
}

export const TitleBoxLayout = styled.div`
  display: flex;
  align-items: center;
  gap: 11px;
  color: var(--color_white);
`;

export const WriteButton = styled.div`
  width: 50px;
  height: 20px;
  position: absolute;
  top: 14px;
  right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: var(--color_gray2);
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.28px;
`;
