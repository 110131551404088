import { useMutation, useQuery } from "@tanstack/react-query";
import {
  ContentApiResponse,
  ContentDetailResponse,
  ContentShowIndexRequest,
} from "../types/content";
import { axiosAdminNoTimeout, axiosCustomerNoTimeout } from "./axiosInstances";
import { ContentStatusKey } from "../types/unions/enums/ContentStatus";
import { AxiosError, AxiosResponse } from "axios";

const target = "contents";

// GET
export const useGetContentListByStatus = (
  isAdmin: boolean,
  status: ContentStatusKey
) => {
  return useQuery<AxiosResponse<ContentApiResponse[]>, AxiosError>(
    ["content", "list", { filter: status }],
    () =>
      isAdmin
        ? axiosAdminNoTimeout.get(`${target}/status/${status}`)
        : axiosCustomerNoTimeout.get(`${target}/status/${status}`)
  );
};

export const useGetRecommendedContentList = (isAdmin: boolean) => {
  return useQuery<AxiosResponse<ContentApiResponse[]>, AxiosError>(
    ["content", "list", "recommended"],
    () =>
      isAdmin
        ? axiosAdminNoTimeout.get(`${target}/recommended`)
        : axiosCustomerNoTimeout.get(`${target}/recommended`)
  );
};

export const useGetContentDetail = (isAdmin: boolean, id?: number) => {
  return useQuery<AxiosResponse<ContentDetailResponse>, AxiosError>(
    ["content", id],
    () =>
      isAdmin
        ? axiosAdminNoTimeout.get(`${target}/read/${id}`)
        : axiosCustomerNoTimeout.get(`${target}/read/${id}`),
    {
      enabled: !!id,
    }
  );
};

export const useGetLikeContentList = (isAdmin: boolean) => {
  return useQuery<AxiosResponse<ContentApiResponse[]>, AxiosError>(
    ["like_content", "list"],
    () =>
      isAdmin
        ? axiosAdminNoTimeout.get(`${target}/likes`)
        : axiosCustomerNoTimeout.get(`${target}/likes`)
  );
};

// POST
export const useCreateContent = () => {
  // return useMutation((request: ContentApiRequest) =>
  return useMutation((request: FormData) =>
    axiosAdminNoTimeout.post(`${target}`, request)
  );
};

// PUT
export const useUpdateContent = () => {
  return useMutation((request: FormData) =>
    axiosAdminNoTimeout.put(`${target}`, request)
  );
};

export const useUpdateRecommended = () => {
  return useMutation((id: number) =>
    axiosAdminNoTimeout.put(`${target}/recommended/${id}`)
  );
};

export const useUpdateShowIndexForContentList = () => {
  return useMutation((request: ContentShowIndexRequest[]) =>
    axiosAdminNoTimeout.put(`${target}/show_index`, request)
  );
};

export const useUpdateContentStatusToPublished = () => {
  return useMutation((request: ContentApiResponse[]) =>
    axiosAdminNoTimeout.put(`${target}/published`, request)
  );
};

// DELETE
export const useDeleteContent = () => {
  return useMutation((id: number) =>
    axiosAdminNoTimeout.delete(`${target}/${id}`)
  );
};
